import { OmitBaseModel, Role, Omit } from './Role';
import * as yup from 'yup';
import { YupObject } from './yup/BaseYup';
import { BaseModel } from '@CoreCommerce/models/BaseModel';

export interface Account extends BaseModel {
    username: string;
    password: string;
    picture?: string;
    email?: string;
    name?: string;
    loginType: LoginType;
    jwt?: string;
}

export interface AccountWithRoles extends Account {
    roles?: Role[];
}

export enum LoginType {
    username = 'username',
    mobile = 'mobile',
    google = 'google',
    facebook = 'facebook',
}

export interface AccountRole extends BaseModel {
    accountId: string;
    roleId: string;
}

export type LoginAccount = Pick<AccountWithRoles, 'username' | 'password'>;

export const loginAccountSchema = yup.object().shape<YupObject<LoginAccount>>({
    username: yup.string().required('This field is required!'),
    password: yup.string().required(),
});
