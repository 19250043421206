import FormTextField from '@BaseWeb/component/common/FormTextField';
import PhoneTextField from '@BaseWeb/component/common/PhoneTextField';
import { DeliveryAddress } from '@CoreCommerce/models/Address';
import { Paper, Stack, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useFormContext } from 'react-hook-form';
import { appLocale } from 'src/constant/Locale';
import { UserContext } from 'src/hook/useUser';
import Countries from './payment-method/Countries';

export default function CustomerInfoForm() {
    const { control, formState } = useFormContext<DeliveryAddress>();
    const theme = useTheme();
    const { upsert, user } = useContext(UserContext);
    const locale = appLocale.page.checkout.delivery.customerInfo;
    return (
        <div>
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="subtitle1" color={theme.palette.grey[700]}>
                    {locale.title}
                </Typography>
                <Stack direction="row" justifyContent="space-evenly" spacing={2} mt={3}>
                    <FormTextField
                        control={control}
                        name="firstName"
                        textFieldProps={{ label: `${locale.firstName}` }}
                    />
                    <FormTextField name="lastName" control={control} textFieldProps={{ label: `${locale.lastName}` }} />
                </Stack>
                <Stack direction="row" justifyContent="space-evenly" spacing={2} mt={3}>

                    <PhoneTextField name="phoneNumber" control={control} textFieldProps={{ label: `${locale.mobile}` }} />

                    <FormTextField name="email" control={control} textFieldProps={{ label: `${locale.email}` }} />
                </Stack>

                <Stack direction="row" justifyContent="space-evenly" spacing={2} mt={3}>
                    <FormTextField name="line1" control={control} textFieldProps={{ label: `${locale.line1}` }} />
                </Stack>
                <Stack direction="row" justifyContent="space-evenly" spacing={2} mt={3}>
                    <FormTextField name="line2" control={control} textFieldProps={{ label: `${locale.line2}` }} />
                </Stack>
                <Stack direction="row" justifyContent="space-evenly" spacing={2} mt={3}>
                    <FormTextField name="city" control={control} textFieldProps={{ label: `${locale.city}` }} />
                    <FormTextField name="postCode" control={control} textFieldProps={{ label: `${locale.postcode}` }} />
                </Stack>
                <Stack direction="row" justifyContent="space-evenly" spacing={2} mt={3}>
                    <Countries name="country" control={control} />
                </Stack>
            </Paper>
        </div>
    );
}
