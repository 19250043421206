import { BaseModel } from '@CoreCommerce/models/BaseModel'
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext, useState } from 'react'
import { MdOutlineArrowForwardIos } from 'react-icons/md'
import { PaymentMethodContext } from '../usePaymentMethod'


export const useStyles = makeStyles((theme: Theme) => ({
    methodItem: {
        padding: "16px 0",
        cursor: "pointer",
        color: theme.palette.grey[500]
    }
}))

export interface Provider extends BaseModel {
    name: string
    label: string
}
export default function GiftCardPayment() {
    const classes = useStyles();
    const { paymentMethod, setPaymentMethodState } = useContext(PaymentMethodContext)
    return (
        <Grid>
            <Grid className={classes.methodItem} container xs={12} item justifyContent="space-between">
                <Typography>Pay by BeeGiftcard (Coming soon)</Typography>
                <MdOutlineArrowForwardIos />
            </Grid>
        </Grid>
    )
}

interface State {
    expanded: boolean
}