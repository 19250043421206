import { Product } from '@CoreCommerce/models/Product';
import { BaseHttpController } from '../BaseHttpController';
import { IProductController } from '../IProductController';
import { AxiosInstance } from 'axios';
import { ReportDataAnalyticsProduct } from '@CoreCommerce/models/ReportAnalytics';
import { Paging } from '@CoreCommerce/models/Paging';
import { ListProps } from '@CoreCommerce/models/Query';

export class ProductController extends BaseHttpController<Product> implements IProductController {
    constructor(serviceUrl: string, axiosClient: AxiosInstance) {
        super(serviceUrl, 'product', axiosClient);
    }
    shuffleProductByShopIds(params: ListProps<Product> & { shopId: string[] }): Promise<Product[]> {
        return this.doGet({ path: 'shuffleProductByShopIds', config: { params } }).then((res) => res.data);
    }

    getInfoAnalytics(productId: string): Promise<ReportDataAnalyticsProduct> {
        return this.doGet({ path: `infoAnalytics/${productId}` }).then((res) => res.data);
    }
}
