import { Stack, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useNavigate } from 'react-router'
import { Brand } from 'src/component/search-products/brand'
const useStyles = makeStyles((theme: Theme) => ({

    item: {
        cursor: "pointer",
        '&:hover': {
            color: theme.palette.primary.main,
            transition: '0.2s ease-in-out'
        }
    }
}))
export default function BrandItem({ brand }: Props) {
    const history = useNavigate()
    const classes = useStyles()
    return (
        <Stack direction="row" alignItems="center" gap={1} >
            <div style={{fontSize: 18}}>
                {brand.icon}
            </div>
            <Typography className={classes.item} fontSize={{ sm: 14, xs: 10 }} onClick={e => history(`/shop/${brand.id}`)}>{brand.name}</Typography>
        </Stack>
    )
}
interface Props {
    brand: Brand
}