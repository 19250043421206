import { Grid, Stack, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { appLocale } from 'src/constant/Locale';
import Founder from './Founder';
const useStyles = makeStyles((theme: Theme) => ({
    box: {
        position: 'relative',
        paddingBottom: 5,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '50%',
            bottom: 0,
            left: '25%',
            borderTop: `5px solid ${theme.palette.primary.darker}`,
            borderRadius: 50,
        },
    },
}));
export default function Founders() {
    const locale = appLocale.page.about.founders;
    const theme = useTheme();
    const classes = useStyles();
    return (
        <Grid
            item
            md={8}
            sm={12}
            xs={12}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            mt={3}
        >
            <Grid container justifyContent="center">
                <Stack justifyContent="center" alignItems="center" maxWidth={480} textAlign="center">
                    <Typography fontSize={32} fontWeight={600} mt={2} mb={3} className={classes.box} color="#183b56">
                        {locale.title}
                    </Typography>
                    <Typography>{locale.content}</Typography>
                </Stack>
                <Grid container md={12} sm={11} xs={11} gap={6} mt={4}>
                    <Founder
                        name={locale.item1.name}
                        career={locale.item1.career}
                        urlImage="/ceo.jpg"
                    />
                    <Founder
                        name={locale.item2.name}
                        career={locale.item2.career}
                        urlImage="/cto.jpg"
                    />
                    <Founder
                        name={locale.item3.name}
                        career={locale.item3.career}
                        urlImage="/cmo.jpg"
                    />

                </Grid>
            </Grid>
        </Grid>
    );
}
