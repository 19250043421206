import { Control, Controller, Path } from 'react-hook-form';
import { Stack, TextFieldProps, Theme, Typography, useTheme } from '@mui/material';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
    borderClass: {
        "&.react-tel-input .form-control:focus": {
            borderColor: theme.palette.primary.main,
            boxShadow: `0px 0px 0px 1px ${theme.palette.primary.main}`,
            transition: 'none'
        },
    }
}))

export default function PhoneTextField<T>({ control, name, textFieldProps }: Props<T>) {
    const classes = useStyles()
    return (
        <Controller
            name={name as any}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <Stack width="100%">
                        <PhoneInput
                            inputStyle={{ width: "100%", }}
                            value={value as any}
                            {...textFieldProps}
                            onChange={onChange}
                            country={'vn'}
                            onlyCountries={['gb', 'vn']}
                            containerClass={classes.borderClass}
                            isValid={() => !error}

                        />
                        {error && <Typography variant='caption' color="error" m='8px 14px 0'>{error.message}</Typography>}
                    </Stack>
                )
            }}
        />
    )
}

interface Props<T> {
    control: Control<T, object>
    name: Path<T>
    textFieldProps: TextFieldProps
}