import { MobileContext } from '@BaseWeb/hook/useMobile';
import { FormControl, IconButton, InputBase, MenuItem, Paper, Select } from '@mui/material';
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { appLocale } from 'src/constant/Locale';
import { ShopContext } from 'src/hook/useShop';
import SearchIcon from '@mui/icons-material/Search';
import { useStyles } from './styles';
import { toParamsString } from 'src/util/ParamUtils';
import { brands } from './brand';
import { GoogleAnalyticContext } from '@BaseWeb/hook/useGoogleAnalytic';

export default function SearchProducts() {
    const { shop } = useContext(ShopContext);
    const { onSearch } = useContext(GoogleAnalyticContext);
    const history = useNavigate();
    const classes = useStyles();
    const { searchLabel, searchLabelShop } = appLocale.component.appMenu.searchProducts;
    const label = shop && shop.id ? searchLabelShop(shop.name || '') : searchLabel;
    const refInputSearch = useRef<HTMLInputElement | null>();
    const [state, setState] = useState<State>({ selectedBrand: brands[0].id });
    const searchProducts = () => {
        console.log('ON Search product');

        const brand = brands.find((b) => b.id == state.selectedBrand);
        const params = toParamsString([
            { key: 'brand', value: brand?.key },
            { key: 'search', value: refInputSearch.current?.value },
        ]);
        onSearch(refInputSearch.current?.value || '', brand?.key || '');
        if (shop && shop.id) history(`/shop/${shop.id}?${params}`);
        else history(`/search?${params}`);
    };
    const { setIsLockScreen } = useContext(MobileContext);

    return (
        <>
            <Paper component="form" sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                {shop?.id == null && (
                    <FormControl>
                        <Select
                            value={state.selectedBrand}
                            inputProps={{ MenuProps: { disableScrollLock: true } }}
                            onChange={(e) => {
                                setTimeout(() => {
                                    (refInputSearch as any).current.focus();
                                }, 100);
                                setState((prev) => ({ ...prev, selectedBrand: e.target.value }));
                            }}
                            className={classes.selectBrand}
                        >
                            {brands.map((b) => (
                                <MenuItem key={b.id} style={{ fontSize: '0.85rem' }} value={b.id}>
                                    {b.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
                <InputBase
                    sx={{ ml: 1, flex: 1, fontSize: '0.9rem', pl: 1 }}
                    onFocus={() => setIsLockScreen(true)}
                    onBlur={() => setIsLockScreen(false)}
                    inputProps={{
                        ref: refInputSearch,
                    }}
                    onKeyDown={(e) => {
                        const search = (e.target as any).value;
                        if (e.key === 'Enter') {
                            console.log(search);
                            (refInputSearch as any).current.blur();
                            setIsLockScreen(false);
                            searchProducts();
                        }
                    }}
                    placeholder={label}
                />
                <IconButton sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon onClick={(e) => searchProducts()} />
                </IconButton>
            </Paper>
        </>
    );
}

interface State {
    selectedBrand?: string;
}
