import axios from "axios"

const DEFAULT_TIMEOUT = 5 * 60 * 1000;
var timeoutLoading: any;

export interface AxiosError {
    status: number
    message: string
}

interface Props {
    url: string,
    timeout?: number,
    onLoad?: (isLoading: boolean) => void,
    onError?: (error: AxiosError) => void
}

export const axiosCreator = (
    { url, timeout, onLoad, onError }:
        Props) => {
    const client = axios.create({
        baseURL: url,
        timeout: timeout || DEFAULT_TIMEOUT,
        headers: {
            common: {
                'Content-Type': 'application/json',
            },
        },
    });

    client.interceptors.request.use(
        function (config) {
            clearTimeout(timeoutLoading);
            onLoad?.(true)
            return config;
        },
        function (error) {
            return Promise.reject(error);
        },
    );

    client.interceptors.response.use(
        (response) => {
            timeoutLoading = setTimeout(() => {
                onLoad?.(false)
                clearTimeout(timeoutLoading);
            }, 100);

            return response;
        },
        (error) => {
            console.log({ error });

            if (error.response) {
                onError?.({ status: error.response.status, message: error?.response?.data?.message })
            } else {
                onError?.({ status: 0, message: "Network error!" })
            }
            onLoad?.(false)
            return Promise.reject(error);
        },
    );

    return client;
}