import useGoogleAnalytic, { GoogleAnalyticContext } from '@BaseWeb/hook/useGoogleAnalytic';
import { MobileContext, useMobile } from '@BaseWeb/hook/useMobile';
import { Grid } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { useContext, useMemo } from 'react';
import useShop, { ShopContext } from 'src/hook/useShop';
import { UserContext, useUser } from 'src/hook/useUser';
import useCart, { CartContext } from 'src/page/checkout/cart/useCart';
import PopupLogin from 'src/page/login/PopupLogin';
import useWishList, { WishListContext } from 'src/page/wishlist/useWishList';
import useController, { ControllerContext, ControllerProps } from '../hook/useController';
import { Loading } from '../submodule/base-web/component/AppLoading';
import { AppNotification } from '../submodule/base-web/component/AppNotification';
import useAuthen, { AuthenContext } from '../submodule/base-web/hook/useAuthen';
import useLoading, { LoadingContext } from '../submodule/base-web/hook/useLoading';
import useNotification, { NotificationContext } from '../submodule/base-web/hook/useNotification';

const theme = createTheme({});
export function AppContext({ children }: { children: JSX.Element }) {
    const notification = useNotification();

    return (
        <GoogleAnalyticContextProvider>
            <NotificationContext.Provider value={notification}>
                <LoadingContextProvider>
                    <ControllerContextProvider>
                        <AuthenContextProvider>
                            <MobileContextProvider>
                                <Grid>
                                    <AppNotification />
                                    <Loading />
                                    <PopupLogin />
                                    {children}
                                </Grid>
                            </MobileContextProvider>
                        </AuthenContextProvider>
                    </ControllerContextProvider>
                </LoadingContextProvider>
            </NotificationContext.Provider>
        </GoogleAnalyticContextProvider>
    );
}

function LoadingContextProvider({ children }: any) {
    const loading = useLoading();
    return <LoadingContext.Provider value={loading}>{children}</LoadingContext.Provider>;
}
function ControllerContextProvider({ children }: ControllerProps) {
    const { showError } = useContext(NotificationContext);
    const { setIsLoading } = useContext(LoadingContext);
    const controller = useController({
        onError: (message) => {
            process.env.REACT_APP_ENV === 'local' && showError(message);
        },
        onLoading: setIsLoading,
    });
    return useMemo(
        () => <ControllerContext.Provider value={controller}>{children}</ControllerContext.Provider>,
        [children],
    );
}

function AuthenContextProvider({ children }: any) {
    const { axiosClient } = useContext(ControllerContext);
    const authen = useAuthen({ axiosClient });
    return <AuthenContext.Provider value={authen}>{children}</AuthenContext.Provider>;
}

export function CartContextProvider({ children }: any) {
    const cartHook = useCart();
    return <CartContext.Provider value={cartHook}>{children}</CartContext.Provider>;
}

export function GoogleAnalyticContextProvider({ children }: any) {
    const googleAnalyticHook = useGoogleAnalytic();
    return <GoogleAnalyticContext.Provider value={googleAnalyticHook}>{children}</GoogleAnalyticContext.Provider>;
}

export function UserContextProvider({ children }: any) {
    const userHook = useUser();
    return <UserContext.Provider value={userHook}>{children}</UserContext.Provider>;
}

export function ShopContextProvider({ children }: any) {
    const shop = useShop();
    return <ShopContext.Provider value={shop}>{children}</ShopContext.Provider>;
}

export function MobileContextProvider({ children }: any) {
    const mobileHook = useMobile();
    return <MobileContext.Provider value={mobileHook}>{children}</MobileContext.Provider>;
}
export function WishListContextProvider({ children }: any) {
    const wishListHook = useWishList()
    return <WishListContext.Provider value={wishListHook}>{children}</WishListContext.Provider>
}