import { Order } from '@CoreCommerce/models/Order';
import { Button, Grid, Paper, Stack, Typography, useTheme, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _, { upperFirst } from 'lodash';
import moment from 'moment';
import { BiStoreAlt } from 'react-icons/bi';
import NumberFormat from 'react-number-format';
import { useNavigate } from 'react-router';
import { appLocale } from 'src/constant/Locale';
import TotalPriceFooter from 'src/page/checkout/cart/component/footer/TotalPriceFooter';
import VariantItems from './VariantItems';

const useStyles = makeStyles((theme: Theme) => ({
    address: {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitLineClamp: 1,
        WebkitBoxOrient: 'vertical',
        pl: 0.5,
        fontSize: 14,
        color: theme.palette.grey[700],
    },
}));
export default function OrderItem({ order }: Props) {
    const theme = useTheme();
    const history = useNavigate();
    const classes = useStyles();

    const locale = appLocale.page.user.orderHistory;
    const status = order.status || 'pending';
    const address = `${order.address?.firstName} ${order.address?.lastName} (${order.address?.phoneNumber}), ${order.address?.line1} ${order.address?.line2}, 
    ${order.address?.city}, ${order.address?.country}`;
    const total = order.variants?.reduce((acc, next) => {
        return (acc += (next.quantity || 0) * (next.variant?.saleOffPrice || next.variant?.price || 0));
    }, 0);
    const shipFee = order.feeShip;
    const shop = _.uniqBy(order.variants, (item) => item.variant?.product?.shop?.name)?.map((item) => {
        return {
            id: item.variant?.product?.shopId,
            name: item.variant?.product?.shop?.name,
        };
    });
    return (
        <div>
            <Paper elevation={0} sx={{ marginTop: 3 }}>
                <Grid container justifyContent="space-between" alignItems="center" p={2}>
                    <Stack direction="row" alignItems="center" gap={1}>
                        <Grid container alignItems="center" justifyContent="space-between">
                            <BiStoreAlt style={{ fontSize: theme.spacing(2.5), fill: theme.palette.grey[800] }} />
                            <Typography pl={0.5} pr={0.5} variant="subtitle2">
                                {' '}
                                {shop[0].name}
                            </Typography>
                            <Button
                                variant="outlined"
                                size="small"
                                onClick={(e) => history(`/shop/${shop[0].id}`)}
                                sx={{ display: { md: 'block', sm: 'block', xs: 'none' } }}
                            >
                                {locale.shopContentButton}
                            </Button>
                        </Grid>
                    </Stack>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={(e) => history(`/shop/${shop[0].id}`)}
                        sx={{ display: { md: 'none', sm: 'none', xs: 'block' } }}
                    >
                        {locale.shopContentButton}
                    </Button>
                    <Stack alignItems={{ sm: 'flex-end', xs: 'flex-start' }} mt={{ sm: 0, xs: 3 }}>
                        <Stack direction="row" alignItems="center">
                            <Typography fontSize={14} color={theme.palette.grey[700]}>
                                {moment(order.createdAt).format('HH:mm DD/MM/yyyy')} |
                            </Typography>
                            <Typography pl={0.5} variant="subtitle2" color={theme.palette.success.dark}>
                                {upperFirst(status)}
                            </Typography>
                        </Stack>
                        <Typography className={classes.address}>{address}</Typography>
                    </Stack>
                </Grid>
                <Grid sx={{ width: '100%', backgroundImage: 'repeating-linear-gradient(45deg,#6fa6d6,#6fa6d6 33px,transparent 0,transparent 41px,#f18d9b 0,#f18d9b 74px,transparent 0,transparent 82px)', backgroundSize: '7.25rem 0.1875rem', backgroundPositionX: '-1.875rem', height: '0.15rem' }} />
                <Grid item xs={12} mt={2}>
                    {order.variants?.map((v) => (
                        <VariantItems v={v} />
                    ))}
                </Grid>
                <Grid sx={{ width: '100%', borderTop: '1px dashed #ddd' }} />
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end" p={2}>
                    <TotalPriceFooter
                        hiddenDivider
                        shipFee={shipFee || 0}
                        totalCost={total || 0}
                        hasFee={Boolean(shipFee)}
                    />
                    <Typography fontSize={16}>
                        {`${locale.total}: `}
                        <Typography
                            fontSize={22}
                            fontWeight={600}
                            display="inline-block"
                            color={theme.palette.primary.main}
                        >
                            £
                            <NumberFormat displayType={'text'} value={(total || 0) + (shipFee || 0)} decimalScale={2} />
                        </Typography>
                    </Typography>
                </Stack>
            </Paper>
        </div>
    );
}

interface Props {
    order: Order;
}
