import { Badge, Button, Divider, Drawer, IconButton, Stack } from '@mui/material'
import clsx from 'clsx'
import { useState } from 'react'
import { FiHeart } from 'react-icons/fi'
import { GiShoppingBag } from 'react-icons/gi'
import { GoPackage } from 'react-icons/go'
import { IoMdLogOut } from 'react-icons/io'
import { MdMenu } from 'react-icons/md'
import { useNavigate } from 'react-router'
import LogoAfi from 'src/component/icons/LogoAfi'
import SearchProducts from 'src/component/search-products'
import { HeaderProps, IMenuAction } from '../IMenu'
import { useMenuStyles } from '../styles'

export default function MobileHeader({ cartNumber, logout, locale, user }: HeaderProps) {
    const [openMobile, setOpenMobible] = useState(false)
    const classes = useMenuStyles()
    const history = useNavigate()

    const mobileMenuActions: IMenuAction[] = [
        { label: locale.menuTitle.orders, doAction: () => history('/user/orders'), icon: < GoPackage /> },
        { label: locale.menuTitle.wishlist, doAction: () => history('/wishlist'), icon: < FiHeart /> },
        { label: locale.menuTitle.logout, doAction: () => logout(), icon: <IoMdLogOut /> }
    ]

    return (
        <div>
            <div className={clsx(classes.root, classes.mobileHeader)} >
                <SearchProducts />
                <Stack direction="row" alignItems="center" ml={1}>
                    <Badge badgeContent={cartNumber} color="secondary">
                        <GiShoppingBag onClick={e => history('/checkout/cart')} className={classes.rootIcons} />
                    </Badge>
                    <IconButton onClick={() => setOpenMobible(true)}>
                        <MdMenu className={classes.rootIcons} />
                    </IconButton>
                </Stack>
            </div >
            <Drawer
                classes={{ paper: classes.tabMenu }}
                anchor="left"
                open={openMobile}
                onClose={() => setOpenMobible(false)}
                BackdropProps={{ classes: { root: classes.backdrop } }}
            >
                <LogoAfi style={{ width: "80%", margin: '0 auto' }} onClick={(e) => history('/')} />
                <Stack gap={1} mt={2} divider={<Divider sx={{ borderWidth: "0.1" }} orientation="horizontal" flexItem />}>
                    {mobileMenuActions.map(m => <Button color="primary" disabled={m.disble} className={classes.tabMenuIcons}
                        onClick={m.doAction} endIcon={m.icon} fullWidth>{m.label}</Button>
                    )}
                </Stack>
            </Drawer>
        </div>
    )
}
