import { CircularProgress, Grid, Theme, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { MdOutlineArrowForwardIos } from 'react-icons/md'
import 'react-coinbase-commerce/dist/coinbase-commerce-button.css';
import CoinbaseCommerceButton from 'react-coinbase-commerce';
import { makeStyles } from '@mui/styles';
import { ControllerContext } from 'src/hook/useController';
import { CartContext } from 'src/page/checkout/cart/useCart';
import { useFormContext } from 'react-hook-form';
import { Address, DeliveryAddress } from '@CoreCommerce/models/Address';

export const useStyles = makeStyles((theme: Theme) => ({
    coinbaseBtn: {
        border: 0,
        background: "none",
        fontSize: "1rem",
        cursor: "pointer",
        color: theme.palette.text.primary,
        "&:hover": {
            color: theme.palette.primary.main
        }
    },

    methodItem: {
        padding: "16px 0",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.main
        },
    }
}))

export default function CryptoPayment() {
    const classes = useStyles()
    const { orderController } = useContext(ControllerContext)
    const { temporaryOrder } = useContext(CartContext)
    const [state, setState] = useState<State>({ loading: false })
    const { watch } = useFormContext<DeliveryAddress>()

    const startPayment = async () => {
        if (state.loading) return;
        setState(prev => ({ ...prev, loading: true }))
        const order = await orderController.startCoinBasePayment({ ...temporaryOrder, address: watch() })
        const coinBaseUrl = order.paymentMethod?.url;
        window.open(coinBaseUrl, "_blank")
        setState(prev => ({ ...prev, loading: false }))
    }

    return (
        <Grid className={classes.methodItem} container xs={12} item justifyContent="space-between">
            <Grid alignItems="center" xs={10} item container>{state.loading &&
                <CircularProgress style={{ width: 16, height: 16, marginRight: 16 }} />}
                <Typography onClick={startPayment}>Payment with crypto</Typography>
            </Grid>
            <MdOutlineArrowForwardIos />
        </Grid>
    )
}

interface State {
    loading: boolean
}