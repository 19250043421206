import { Container, Grid, Stack, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { appLocale } from 'src/constant/Locale';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.up('lg')]: {
            height: '60vh',
        },
        [theme.breakpoints.down('lg')]: {
            height: '50vh',
        },
        [theme.breakpoints.down('md')]: {
            height: '50vh',
        },
        [theme.breakpoints.down('sm')]: {
            height: '50vh',
        },
        backgroundImage:
            'url(https://minimal-assets-api.vercel.app/assets/overlay.svg), url(https://minimal-assets-api.vercel.app/assets/images/about/hero.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        overflow: 'hidden',
    },
    title: {
        color: theme.palette.secondary.main,
    },
}));
export default function Banner() {
    const classes = useStyles();
    const theme = useTheme();
    const locale = appLocale.page.about.mainBanner;
    return (
        <Grid item className={classes.root} xs={12}>
            <Container maxWidth="xl">
                <Stack alignItems={{ sm: 'flex-start', xs: 'center' }}>
                    <Stack
                        sx={{
                            position: 'absolute',
                            bottom: { md: 300, sm: 370, xs: "45%" },
                            pl: { md: 14, sm: 1, xs: 0 },
                            textAlign: { sm: 'left', xs: 'center' },
                        }}
                    >
                        <Typography
                            component="span"
                            fontSize={{ sm: '4rem', xs: '2.5rem' }}
                            fontWeight={700}
                            className={classes.title}
                            pt={3}
                        >
                            {locale.title1}
                        </Typography>
                        <Typography
                            component="span"
                            fontSize={{ sm: '3rem', xs: '2.5rem' }}
                            fontWeight={700}
                            color={theme.palette.common.white}
                            pb={3}
                        >
                            {locale.title2}
                        </Typography>
                        <Typography
                            component="h4"
                            fontWeight={600}
                            fontSize="1.25rem"
                            color={theme.palette.common.white}
                        >
                            {locale.content}
                        </Typography>
                    </Stack>
                </Stack>
            </Container>
        </Grid>
    );
}
