import { Shop } from '@CoreCommerce/models/Shop';
import { BaseHttpController } from '../BaseHttpController';
import { IShopController } from '../IShopController';
import { AxiosInstance } from 'axios';
import { WishList } from '@CoreCommerce/models/WishList';
import { IWishListController } from '../IWishListController';
import { Product } from '@CoreCommerce/models/Product';

export class WishListController extends BaseHttpController<WishList> implements IWishListController {
    constructor(serviceUrl: string, axiosClient: AxiosInstance) {
        super(serviceUrl, 'wishList', axiosClient);
    }
    toggleWishList(productId: string): Promise<Product> {
        return this.doPost({ path: 'toggle', body: { productId } }).then((res) => res.data);
    }
    add(productId: string): Promise<WishList> {
        return this.doPost({ path: '', body: { productId } }).then((res) => res.data);
    }
    remove(productId: string): Promise<Product[]> {
        return this.doDelete({ path: `del/${productId}` }).then((res) => res.data);
    }
    myList(userId?: string): Promise<Product[]> {
        return this.doGet({ path: '' }).then((res) => res.data);
    }
}
