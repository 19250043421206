import { MobileContext } from '@BaseWeb/hook/useMobile';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useAfiSwipeStyles } from 'src/component/swipe/style';
import BaseSwipe from 'src/component/swipe/Swipe';

const useStyles = makeStyles((theme: Theme) => ({
    baseHeight: {
        [theme.breakpoints.up('lg')]: {
            height: "40vh",
        },
        [theme.breakpoints.down('lg')]: {
            height: "40vh",
        },
        [theme.breakpoints.down('md')]: {
            height: "50vh",
        },
        [theme.breakpoints.down('sm')]: {
            height: "200px",
        }
    },
    slide: {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        borderRadius: 3

    },
}))

export default function Banner() {
    const classes = useStyles()
    const { isMobile } = useContext(MobileContext)
    const sliders = ['banner1.png', 'banner2.png', 'banner3.png', 'banner4.png']
    const rightBanners = ['banner4.png', 'banner5.png']
    const afiSwipeCss = useAfiSwipeStyles()
    return (
        <Grid item xs={12} mt={{ md: 2, sm: 1, xs: 1 }} container justifyContent="center" className={classes.baseHeight}>
            <Grid item xs={12} className={clsx(afiSwipeCss.root)} >
                <BaseSwipe imgs={sliders} autoPlay loop customClass={classes.baseHeight} />
            </Grid>
            {/* <Grid item xs={0} md={4} display="grid" sx={{ gridTemplateRows: { lg: "1fr 1fr" }, rowGap: { sm: 0.5 } }}>
                <Grid item xs={12} style={{ backgroundImage: `url(${rightBanners[0]})` }} className={clsx(classes.slide)} />
                <Grid item xs={12} style={{ backgroundImage: `url(${rightBanners[1]})` }} className={clsx(classes.slide)} />
            </Grid> */}
        </Grid>
    )
}
