import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useBaseStyle = makeStyles((theme:Theme) =>({
    wrapper:{
        background:theme.palette.common.white,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        [theme.breakpoints.up('md')]:{
            borderRadius:3
        }
    },
}))