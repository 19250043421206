import { MobileContext } from '@BaseWeb/hook/useMobile';
import { Grid, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router';
import AppMenu from 'src/app-menu/AppMenu';
import { Mask } from 'src/component/styled-components/Mask';
import { ScrollToTop } from 'src/hook/useScrollToTop';
import Footer from 'src/page/landing-page/section/footer';
import { CartContextProvider, ShopContextProvider, UserContextProvider, WishListContextProvider } from 'src/wrapper/AppContext';
import { AuthenContext } from '../hook/useAuthen';
const useStyles = makeStyles({
    maskRoot: {
        background: '#00000050',
        position: 'fixed',
        top: 72,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        transition: '180ms ease-in-out',
        visibility: 'hidden',
        zIndex: 200,
    },
    maskVisible: {
        opacity: 1,
        visibility: 'initial',
    },
});
export function AuthRoute({ fullWidth, renderMenu, loginUrl, renderFooter }: Props) {
    const { isAuthen } = useContext(AuthenContext);
    const { isLock, isMobile } = useContext(MobileContext);
    const theme = useTheme();
    const classes = useStyles();
    return isAuthen == null ? (
        <div />
    ) : isAuthen == true ? (
        <UserContextProvider>
            <CartContextProvider>
                <WishListContextProvider>
                    <ShopContextProvider>
                        <ScrollToTop>
                            <Grid
                                container
                                justifyContent="center"
                                style={{ backgroundColor: theme.palette.background.default }}
                            >
                                {renderMenu ? renderMenu() : <AppMenu />}
                                {fullWidth ? (
                                    <DefaultWrapper useDefaultMenu={Boolean(!renderMenu)} children={<Outlet />} />
                                ) : (
                                    <Grid item xs={12} md={8} mb={12} style={{ minHeight: 'calc(100vh - 132px)' }}>
                                        <DefaultWrapper useDefaultMenu={Boolean(!renderMenu)} children={<Outlet />} />
                                    </Grid>
                                )}
                                {renderFooter ? renderFooter() : <Footer />}
                            </Grid>
                            {/* {isLock && <Mask/>} */}
                            <div className={clsx(classes.maskRoot, { [classes.maskVisible]: isLock })} />
                        </ScrollToTop>
                    </ShopContextProvider>
                </WishListContextProvider>
            </CartContextProvider>
        </UserContextProvider>
    ) : (
        <Navigate to={loginUrl || '/login'} />
    );
}

interface Props {
    loginUrl?: string;
    fullWidth?: boolean;
    renderMenu?(): JSX.Element;
    renderFooter?(): JSX.Element;
}

export function DefaultWrapper({ children, useDefaultMenu }: { children: JSX.Element; useDefaultMenu?: boolean }) {
    const { isLock, isMobile } = useContext(MobileContext);
    return (
        <Grid container mt={useDefaultMenu ? (isMobile ? 9 : 16) : 0} sx={{ overflowY: isLock ? 'hidden' : 'visible' }}>
            {children}
        </Grid>
    );
}
