import React, { useCallback, useState } from "react";
import { VariantType } from 'notistack';

export type Notification = {
    message?: string;
    variant?: VariantType;
    latestAt?: Date;
};

export default function useNotification() {
    const [notification, setNotification] = useState<Notification>()
    const showSuccess = useCallback((message?: string) => setNotification({ message, variant: 'success', latestAt: new Date() }), [],)
    const showError = useCallback((message?: string) => setNotification({ message, variant: 'error', latestAt: new Date() }), [])

    return { notification, showSuccess, showError }
}

export const NotificationContext = React.createContext<ReturnType<typeof useNotification>>({} as any);
