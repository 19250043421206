import { Box, Grid, Paper, Stack, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
        transition: 'transform 0.25s ease-out',
        '&:hover': {
            transform: 'translateY(-.15rem)',
            transition: '.3s ease-in-out',
            color: theme.palette.primary.darker,
        },
    },
    image: {
        height: 500,
        cursor: 'pointer',
        padding: 16,
        overflow: 'hidden',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
    },
    box: {
        backgroundColor: '#fff',
        padding: 20,
        position: 'absolute',
        bottom: 20,
        left: '20%',
        width: '60%',
        borderRadius: 10,
    },
}));
export default function Founder(props: Props) {
    const classes = useStyles();
    const theme = useTheme();
    return (
        <Grid item md sm={12} xs={12} className={classes.root}>
            <Box className={classes.image} sx={{ backgroundImage: `url(${props.urlImage})` }} />
            <Stack className={classes.box} alignItems="center">
                <Typography component="h6" fontSize="1.1rem" fontWeight={600}>
                    {props.name}
                </Typography>
                <Typography fontSize="0.9rem" color="rgb(145, 158, 171)">
                    {props.career}
                </Typography>
            </Stack>
        </Grid>
    );
}
interface Props {
    name: string;
    career: string;
    urlImage: string;
}
