import { Theme, useMediaQuery } from '@mui/material'
import _, { uniqBy } from 'lodash'
import { useContext } from 'react'
import { useAfiSwipeStyles } from 'src/component/swipe/style'
import BaseSwipe from 'src/component/swipe/Swipe'
import { ProductDetailContext } from '../../useProductDetail'


export default function Image2() {
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    const afiSwipeClasses = useAfiSwipeStyles();

    const { medias, filterVariants, variants, state } = useContext(ProductDetailContext)
    const imgs = uniqBy(medias, (item) => item.url).map(i => i.url) as string[]
    const remainingVariants = filterVariants(variants, state.filters);
    const getImage = _.uniqBy(remainingVariants, (item) => item?.media?.url)[0]?.media?.url
    const indexActive = imgs?.findIndex(img => img == getImage)



    return (
        <div className={afiSwipeClasses.root}>
            <BaseSwipe {...{ imgs, showThumbs: matches, indexImageActive: indexActive }} />
        </div>
    )
}
