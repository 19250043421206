import { Avatar, Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext } from 'react'
import { FiHeart } from 'react-icons/fi';
import { GoPackage } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'src/hook/useUser';
import IconMenu from './IconMenu';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        padding: 10,
        [theme.breakpoints.up('lg')]: {
            height: '40vh',
        },
        [theme.breakpoints.down('lg')]: {
            height: '7vh',
        },
        [theme.breakpoints.down('md')]: {
            height: '7vh',

        },
        [theme.breakpoints.down('sm')]: {
            height: '11vh',

        },
        background: 'url(https://ae01.alicdn.com/kf/He1ffbc1d340f4be8a0a6d07641c58180t.png_.webp)',
        backgroundRepeat: 'no-repeat',
        borderRadius: 5
    },
    avatarIcon: {
        width: 55,
        height: 55,
        cursor: "pointer",
        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
    },

}));
export default function User() {
    const classes = useStyles();
    const history = useNavigate()
    const { user } = useContext(UserContext)
    const matches = useMediaQuery('(width:1024px)');
    return (
        <Grid container className={classes.root}>
            <Grid container justifyContent="center">
                <Stack alignItems="center" spacing={0.5} mt={1.5}>
                    <Avatar
                        alt={user?.firstName || ""}
                        src={user?.picture || '/static/images/avatar/1.jpg'}
                        className={classes.avatarIcon}
                    />
                    {user.firstName ? <Typography fontSize={13} fontWeight={600} component='span'>Hi, {user?.firstName || ""}</Typography> : <Typography fontSize={13} fontWeight={600} component='span'>Hi there!</Typography>}
                </Stack>
            </Grid>
            <Grid container mt={-4}>
                <IconMenu icon={<GoPackage />} title='My orders' redirect={(e) => history('/user/orders')} />
                <IconMenu icon={<FiHeart />} title='Wishlist' redirect={(e) => history('/wishlist')} />
            </Grid>
        </Grid>
    )
}
