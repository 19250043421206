import { Product } from '@CoreCommerce/models/Product'
import { Box, CardMedia, Grid, IconButton, Paper, Stack, Theme, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { useContext } from 'react'
import { FiHeart } from 'react-icons/fi'
import { useNavigate } from 'react-router'
import { appLocale } from 'src/constant/Locale'

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        overflow: "hidden",
        borderRadius: 4,
        transition: "transform 0.25s ease-out",
        "&:hover": {
            transform: "translateY(-.15rem)",
            boxShadow: "0 0.15rem 20px 0 rgb(0 0 0 / 5%)"
        }
    },
    name: {
        color: "#555655",
        cursor: "pointer",
        fontSize: "0.9rem",
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        minHeight: 14 * 3
    },
    image: {
        height: theme.spacing(25),
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(20),
        },
        objectFit: "cover",
        cursor: "pointer",
        overflow: "hidden",
        borderRadius: 4
    },
    price: {
        display: "flex",
        alignItems: "center",
        fontWeight: 500
    },
    shop: {
        cursor: "pointer",
        fontSize: "0.75rem",
        color: theme.palette.grey[500],
        textDecoration: "underline",
        fontStyle: "italic",
        "&:hover": {
            color: theme.palette.primary.main
        }
    },
    sell: {
        fontSize: "0.85rem",
        color: theme.palette.grey[500]
    }
}))

export default function ProductItem({ product, isDisplayShop = true, isDisplayIcon = false, handleRemove }: Props) {
    const theme = useTheme()
    const history = useNavigate();
    const classes = useStyles()
    const variants = product.variants || [];
    const maxPrice = _.maxBy(variants, 'price')?.price || 0
    const maxSaleOff = _.maxBy(variants, 'saleOffPrice')?.saleOffPrice || 0
    const hasSalePrice = maxSaleOff > 0 && maxSaleOff < maxPrice;
    const isSale = (price: number, salePrice: number) => {
        let mainPrice = 0;
        if (hasSalePrice) {
            mainPrice = salePrice
        } else {
            mainPrice = price
        }
        return mainPrice
    }
    const totalPercent = (price: number, saleOff: number) => {
        let saled = price - saleOff;
        let percent = 1;
        if (saleOff < 0 || saleOff == 0) {
            percent = 0
        } else {
            percent = saled * (100 / price)
        }
        return _.round(percent, 0);
    }
    return (
        <Paper
            variant="outlined" square className={classes.root} sx={{ position: 'relative' }}>
            <CardMedia onClick={e => history(`/product/${product.shopId}/${product.id}`)}
                component="img" src={product?.media?.[0]?.url} className={classes.image} />

            <Grid mt={1} p={1}>
                <Grid>
                    <Typography onClick={e => history(`/product/${product.shopId}/${product.id}`)} className={classes.name}>{product.name}</Typography>
                </Grid>
                <Grid mt={2} container justifyContent="space-between" alignItems="flex-end" minHeight={14 * 3}>
                    <Stack direction="column">
                        <Typography color={theme.palette.primary.dark}>£{isSale(maxPrice, maxSaleOff)}</Typography>
                        {totalPercent(maxPrice, maxSaleOff) > 0 &&
                            <Stack direction="row">
                                <Typography
                                    fontSize="0.75rem"
                                    pr={0.5}
                                    color={theme.palette.grey[600]}
                                    sx={{ textDecoration: 'line-through' }}
                                >
                                    £{maxPrice}
                                </Typography>
                                <Typography fontSize="0.75rem" pr={0.5} color={theme.palette.primary.main}>-{totalPercent(maxPrice, maxSaleOff)}%</Typography>
                            </Stack>}
                    </Stack>
                    {isDisplayIcon &&
                        <IconButton onClick={handleRemove} sx={{ position: 'absolute', top: 5, right: 4 }}>
                            <FiHeart fill='#CA0C0C' color='#CA0C0C' fontSize={20} />
                        </IconButton>}
                    <Typography className={classes.sell}> {product.sold} {appLocale.component.productItem.sold}</Typography>
                </Grid>

                {isDisplayShop && <Grid mt={1} className={classes.shop} onClick={e => history(`/shop/${product.shopId}`)}>{product.shop?.name}</Grid>}
            </Grid>
        </Paper>
    )
}

interface Props {
    product: Product
    isDisplayShop?: boolean
    isDisplayIcon?: boolean,
    handleRemove?: VoidFunction

}