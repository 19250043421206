import { Product } from '@CoreCommerce/models/Product';
import { CardMedia, Grid, IconButton, Paper, Stack, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React from 'react'
import { FiHeart } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import ProductItem from 'src/component/product-base/ProductItem';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        overflow: 'hidden',
        borderRadius: 4,
        transition: 'transform 0.25s ease-out',
        '&:hover': {
            transform: 'translateY(-.15rem)',
            boxShadow: '0 0.15rem 20px 0 rgb(0 0 0 / 5%)',
        },
    },
    name: {
        color: '#555655',
        cursor: 'pointer',
        fontSize: '0.9rem',
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: 2,
        minHeight: 14 * 3,
    },
    image: {
        height: theme.spacing(25),
        [theme.breakpoints.down('sm')]: {
            height: theme.spacing(20),
        },
        objectFit: 'cover',
        cursor: 'pointer',
        overflow: 'hidden',
        borderRadius: 4,
    },
    price: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
    },
    shop: {
        cursor: 'pointer',
        fontSize: '0.75rem',
        color: theme.palette.grey[500],
        textDecoration: 'underline',
        fontStyle: 'italic',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    sell: {
        fontSize: '0.85rem',
        color: theme.palette.grey[500],
    },
}));
export default function WishListItem({ product, handleRemove }: Props) {
    const classes = useStyles()
    const history = useNavigate()
    const theme = useTheme()
    const variants = product.variants || [];
    const maxPrice = _.maxBy(variants, 'price')?.price || 0
    const maxSaleOff = _.maxBy(variants, 'saleOffPrice')?.saleOffPrice || 0
    const hasSalePrice = maxSaleOff > 0 && maxSaleOff < maxPrice;
    const isSale = (price: number, salePrice: number) => {
        let mainPrice = 0;
        if (hasSalePrice) {
            mainPrice = salePrice
        } else {
            mainPrice = price
        }
        return mainPrice
    }
    const totalPercent = (price: number, saleOff: number) => {
        let saled = price - saleOff;
        let percent = 1;
        if (saleOff < 0 || saleOff == 0) {
            percent = 0
        } else {
            percent = saled * (100 / price)
        }
        return _.round(percent, 0);
    }

    return (
        <Grid item xs={6} sm={4} md={3} lg={3} xl={2}>
            <ProductItem product={product} handleRemove={handleRemove} isDisplayIcon />
        </Grid>
    )
}
interface Props {
    product: Product,
    handleRemove: VoidFunction
}
