import { Button, Divider, Grid, Stack, Theme, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import { makeStyles } from '@mui/styles';
import useThrottle from '@rooks/use-throttle';
import clsx from 'clsx';
import _ from 'lodash';
import React, { useContext } from 'react';
import { BsFillCartPlusFill } from 'react-icons/bs';
import { FaRegEye } from 'react-icons/fa';
import { FiHeart } from 'react-icons/fi';
import { CartContext } from 'src/page/checkout/cart/useCart';
import { ProductDetailContext } from '../../useProductDetail';
import PurchaseVariants from './PurchaseVariants';

const useStyles = makeStyles((theme: Theme) => ({
    priceBox: {
        padding: '18px 12px',
        background: '#00000005',
        fontSize: '1.4rem',
        marginBottom: 24,
        color: theme.palette.primary.main,
    },
    price: {
        fontWeight: 400,
        fontSize: 24,
    },
    isPrice: {
        fontSize: 18,
        color: theme.palette.grey[600],
        textDecoration: 'line-through',
    },
    title: {
        fontSize: 16,
        color: "#222"
    },
    subTitle: {
        color: "#767676",
        fontSize: 12
    }
}));

export default function ProductInformation() {
    const { selectedVariant, state, name, noSelectedItem, report, toggleWishList } = useContext(ProductDetailContext);
    const { addToCart } = useContext(CartContext);
    const classes = useStyles();
    const theme = useTheme();
    const price = _.round(selectedVariant?.[0]?.price || 0, 2);
    const salePrice = selectedVariant?.[0]?.saleOffPrice || 0;
    const hasSalePrice = salePrice > 0 && salePrice < price;
    const totalSold = report?.totalBuy || 0;
    const totalView = report?.totalPageView || 0;
    const [throttleToggleWishList] = useThrottle(toggleWishList, 2000)
    const addProductToCart = () => {
        addToCart(
            {
                ...selectedVariant[0],
                product: { ...state.product, shop: { id: state.product.shopId } },
            },
            state.quantity,
        );
    }
    const [throttleAddToCart] = useThrottle(addProductToCart, 2000)


    return (
        <div>
            <Typography
                variant="h4"
                color={theme.palette.grey[700]}
                style={{ textTransform: 'capitalize', marginBottom: 5, fontWeight: 500 }}
            >
                {name}
            </Typography>

            {selectedVariant && (
                <Stack className={classes.priceBox} direction="row" gap={2} alignItems="center" divider={<Divider orientation="vertical" flexItem />}>
                    <Typography
                        variant="subtitle1"
                        fontWeight={400}
                        className={clsx(classes.price, { [classes.isPrice]: hasSalePrice })}
                    >
                        £{price}
                    </Typography>
                    {hasSalePrice && (
                        <Typography variant="h4" fontWeight={400}>
                            £{salePrice}{' '}
                        </Typography>
                    )}
                    <Stack direction="row" alignItems="center" gap={0.5}>
                        <Typography className={classes.title}>{totalSold}</Typography>
                        <Typography fontSize={13} className={classes.subTitle}>SOLD</Typography>
                    </Stack>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                        <Typography className={classes.title}>{totalView}</Typography>
                        <Typography fontSize={13} className={classes.subTitle}>VIEW</Typography>
                    </Stack>
                </Stack>
            )}
            <Grid container wrap="nowrap" alignItems="center">
                <PurchaseVariants />
            </Grid>

            <Grid xs={12} mt={3} item container justifyContent={{ xs: 'center', sm: 'flex-start' }}>
                <Button
                    startIcon={<BsFillCartPlusFill />}
                    onClick={throttleAddToCart}
                    disabled={state.quantity == 0 || noSelectedItem}
                    size="large"
                    variant="outlined"
                    sx={{ width: { sm: 300, xs: '70%' }, mr: 1 }}
                >
                    Add to Cart
                </Button>
                <Button size="large" variant="outlined" onClick={throttleToggleWishList}>
                    <FiHeart fontSize={20} fill={!!state.product.wishList ? '#CA0C0C' : 'white'} color={!!state.product.wishList ? '#CA0C0C' : theme.palette.primary.main} />
                </Button>
            </Grid>
        </div>
    );
}
