import React, { useEffect, useState } from "react";
import { AxiosInstance } from "axios"
import { useNavigate } from "react-router";

const JWT_KEY = 'jwt';

export default function useAuthen({ axiosClient }: Props) {
    const [state, setState] = useState<State>({ isAuthen: null, isShowPopup: false })
    const history = useNavigate()

    useEffect(() => {
        const jwt = localStorage.getItem(JWT_KEY) || undefined
        setJwt(jwt)
    }, [])


    const setJwt = async (jwt?: string) => {
        if (jwt) axiosClient.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
        await new Promise(res => setTimeout(res, 1000))
        setState({ ...state, jwt, isAuthen: !!jwt })
    }

    const login = (jwt: string) => {
        localStorage.setItem(JWT_KEY, jwt)
        setJwt(jwt)
    }

    const logout = () => {
        localStorage.removeItem(JWT_KEY)
        setState({ ...state, isAuthen: false, jwt: undefined })
    }

    return { ...state, login, logout }
}

export const AuthenContext = React.createContext<ReturnType<typeof useAuthen>>({} as any);

interface State {
    isAuthen: boolean | null
    jwt?: string;
    isShowPopup: boolean
}

interface Props {
    axiosClient: AxiosInstance
}