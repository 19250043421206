import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import { CategoryBrand } from '../data'
import BrandCategory from './BrandCategory'

export default function ListBrandCategory({ lbc }: Props) {
    return (
        <Grid item md={8} sm={8} xs={12}>
            <Typography variant="h6" mb={2} ml={{ sm: 1, xs: 4 }}>{lbc.name}</Typography>
            <Grid container justifyContent="center" spacing={10} mb={2}>
                {lbc.brands?.map(bc =>
                    <Grid item>
                        <BrandCategory bc={bc} />
                    </Grid>
                )}
            </Grid>
            <Button sx={{ mb: 5, ml: { sm: 0, xs: 3 } }} color="secondary" endIcon={<BiRightArrowAlt />}>Show more stores in {lbc.name}</Button>
        </Grid>
    )
}
interface Props {
    lbc: CategoryBrand
}