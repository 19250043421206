import { Box, Stack, Typography, useTheme } from '@mui/material'
import { styled } from '@mui/styles'
import React from 'react'
import { Deal } from '../data'
const DealImage = styled('img')({
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    cursor: "pointer"
})
export default function DealItem({ d }: Props) {
    const theme = useTheme()
    return (
        <Box width={theme.spacing(32)}>
            <Stack gap={0.5}>
                <DealImage src={d.image} />
                <Typography variant="subtitle1">{d.title}</Typography>
                <Typography variant="caption" fontSize={16}>{d.brand}</Typography>
            </Stack>
        </Box>

    )
}
interface Props {
    d: Deal
}