import { OrderVariant } from '@CoreCommerce/models/OrderVariant'
import { useTheme } from '@mui/material'
import React from 'react'
import WebItems from './variant-component/WebItems'


export default function VariantItems({ v }: Props) {
    return (
        <>
            <WebItems v={v} />
        </>
    )
}
interface Props {
    v: OrderVariant
}