import { Shop } from '@CoreCommerce/models/Shop';
import { BaseHttpController } from '../BaseHttpController';
import { IShopController } from '../IShopController';
import { AxiosInstance } from 'axios';
import { PropsListAmazon } from '@CoreCommerce/model-refferral/AmazonProduct';
import { Paging } from '@CoreCommerce/models/Paging';
import { Product } from '@CoreCommerce/models/Product';

export class ShopController extends BaseHttpController<Shop> implements IShopController {
    constructor(serviceUrl: string, axiosClient: AxiosInstance) {
        super(serviceUrl, 'shop', axiosClient);
    }
    searchProductAmazon(props: PropsListAmazon): Promise<Paging<Product>> {
        return this.doGet({ path: 'searchProductAmazon', config: { params: props } }).then((res) => res.data);
    }
}
