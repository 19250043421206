import { Button, Grid, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pick, upperFirst } from 'lodash';
import React, { useContext } from 'react';
import { QuantityButton } from 'src/component/product-base/QuantityButton';
import { ProductDetailContext } from '../../useProductDetail';

const useStyles = makeStyles((theme: Theme) => ({
    variantItem: {
        display: 'grid',
        gridTemplateColumns: '80px 1fr',
        [theme.breakpoints.up('sm')]: {
            gridColumnGap: 24,
        },
        [theme.breakpoints.up('xs')]: {
            gridColumnGap: 12,
        },
        '& .title': {
            height: 34,
            display: 'grid',
            alignItems: 'center',
            color: theme.palette.grey[600],
        },
        '& .variant': {
            fontWeight: 500,
            padding: '4px 12px',
            lineHeight: '1.5rem',
        },
    },
}));

export default function PurchaseVariants() {
    const {
        getAttrValue,
        filterVariants,
        state,
        attrs,
        variants,
        isSelectedAttr,
        setState,
        noSelectedItem,
        getVariantByAttr,
    } = useContext(ProductDetailContext);
    const classes = useStyles();
    return (
        <Stack pb={2} gap={4}>
            {attrs.map((k, index) => {
                const filters = pick(state.filters, attrs.slice(0, index));
                const remainingVariants = filterVariants(variants, filters);
                return (
                    <Grid key={index} container alignItems="center">
                        <Grid className={classes.variantItem}>
                            <Typography className="title" variant="subtitle1">
                                {' '}
                                {upperFirst(k)}:{' '}
                            </Typography>
                            <Grid container spacing={1}>
                                {getAttrValue(variants || [], k).map((v) => {
                                    const isAttr = getAttrValue(remainingVariants, k).find((att) => att == v);
                                    const att = { ...state.filters, [k]: isAttr };
                                    const variantItem = getVariantByAttr(att);
                                    const isStock = Boolean(variantItem?.quantity == 0 || 0);
                                    return (
                                        <Grid item>
                                            <Button
                                                disabled={!isAttr || isStock}
                                                variant={isSelectedAttr(k, v) ? 'contained' : 'outlined'}
                                                color={isSelectedAttr(k, v) ? 'primary' : 'inherit'}
                                                className="variant"
                                                sx={{ textAlign: 'left' }}
                                                onClick={() => setState({ ...state, filters: { ...filters, [k]: v } })}
                                            >
                                                {v}
                                            </Button>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
            <Grid className={classes.variantItem}>
                <Typography className="title" variant="subtitle1">
                    {upperFirst('Quantity')}:{' '}
                </Typography>
                <Grid container spacing={1}>
                    <Grid item>
                        <QuantityButton
                            quantity={noSelectedItem ? 0 : state.quantity}
                            onChange={(newQuantity) => setState((preState) => ({ ...preState, quantity: newQuantity }))}
                            disable={noSelectedItem}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Stack>
    );
}
