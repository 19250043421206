import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
const firebaseConfig = {
  apiKey: "AIzaSyCEdxT9JXfVqe_F3Lbdln8obqjX-zbR6qM",
  authDomain: "ecommerce-prod-33578.firebaseapp.com",
  projectId: "ecommerce-prod-33578",
  storageBucket: "ecommerce-prod-33578.appspot.com",
  messagingSenderId: "652681090572",
  appId: "1:652681090572:web:c5d1ad69b767b6ea188029"
};
  // const firebaseConfig = {
  //   apiKey: 'AIzaSyAesWIc2KgmNDnhDwV5CYFIF_iGRlk7hbM',
  //   authDomain: 'e-afi-dev.firebaseapp.com',
  //   projectId: 'e-afi-dev',
  //   storageBucket: 'e-afi-dev.appspot.com',
  //   messagingSenderId: '392843118915',
  //   appId: '1:392843118915:web:53a91a6535a29ae4ebc7f6',
  //   measurementId: 'G-6W9ZX8J8B6',
// };
const app = initializeApp(firebaseConfig);
export const authentication = getAuth(app);
