import React from 'react'

export default function BrContent(props: Props) {
    const content = props.children.split('\n')
    return (
        <>
            {content.map((t, i) => <>{t}{i == content.length ? undefined : <br />}</>)}
        </>
    )
}
interface Props {
    children: string
}