import { Grid, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme: Theme) => ({
    base: {
        width: 160,
        height: 160,
        borderRadius: '50%',
        position: 'relative',
        color: 'rgb(119, 5, 8)',
        border: '2px dashed rgba(250, 84, 28, 0.24)',
        '&:before': {
            content: "''",
            position: 'absolute',
            borderRadius: '50%',
            width: 'calc(60%)',
            height: 'calc(60%)',
            background: `conic-gradient(from 
                0deg at 50% 50%, rgb(250, 84, 28) 
                0deg, rgb(253, 171, 118) 
                360deg)`,
            zIndex: 8,
        },
    },
}));
export default function ViewItem(props: Props) {
    const classes = useStyles();
    return (
        <Grid container direction="column" alignItems="center" textAlign="center" md={3} sm={6} xs={12} mt={5}>
            <Stack className={classes.base} alignItems="center" justifyContent="center">
                <div style={{zIndex: 9, fontSize: 32}}>
                    {props.icon}
                </div>
            </Stack>
            <Typography mt={2} fontSize="1.25rem" fontWeight={600}>{props.title}</Typography>
        </Grid>
    );
}
interface Props {
    icon: React.ReactElement,
    title: string
}