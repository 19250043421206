import { PaymentMethod } from '@CoreCommerce/models/PaymentMethod'
import React, { useState } from 'react'

export function usePaymentMethod() {
    const [state, setState] = useState<State>({ paymentMethod: { method: 'payLater', provider: 'BeeMall.io' } })
    const { paymentMethod } = state
    return { ...state, setPaymentMethodState: setState, isValid: paymentMethod?.method === 'payLater' && paymentMethod.provider ? true : false }
}

interface State {
    paymentMethod: PaymentMethod
}

export const PaymentMethodContext = React.createContext<ReturnType<typeof usePaymentMethod>>({} as any)