import { LinearProgress } from "@mui/material";
import React, { useContext } from "react";
import { LoadingContext } from "../hook/useLoading";

export function Loading() {
  const { isLoading } = useContext(LoadingContext)
  
  return (
    <div style={{ visibility: isLoading ? "visible" : "hidden"}}>
      <LinearProgress sx={{position:"absolute" }} />
    </div>
  );
}
