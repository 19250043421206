
import { Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext } from 'react';
import { PaymentMethodContext } from './usePaymentMethod';
import CryptoPayment from './component/CryptoPayment';
import PayLaterPayment from './component/PayLaterPayment';
import { appLocale } from 'src/constant/Locale';
import { Method } from '@CoreCommerce/models/PaymentMethod';
import GiftCardPayment from './component/GiftCardPayment';

export const useStyles = makeStyles((theme: Theme) => ({
    coinbaseBtn: {
        border: 0,
        background: "none",
        fontSize: "1rem",
        cursor: "pointer",
        color: theme.palette.text.primary,
        "&:hover": {
            color: theme.palette.primary.main
        }
    },

    methodItem: {
        padding: "16px 0",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.main
        },
    }
}))

interface PaymentMethodItem {
    method: Method
    component: JSX.Element
}

const paymentMethods: PaymentMethodItem[] = [
    { method: 'crypto', component: <CryptoPayment /> },
    { method: 'payLater', component: <PayLaterPayment /> },
    { method: 'payGiftCard', component: <GiftCardPayment /> },
]

export function PaymentMethodComponent() {
    const locale = appLocale.page.checkout.delivery.payment
    const { setPaymentMethodState } = useContext(PaymentMethodContext)
    return (
        <Grid mt={3}>
            <Paper elevation={0} sx={{ p: 3 }}>
                <Grid container item xs={12} direction="column">
                    <Typography variant="subtitle1">{locale.title}</Typography>
                    <Typography variant="caption" style={{ fontSize: "0.9rem" }} mt={2}>{locale.subTitle}</Typography>

                    <Grid mt={3}>
                        {paymentMethods.map(m => <Grid
                            onClick={e => setPaymentMethodState(prev => ({ ...prev, paymentMethod: { ...prev.paymentMethod, method: m.method } }))}>
                            {m.component}</Grid>)}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}
