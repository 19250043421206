import { CircularProgress, Grid, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ControllerContext } from 'src/hook/useController'
import { BiError } from 'react-icons/bi'

export function CoinBaseConfirmed() {
    const { orderId } = useParams<{ orderId: string }>()
    const { orderController } = useContext(ControllerContext)
    const [state, setState] = useState<State>()
    const history = useNavigate()

    useEffect(() => {
        orderId && confirmOrder(orderId)
    }, [])

    const confirmOrder = async (orderId: string) => {
        orderController.confirmCoinBasePayment(orderId).then(order => {
            history(`/checkout/completed/${orderId}`)
        }).catch(err => {
            setState(prev => ({ ...prev, error: 'Payment still not successfully!' }))
            setTimeout(() => {  
                history(`/home`)
            }, 5000);
        })
    }
    return (
        <Grid xs={12} pt={15} justifyContent='center' container>
            {!state?.error ? <CircularProgress /> : <Grid item xs={12} container direction='column' alignItems="center" justifyContent="center">
                <BiError style={{ width: 100, height: 100 }} />
                <Typography mt={3} style={{ fontWeight: 600 }}>{state?.error}</Typography>
                <Typography mt={2} >Redirecting to Home page ...</Typography>
            </Grid>}
        </Grid>
    )
}

interface State {
    error?: string
}