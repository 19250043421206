import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Cart from './cart';
import { DeliveryConfirm } from './delivery-confirm';

type CheckoutRoute = 'cart' | 'delivery' | 'completed/:orderId'
interface CheckoutStep {
    index: number
    route: CheckoutRoute
    title: string
    component: JSX.Element
}

export const checkoutSteppers: CheckoutStep[] = [
    { index: 1, route: 'cart', title: "Cart information", component: <Cart /> },
    { index: 2, route: 'delivery', title: "Delivery & Payment", component: <DeliveryConfirm /> },
]


export default function useCheckout() {
    const [state, setState] = useState<State>({ activatedStep: checkoutSteppers[0].index })
    const location = useLocation()
    const history = useNavigate()


    useEffect(() => {
        const activatedStep = checkoutSteppers.find(step => `/checkout/${step.route}` == location.pathname);
        console.log({ activatedStep });

        activatedStep && setState({ activatedStep: activatedStep.index })
    }, [location.pathname])

    const doChangeStep = (route: CheckoutRoute) => history(`/checkout/${route}`)

    return { ...state, setState, doChangeStep }
}

interface State {
    activatedStep: number
}

export const CheckoutContext = React.createContext<ReturnType<typeof useCheckout>>({} as any)