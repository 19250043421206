
export interface IConfig {
    apiGateway: {
        account: string,
        ecommerce: string,
    }
}


export const localConfig: IConfig = {
    apiGateway: {
        account: process.env.REACT_APP_GATEWAY_ACCOUNT || 'http://localhost:6001',
        ecommerce: process.env.REACT_APP_GATEWAY_ECOMMERCE || 'http://localhost:6001',
    }
}

export const prodConfig: IConfig = {
    apiGateway: {
        account: 'https://api.ecommerce.afivn.com',
        ecommerce: 'https://api.ecommerce.afivn.com',
    }
}


export const appConfig =
    process.env.REACT_APP_ENV === 'production'
        ? prodConfig
        : process.env.REACT_APP_ENV === 'staging'
            ? localConfig
            : process.env.REACT_APP_ENV === 'development'
                ? localConfig
                : localConfig;
