import { AuthenContext } from "@BaseWeb/hook/useAuthen";
import { MobileContext } from "@BaseWeb/hook/useMobile";
import { AppBar, Slide, useScrollTrigger } from "@mui/material";
import { useContext } from "react";
import { appLocale } from "src/constant/Locale";
import { UserContext } from "src/hook/useUser";
import { CartContext } from "src/page/checkout/cart/useCart";
import useWishList, { WishListContext } from "src/page/wishlist/useWishList";
import MobileHeader from "./component/MobileHeader";
import WebHeader from "./component/WebHeader";
import { HeaderProps } from "./IMenu";

export default function AppMenu(props) {

    return (
        <HideOnScroll {...props}>
            <AppBar>
                <BaseMenu />
            </AppBar>
        </HideOnScroll>
    )

}

export function BaseMenu() {
    const { isMobile } = useContext(MobileContext)
    const { state } = useContext(WishListContext)
    const menuProps: HeaderProps = {
        user: useContext(UserContext).user,
        logout: useContext(AuthenContext).logout,
        cartNumber: useContext(CartContext).flattenVariants.map(v => v.quantity || 1).reduce((s, q) => s = s + q, 0),
        wishListNumber: state.length,
        locale: appLocale.component.appMenu
    }
    return (isMobile ? <MobileHeader {...menuProps} /> : <WebHeader  {...menuProps} />)
}

export function HideOnScroll(props: Props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({ target: window ? window() : undefined });
    const typeShow = props.direction == "down" || !props.direction ? !trigger : trigger
    return (
        <Slide appear={false} direction={props.direction || "down"} in={typeShow}>
            {children}
        </Slide>
    );
}

type Props = {
    children: JSX.Element
    window(): Window
    direction?: "down" | "up"
};