import { GoogleAnalyticContext } from '@BaseWeb/hook/useGoogleAnalytic';
import { Product } from '@CoreCommerce/models/Product';
import { Grid, Pagination } from '@mui/material';
import { useContext, useEffect } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { useParams } from 'react-router';
import { ProductList } from 'src/component/product-base/ProductList';
import { NotFound } from 'src/component/search-products/NotFound';
import { appLocale } from 'src/constant/Locale';
import { ControllerContext } from 'src/hook/useController';
import { usePagination } from 'src/hook/usePagination';
import useQueryString from 'src/hook/useQueryString';
import ShopInfor from './ShopInfor';

export function ShopPage({ shopId }: Props) {
    const { productController } = useContext(ControllerContext);
    const { search } = useQueryString<{ search: string }>();
    const { notFound } = appLocale.page.shop;

    const { pagination, searchPagingState, onChangePage, onChangeSearch, loading } = usePagination<Product>({
        controller: productController as any,
        pagination: { pageSize: 24 },
        filter: { shopId },
        search: { fields: ['name'], content: search },
    });

    const isNotExistedProduct = pagination.rows?.length == 0 && !loading;
    useEffect(() => {
        if (search) onChangeSearch({ content: search });
    }, [search]);

    useEffect(() => {
        if (shopId) {
            searchPagingState((prev) => ({
                ...prev,
                filter: { ...prev.filter, shopId },
                pagination: { ...prev.pagination },
            }));
        }
    }, [shopId]);

    return (
        <Grid container xs={12} mb={10}>
            {!isNotExistedProduct ? (
                <Grid container>
                    <Grid mb={3} mt={5} container justifyContent="center">
                        <ShopInfor shopId={shopId || ''} />
                    </Grid>
                    <Grid container justifyContent="center" p={{ sm: 0, xs: '0 0.5rem' }}>
                        <ProductList products={pagination.rows} isDisplayShop loading={loading} />
                        <Grid mt={4}>
                            <Pagination
                                variant="outlined"
                                color="primary"
                                page={pagination.page}
                                count={pagination.totalPages}
                                onChange={(e, page) => onChangePage(page)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            ) : (
                <NotFound {...notFound} />
            )}
        </Grid>
    );
}

export function ShopPageRoute() {
    const params = useParams<'shopId'>();
    const { seeShop } = useContext(GoogleAnalyticContext);
    useEffect(() => {
        seeShop(params.shopId || '');
    }, [params.shopId]);

    return params.shopId ? <ShopPage key={params.shopId} shopId={params.shopId} /> : <div />;
}

interface Props {
    shopId?: string;
}
