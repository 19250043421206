import { BaseModel } from './BaseModel';
import * as yup from 'yup';
import { YupObject } from '@CoreAccount/model/yup/BaseYup';
export interface Address extends BaseModel {
    userId?: string;
    line1: string;
    line2?: string;
    country: string;
    city: string;
    district?: string;
    postCode?: string;
}

export interface DeliveryAddress extends Address {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    email?: string;
    addresses?: Address[];
}
yup.setLocale({
    mixed: {
        required: `This field is required!`
    }
})

export const addressValidationSchema = yup.object().shape<YupObject<DeliveryAddress>>({
    firstName: yup.string().required().max(255),
    lastName: yup.string().required().max(255),
    phoneNumber: yup.string().required().matches(/^\d+$/, 'Phone number is invalid!').min(10, 'Phone number is invalid!').max(14, 'Phone number is invalid!'),
    email: yup.string().required().email('Please enter an valid email!').max(255),
    line1: yup.string().required().max(255),
    country: yup.string().required().max(255),
    city: yup.string().required().max(255),
    postCode: yup.string().required().max(255),
});
