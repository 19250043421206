import { AuthenContext } from '@BaseWeb/hook/useAuthen';
import { User, UserWithRole } from '@CoreCommerce/models/User';
import React, { useContext, useEffect, useState } from 'react';
import { ControllerContext } from './useController';

export function useUser() {
    const { isAuthen } = useContext(AuthenContext)
    const { userController } = useContext(ControllerContext)
    const [state, setState] = useState<State>({ user: {} as UserWithRole })
    useEffect(() => {
        isAuthen && getMe()
    }, [isAuthen])

    const getMe= ()=>{
        userController.getMe().then(user => setState(prev => ({ ...prev, user })))
    }

    const upsert = async (user: User) => {
        return userController.upsert(user)
    }
    
    return {...state, upsert, getMe}
}

interface State {
    user: UserWithRole
}

export const UserContext = React.createContext<ReturnType<typeof useUser>>({} as any)