import { Order } from '@CoreCommerce/models/Order';
import { Variant } from '@CoreCommerce/models/Variant';
import { BaseHttpController } from '../BaseHttpController';
import { IOrderController, StatisticOrder } from '../IOrderController';
import { AxiosInstance } from 'axios';

export class OrderController extends BaseHttpController<Order> implements IOrderController {
    constructor(serviceUrl: string, axiosClient: AxiosInstance) {
        super(serviceUrl, 'order', axiosClient);
    }
    statisticOrder(): Promise<StatisticOrder> {
        return this.doGet({ path: `statisticOrder`, config: {} }).then((resp) => resp.data);
    }
    startCoinBasePayment(order: Order): Promise<Order> {
        return this.doPost({ path: `payment/coinbase/start`, body: order }).then((resp) => resp.data);
    }
    confirmCoinBasePayment(orderId: string): Promise<Order> {
        return this.doPost({ path: `payment/coinbase/confirm/${orderId}` }).then((resp) => resp.data);
    }
    myCart(): Promise<Order> {
        return this.doGet({ path: `my-cart`, config: {} }).then((resp) => resp.data);
    }
}
