import { Theme, Typography } from '@mui/material'
import Grid from '@mui/material/Grid/Grid'
import { makeStyles } from '@mui/styles'
import { upperCase } from 'lodash'
import React from 'react'
import LoginContent from 'src/component/login/LoginContent'




const useStyles = makeStyles((theme: Theme) =>
({
    background: {
        height: "100vh",
        // background: "url(https://www.pixelstalk.net/wp-content/uploads/2016/06/Nice-Fashion-Wallpaper.jpg)",
        backgroundImage: "url(/login.png)",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        padding: 40,
        [theme.breakpoints.down(1200)]: {
            padding: 24
        },
    },
    container: {
        width: "85%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.between(768, 1025)]: {
            width: "90%"
        },
        [theme.breakpoints.down(769)]: {
            width: "100%",
            flexDirection: "column",
            justifyContent: "center",
        },
    },
    loginBox: {
        minWidth: "400px",
        padding: "40px 20px",
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 60px 40px -7px",
        [theme.breakpoints.down(1025)]: {
            minWidth: "300px",
        },
    }
})
)

export default function Login2() {


    const classes = useStyles()
    return (
        <Grid className={classes.background}>
            <Grid className={classes.container}>
                <Grid mb={2}>
                    <Typography variant='h2' color="white">Biggest mall of</Typography>
                    <Typography variant="h1" color="white">{upperCase("Super brands")}</Typography>
                </Grid>
                <Grid className={classes.loginBox}>
                    <LoginContent />
                </Grid>
            </Grid>
        </Grid>
    )
}
