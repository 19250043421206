import { Product } from '@CoreCommerce/models/Product'
import { Grid, Pagination, Typography } from '@mui/material'
import { useContext, useEffect } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import { ProductList } from 'src/component/product-base/ProductList'
import { brands } from 'src/component/search-products/brand'
import { NotFound } from 'src/component/search-products/NotFound'
import { appLocale } from 'src/constant/Locale'
import { ControllerContext } from 'src/hook/useController'
import { usePagination } from 'src/hook/usePagination'
import useQueryString from 'src/hook/useQueryString'


export function SearchPage() {
    const { productController } = useContext(ControllerContext)
    const { search, brand } = useQueryString<{ search: string, brand: string }>()
    const { resultWihText, resultWithoutText } = appLocale.page.search
    const brandId = brands.find(b => b.key == brand)?.id;

    const { pagination, onChangePage, loading, searchPagingState } = usePagination<Product>({
        controller: productController as any,
        pagination: { pageSize: 24 },
        search: { fields: ['name'], content: search },
        filter: { shopId: brandId }
    })


    useEffect(() => {
        searchPagingState(prev => ({ ...prev, search: { ...prev.search, content: search }, filter: { ...prev.filter, shopId: brandId } }))
    }, [search, brand])

    return pagination.rows?.length > 0 ? (
        <Grid container marginTop={2} justifyContent="center" mb={10}>
            <Grid xs={12} item>
                <Typography style={{ fontWeight: 600, fontSize: "0.9rem" }} mb={2} ml={1}>
                    {search && search.length > 0 ? resultWihText(search, pagination.total) : resultWithoutText(pagination.total)}
                </Typography>
            </Grid>
            <ProductList products={pagination.rows} loading={loading} />
            <Grid mt={4}>
                <Pagination size="large" variant="outlined" color="primary" page={pagination.page} count={pagination.totalPages}
                    onChange={(e, page) => onChangePage(page)} />
            </Grid>
        </Grid>
    ) : <NotFound title="Không tìm thấy kết quả nào" subTitle='Hãy thử sử dụng các từ khóa chung chung hơn' />
}
