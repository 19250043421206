import { GoogleAnalyticContext } from '@BaseWeb/hook/useGoogleAnalytic';
import { NotificationContext } from '@BaseWeb/hook/useNotification';
import { Order } from '@CoreCommerce/models/Order';
import { OrderVariant } from '@CoreCommerce/models/OrderVariant';
import { Shop } from '@CoreCommerce/models/Shop';
import { Variant } from '@CoreCommerce/models/Variant';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { brands } from 'src/component/search-products/brand';
import { ControllerContext } from 'src/hook/useController';
import { UserContext } from 'src/hook/useUser';
import { useDebounce } from 'use-debounce/lib';
import { v4 as uuid } from 'uuid';

export default function useCart() {
    const [state, setState] = useState<State>({ cart: {} });
    const { orderController } = useContext(ControllerContext);
    const googleAnalytics = useContext(GoogleAnalyticContext);
    const { user } = useContext(UserContext);
    console.log({ userCart: user })
    const flattenVariants = (state.cart?.variants || []).map((v) => ({
        ...v,
        shop: v.variant?.product?.shop,
        shopId: v.variant?.product?.shop?.id,
    }));
    const groupByShop = _.groupBy(flattenVariants, 'shopId');
    const shops = Object.keys(groupByShop)
        .map((id) => flattenVariants.find((v) => v.shopId == id)?.shop)
        .filter((s) => !!s) as Shop[];
    const { showSuccess } = useContext(NotificationContext);

    useEffect(() => {
        orderController.myCart().then((cart) => {
            setState({ cart });
        });
    }, []);
    const [debounce] = useDebounce(state.cart, 1000);
    useEffect(() => {
        Object.keys(state.cart).length &&
            orderController
                .upsert({
                    id: uuid(),
                    ...state.cart,
                    userId: user.id,
                    isTemporary: true,
                    status: 'addedToCart',
                    variants: state.cart.variants?.map((v) => _.omit(v, 'variant')),
                })
                .then((res) => {
                    res.variants = _.sortBy(res.variants, (v) => v.createdAt);
                    if (!_.isEqual(res, state.cart)) {
                        setState({
                            ...state,
                            cart: res,
                        });
                    }
                });
    }, [JSON.stringify(debounce)]);

    const addToCart = (variant: Variant, quantity?: number) => {
        setState((prev) => {
            const currentCart = _.cloneDeep(state.cart);
            const variants = currentCart.variants || [];
            const index = variants.findIndex((v) => v.variantId == variant.id);
            if (index == -1) variants.push({ variantId: variant.id, quantity: quantity || 1, variant });
            // else variants[index] = { ...variants[index], quantity: quantity || (variants[index].quantity || 1) + 1 };
            else variants[index] = { ...variants[index], quantity: (quantity || 1) + (variants[index].quantity || 1) };
            return { ...prev, cart: { ...currentCart, variants } };
        });
        showSuccess('Add to cart successfully!');
        googleAnalytics.addToCart(variant.productId || '');
    };

    const deleteFromCart = (variantId: string) => {
        setState((prev) => {
            const currentCart = _.cloneDeep(state.cart);
            let variants = currentCart.variants || [];
            variants = variants.filter((v) => v.variantId != variantId);
            return { ...prev, cart: { ...currentCart, variants } };
        });
    };

    const updateVariant = (variant: OrderVariant) => {
        setState((prev) => {
            const currentCart = _.cloneDeep(state.cart);
            const variants = currentCart.variants || [];
            const index = variants.findIndex((v) => v.variantId == variant.variantId);
            if (index > -1) variants[index] = variant;
            return { ...prev, cart: { ...currentCart, variants } };
        });
    };

    const isVariantSelected = (variantId: string) =>
        state.cart.variants?.find((v) => v.variantId == variantId)?.selected || false;

    const isShopSelected = (shopId: string) => {
        const shopVariants = groupByShop[shopId];
        return shopVariants.every(
            (shopVariant) => selectedVariants().find((v) => v.variantId == shopVariant.variantId) != null,
        );
    };

    const selectVariant = (variant: OrderVariant) =>
        setState((prev) => {
            const variants = _.cloneDeep(prev.cart.variants)?.map((v) => ({
                ...v,
                selected: v.variantId == variant.variantId ? !v.selected : v.selected,
            }));
            return { ...prev, cart: { ...prev.cart, variants } };
        });

    const selectShop = (shopId: string) => {
        console.log(isShopSelected(shopId), 'selected');

        setState((prev) => ({
            ...prev,
            cart: {
                ...prev.cart,
                variants: prev.cart.variants?.map((v) => ({
                    ...v,
                    selected:
                        v.variant?.product?.shopId != shopId ? v.selected : isShopSelected(shopId) ? !v.selected : true,
                })),
            },
        }));
    };

    const totalSelectedOrder = () =>
        (state.cart.variants || [])
            .filter((v) => v.selected)
            .map((v) => ({
                quantity: v.quantity || 1,
                price: v.variant?.saleOffPrice != 0 ? v.variant?.saleOffPrice || 0 : v.variant.price || 0,
            }))
            .reduce((sum, { price, quantity }) => (sum = sum + quantity * price), 0);

    const temporaryOrder = () => ({
        isTemporary: false,
        id: uuid(),
        variants: selectedVariants().map((v) => ({ ..._.omit(v, 'variant'), shopId: v.variant?.product?.shopId })),
        userId: user.id,
    });

    const selectedVariants = () => (state.cart.variants || []).filter((v) => !!v.selected) || [];

    const shopVariantsWithTotalAndFeeShip = () => {
        const shopSelectedVariants = _.groupBy(
            selectedVariants().map((v) => ({
                ...v,
                shopId: v.variant?.product?.shopId,
                shop: v.variant?.product?.shop,
            })),
            'shopId',
        );
        return Object.keys(shopSelectedVariants).map((shopId) => {
            const shopVariants = shopSelectedVariants[shopId] || [];
            const total = shopVariants
                .map((v) => (v.variant?.saleOffPrice || v.variant?.price || 0) * (v.quantity || 1))
                .reduce((sum, price) => (sum = sum + price), 0);
            const brand = brands.find((b) => b.id == shopId);
            const feeShip =
                brand &&
                    brand.feeShip &&
                    (brand.feeShip.freeWithMinimum == null || brand.feeShip.freeWithMinimum > total)
                    ? brand.feeShip.fee
                    : 0;
            return {
                shop: shopVariants[0].shop || ({} as Shop),
                total,
                feeShip,
                variants: shopVariants as OrderVariant[],
            };
        });
    };

    const proceedOrder = async (order: Partial<Order>): Promise<Order[]> => {
        const shopVariants = shopVariantsWithTotalAndFeeShip();
        const savedOrders = await Promise.all(
            shopVariants.map(
                async (shopVariants) =>
                    await orderController.upsert({
                        ...order,
                        ...temporaryOrder(),
                        variants: shopVariants.variants,
                        feeShip: shopVariants.feeShip || 0,
                    }),
            ),
        );

        const updatedCart = { ...state.cart, variants: state.cart.variants?.filter((v) => !v.selected) };
        setState({ ...state, cart: updatedCart });
        await orderController.upsert(updatedCart);
        showSuccess('Process order successfully!');
        return savedOrders;
    };

    return {
        ...state,
        shops,
        groupByShop,
        flattenVariants,
        selectedVariants: selectedVariants(),
        totalSelectedOrder: totalSelectedOrder(),
        addToCart,
        deleteFromCart,
        updateVariant,
        selectVariant,
        selectShop,
        isVariantSelected,
        isShopSelected,
        proceedOrder,
        temporaryOrder: temporaryOrder(),
        shopWithTotalAndFeeShip: shopVariantsWithTotalAndFeeShip(),
        shopVariantsWithTotalAndFeeShip,
    };
}

interface State {
    cart: Order;
}

export const CartContext = React.createContext<ReturnType<typeof useCart>>({} as any);
