import { Divider, Grid, Stack, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import NumberFormat from 'react-number-format'
import { appLocale } from 'src/constant/Locale'

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        background: "#fff",
        marginTop: theme.spacing(3),
        zIndex: 2,
        position: "sticky",
        bottom: "0",
        "&::before": {
            background: "linear-gradient(transparent,rgba(0,0,0,.05))",
            content: '""',
            position: "absolute",
            top: "-1.25rem",
            left: 0,
            height: "1.25rem",
            width: "100%",
        }
    },
    row: {
        padding: "12px 20px",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 20px"
        }
    },
    priceWrapper: {
        minWidth: 200,
        maxWidth: 400
    }
}))

export default function TotalPriceFooter({ totalCost, shipFee, hiddenDivider, hasFee }: Props) {
    const locale = appLocale.page.user.orderHistory
    const renderPrice = (title: string, price: number) => {
        return (
            <Stack direction="row" gap={1} justifyContent="space-between" width={180}>
                <Typography variant="body2">{title}: </Typography>
                <Typography variant="subtitle1" color="primary">£
                    <NumberFormat
                        displayType={'text'}
                        value={price}
                        decimalScale={2} />
                </Typography>
            </Stack >
        )
    }

    return (
        <Stack divider={!hiddenDivider && <Divider orientation="horizontal" style={{ border: "1px dashed rgba(0,0,0,.09)" }} />}>
            <Grid>
                {!hasFee && <Typography variant="caption">{locale.hasFee}</Typography>}
            </Grid>
            <Grid >
                {renderPrice('Estimated cost', totalCost)}
                {renderPrice('Shipping fee', shipFee)}
            </Grid>
        </Stack>
    )
}
interface Props {
    totalCost: number,
    shipFee: number,
    hiddenDivider?: boolean,
    hasFee?: boolean
}