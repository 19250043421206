import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { GrDocumentText } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom'

export function NotFound(props: Props) {
    const history = useNavigate()
    return (
        <Grid xs={12} container justifyContent="center" >
            <Grid xs={6} pt={10} pb={5} item justifyContent="center">
                <Grid style={{ display: 'flex', justifyContent: "center" }}>
                    <img src="/search.png" style={{ width: 200, height: 200 }} />
                </Grid>
                <Typography variant="h5" textAlign="center" mt={3}>{props.title}</Typography>
                <Typography textAlign="center" mt={2}>{props.subTitle}</Typography>
                <Grid xs={12} container justifyContent="center" mt={2}>
                    <Button sx={{minWidth:200}} variant="contained" size="large" color="primary" onClick={() => history("/")}>Shop now!</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

interface Props {
    title: string
    subTitle: string
}