import { OrderVariant } from '@CoreCommerce/models/OrderVariant';
import { Box, Grid, Stack, styled, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router';

const useStyles = makeStyles((theme: Theme) => ({
    image: {
        width: 105,
        height: 105,
        borderRadius: 5,
        cursor: 'pointer',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    name: {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
    },

    price: {
        fontWeight: 400,
        color: theme.palette.primary.main,
    },
    isPrice: {
        fontSize: 16,
        color: theme.palette.grey[600],
        textDecoration: 'line-through',
    },
}));
export default function VariantItems({ v }: Props) {
    const theme = useTheme();
    const attrs = v.variant?.attributes || {};
    const history = useNavigate();
    const classes = useStyles();

    const price = _.round(v.variant?.price || 0, 2);
    const salePrice = v.variant?.saleOffPrice || 0;
    const hasSalePrice = salePrice > 0 && salePrice < price;
    return (
        <Grid container justifyContent="space-between" alignItems="center" p="16px 32px">
            <Stack direction="row" width={{ sm: '70%', xs: '100%' }} mb={2}>
                <Box
                    className={classes.image}
                    sx={{ background: `url(${v.variant?.media?.url || v.variant?.product?.media?.[0].url})` }}
                    onClick={(e) => history(`/product/${v.variant?.product?.shopId}/${v.variant?.productId}`)}
                />
                <Stack maxWidth={300} pl={2}>
                    <Typography
                        variant="subtitle2"
                        maxWidth={250}
                        className={classes.name}
                        sx={{
                            WebkitLineClamp: { sm: 2, xs: 1 },
                        }}
                    >
                        {v.variant?.product?.name}
                    </Typography>
                    <Typography variant="caption">
                        {Object.keys(attrs).length ? (
                            <span>
                                (
                                {Object.keys(attrs)
                                    .map((key) => attrs[key])
                                    .join(', ')}
                                )
                            </span>
                        ) : (
                            <span></span>
                        )}
                    </Typography>
                    <Stack direction="row" alignItems="flex-start">
                        {hasSalePrice ? (
                            <Typography
                                className={classes.price}
                                pr={0.5}
                                sx={{ display: { sm: 'none', xs: 'block' } }}
                            >
                                £{salePrice}
                            </Typography>
                        ) : (
                            <Typography
                                className={classes.price}
                                pr={0.5}
                                sx={{ display: { sm: 'none', xs: 'block' } }}
                            >
                                £{price}
                            </Typography>
                        )}
                        <Typography variant="subtitle2" fontSize={14} fontStyle="italic">
                            (x{v.quantity})
                        </Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" gap={1} display={{ sm: 'flex', xs: 'none' }}>
                <Typography
                    variant="subtitle2"
                    fontWeight={400}
                    fontSize={22}
                    className={clsx(classes.price, { [classes.isPrice]: hasSalePrice })}
                >
                    £{price}
                </Typography>
                {hasSalePrice && (
                    <Typography variant="h5" fontWeight={400} color={theme.palette.primary.main}>
                        £{salePrice}{' '}
                    </Typography>
                )}
            </Stack>
        </Grid>
    );
}
interface Props {
    v: OrderVariant;
}
