import { Button, Grid, Typography } from '@mui/material';
import { useContext } from 'react'
import Card from '../../submodule/base-web/component/common/Card';
import { ControllerContext } from '../../hook/useController'
import { Account, LoginAccount, loginAccountSchema } from '../../submodule/account-model/model/Account';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from 'react-router-dom';
import FormTextField from '@BaseWeb/component/common/FormTextField';

export default function RegisterPage() {
    const { accountController } = useContext(ControllerContext);
    const { control, handleSubmit, formState } = useForm<LoginAccount>({ resolver: yupResolver(loginAccountSchema), mode: "onChange", reValidateMode: "onChange" })
    const history = useNavigate();

    const onSubmit = (account: Account) => accountController.register(account.username, account.password, "test").then(_ => { })
    const onError = (error) => console.log(error);

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid mt={15} item xs={4}>
                <Card >
                    <Typography textAlign="center" variant="h3">Register</Typography>
                    <FormTextField<LoginAccount> control={control} name="username" textFieldProps={{ label: "Username" }} />
                    <FormTextField<LoginAccount> control={control} name="password" textFieldProps={{ label: "Password" }} />
                    <Button disabled={!formState.isValid} fullWidth variant="contained" onClick={e => { handleSubmit(onSubmit, onError)() }}>Register</Button>
                    <Button onClick={() => history("/login")}>Login</Button>
                </Card>
            </Grid>
        </Grid>
    )
}
