import { Grid, Stack, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { NotFound } from 'src/component/search-products/NotFound';
import { ControllerContext } from 'src/hook/useController';
import WishListItem from './components/WishListItem';
import useWishList, { WishListContext } from './useWishList';

export default function WishList() {
    const { wishListController } = useContext(ControllerContext)
    const { state, setState, loading } = useContext(WishListContext)
    const theme = useTheme()
    const quantity = `${state.length} ITEMS`
    const handleRemove = (productId: string) => {
        wishListController.remove(productId).then(res => {
            wishListController.myList().then(res => {
                setState(res)
            })
        })
    }
    const isExistItem = state.length == 0 && !loading

    return (
        <>
            {!isExistItem ? (
                <Grid container mb={10}>
                    {!loading ? (
                        <>
                            <Stack p={{ xs: '0 0.5rem' }}>
                                <Typography variant="h3" color={theme.palette.secondary.dark} mt={5}>
                                    MY WISHLIST
                                </Typography>
                                <Typography>{quantity}</Typography>
                            </Stack>
                            <Grid container spacing={1} p={{ xs: '0 0.5rem' }} sx={{ mt: 2 }}>
                                {state.map((item) => {
                                    return (
                                        <WishListItem key={item.id} product={item} handleRemove={() => handleRemove(item.id || "")} />
                                    );
                                })}
                            </Grid>
                        </>
                    ) : (
                        <Grid container justifyContent="flex-start" mt={{ md: 5, sm: 0, xs: 1 }}>
                            <Grid md={12} sm={12} xs={12} ml={{ md: 0, sm: 2, xs: 2 }} mb={2}>
                                <Skeleton style={{ width: 200 }} height={35} />
                                <Skeleton style={{ width: 70, marginTop: 10 }} height={20} />
                            </Grid>
                            {[...new Array(24)].map((p, index) => (
                                <Grid key={index} item xs={6} sm={4} md={3} lg={3} xl={2}>
                                    <Skeleton style={{ width: '100%' }} height={300} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
            ) : (
                <NotFound title="Oops sorry, you haven't had any favorite product yet." subTitle='Back to Home to find your favorite products.' />
            )}

        </>
    );
}
