import { OrderController } from "@CoreCommerce/controllers/http/OrderController";
import { ProductController } from "@CoreCommerce/controllers/http/ProductController";
import { ShopController } from "@CoreCommerce/controllers/http/ShopController";
import { UserController } from "@CoreCommerce/controllers/http/UserController";
import React, { useContext, useMemo } from "react"
import { appConfig } from "../constant/IConfig"
import { AccountController } from "../controller/AccountController"
import { axiosCreator } from "../submodule/base-web/controller/AxiosCreator"
import { useNavigate } from 'react-router-dom';
import { GoogleAnalyticContext } from "@BaseWeb/hook/useGoogleAnalytic";
import { WishListController } from "@CoreCommerce/controllers/http/WishListController";
export default function useController({ onError, onLoading }: ControllerProps) {
    const googleAnalytics = useContext(GoogleAnalyticContext)
    const history = useNavigate()
    const axiosClient = axiosCreator({
        url: appConfig.apiGateway.account,
        onError: ({ status, message }) => {
            if (status === 403) { } else if (status == 401) { history('/login', {}) }
            else onError?.(message)
            // googleAnalytics.errorRequest(status,)
        },
        onLoad: (isLoading) => onLoading?.(isLoading)
    });

    const accountController = useMemo(() => new AccountController(axiosClient), [])
    const productController = useMemo(() => new ProductController(appConfig.apiGateway.ecommerce, axiosClient), [])
    const shopController = useMemo(() => new ShopController(appConfig.apiGateway.ecommerce, axiosClient), [])
    const orderController = useMemo(() => new OrderController(appConfig.apiGateway.ecommerce, axiosClient), [])
    const userController = useMemo(() => new UserController(appConfig.apiGateway.ecommerce, axiosClient), [])
    const wishListController = useMemo(() => new WishListController(appConfig.apiGateway.ecommerce, axiosClient), [])
    return { axiosClient, accountController, productController, shopController, orderController, userController, wishListController }
}

export interface ControllerProps {
    onLoading?(isLoading: boolean): void
    onError?(message: string): void
    children?: any
}

export const ControllerContext = React.createContext<ReturnType<typeof useController>>({} as any);
