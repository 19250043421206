/* eslint-disable react-hooks/exhaustive-deps */
import { Shop } from '@CoreCommerce/models/Shop'
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { ControllerContext } from './useController'

export default function useShop() {
    const [state, setState] = useState<State>({ shop: {} as Shop })
    const { shopController } = useContext(ControllerContext)
    const { shopId } = useParams<{ shopId: string }>()
    const location = useLocation()

    useEffect(() => {
        if (shopId === state.shop?.id) return;
        if (shopId) shopController.get(shopId).then(shop => setShop(shop))
        else setShop(undefined)
    }, [location.pathname])

    const changeShop = (id: string) => shopController.get(id).then(shop => {
        setShop(shop)
    })

    const setPartialState = (partial: Partial<State>) => setState(prev => ({ ...prev, ...partial }))
    const setShop = (shop?: Shop) => setPartialState({ shop })

    return {
        ...state, setShopState: setPartialState, changeShop
    }
}

interface State {
    shop?: Shop
}

export const ShopContext = React.createContext<ReturnType<typeof useShop>>({} as any)