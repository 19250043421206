import { Order } from '@CoreCommerce/models/Order';
import { Button, Grid, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router';
import { appLocale } from 'src/constant/Locale';
import { ControllerContext } from 'src/hook/useController';
import OrderItem from 'src/page/info/order-history/component/OrderItem';

export function CheckoutCompletePage() {
    const [state, setState] = useState<State>();

    const { orderId } = useParams<{ orderId: string }>();
    const { orderController } = useContext(ControllerContext);

    const theme = useTheme()
    const history = useNavigate();
    const locale = appLocale.page.checkout.completed;

    useEffect(() => {
        const orderIds = orderId?.split(',') || [];
        getOrders(orderIds);
    }, [orderId]);

    const getOrders = async (orderIds: string[]) => {
        if (orderIds && orderIds.length > 0) {
            const orders = await Promise.all(orderIds.map((id) => orderController.get(id)));
            setState((prev) => ({ ...prev, orders }));
        }
    };

    return state?.orders ? (
        <Grid container justifyContent="center" mt={3} mb={10}>
            <Grid container item sm={12} xs={12} p={{ md: 0, sm: "0 1rem", xs: "0 1rem" }}>
                <Grid item md={12} sm={12} xs={12}>
                    {state.orders.map((order) => (
                        <OrderItem order={order} />
                    ))}
                </Grid>
                <Grid container item xs={12} justifyContent="space-between" mt={3}>
                    <Button onClick={(e) => history('/')} color="secondary" startIcon={<BiLeftArrowAlt />}>
                        {locale.backToHome}
                    </Button>
                    <Button
                        onClick={(e) => history('/user/orders')}
                        color="primary"
                        variant="contained"
                        endIcon={<BiRightArrowAlt />}
                    >
                        {locale.listOrders}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    ) : (
        <div></div>
    );
}

interface State {
    orders?: Order[];
}
