/* eslint-disable react-hooks/exhaustive-deps */
import { IBaseController } from "@CoreCommerce/controllers/IBaseController";
import { Paging } from "@CoreCommerce/models/Paging";
import { Filter, Search } from "@CoreCommerce/models/Query";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";

const DEFAULT_PAGE_SIZE = 10;
const defaultPagination: Paging<any> = {
  rows: [],
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  total: 0,
  totalPages: 0
}

export function usePagination<T>(props: UsePaginationProps<T>) {
  const [state, setState] = useState<State<T>>({ ...props, loading: false, pagination: { ...defaultPagination, ...props.pagination } } as State<T>)

  useEffect(() => {
    getList();
  }, [state.pagination.page, state.pagination.pageSize, JSON.stringify(state.filter), state.search]);

  const onChangePage = (page: number, pageSize?: number) => {
    setPartialState({ pagination: { ...state.pagination, page, pageSize: pageSize || state.pagination?.pageSize || DEFAULT_PAGE_SIZE } });
  };

  const getList = () => {
    if (state.loading) return;
    const { pagination: { page, pageSize }, filter, search } = state
    setPartialState({ loading: true });

    return props.controller
      .list({ page: page, pageSize, filter, search })
      .then((pagination: Paging<T>) => {
        if (pagination.rows?.length === 0 && pagination.page > 1)
          setPartialState({ loading: false, pagination: { ...state.pagination, page: pagination.page - 1 } });
        else setPartialState({ pagination, loading: false });
        return pagination;
      });
  };

  const onChangeFilter = (filter: Filter<T>) => {
    setPartialState({ filter, pagination: { ...state.pagination, page: 1 } });
  };

  const onChangeSearch = (search: Partial<Search<T>>) => {
    console.log({ state: state.search, search });
    onChangeSearchDebounce({ ...state.search, ...search } as Search<T>);
  };

  const setPartialState = (partial: Partial<State<T>>) => setState(prev => ({ ...prev, ...partial }))

  const onChangeSearchDebounce = useCallback(
    _.debounce((search: Search<T>) => {
      setPartialState({ search, pagination: { ...state.pagination, page: 1 } });
    }, 500),
    []
  );

  return {
    ...state,
    onChangePage,
    onChangeFilter,
    onChangeSearch,
    getList,
    searchPagingState: setState
  };
}

interface State<T> {
  loading: boolean
  pagination: Paging<T>
  search: Search<T>
  filter?: Filter<T>
}

export interface UsePaginationProps<T> {
  controller: IBaseController<T>;
  pagination: Partial<Paging<T>>
  search?: Partial<Search<T>>
  filter?: Filter<T>
}
