import { Control, Controller, Path } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
export default function FormTextField<T>({ control, name, textFieldProps }: Props<T>) {
    return (
        <Controller
            name={name as any}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                    size="medium"
                    fullWidth
                    label="Field"
                    variant="outlined"
                    value={value}
                    {...textFieldProps}
                    error={!!error}
                    helperText={error?.message}
                    onChange={onChange}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            )}
        />
    )
}

interface Props<T> {
    control: Control<T, object>
    name: Path<T>
    textFieldProps: TextFieldProps
}