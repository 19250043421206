import React, { useContext, useEffect, useRef, useState } from "react"
import clsx from "clsx"
import { Typography, Stack, IconButton, Button, Divider, TextField, Grid, Theme, useTheme, Alert } from "@mui/material";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { makeStyles, styled } from '@mui/styles'
import { appLocale } from "src/constant/Locale";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { authentication } from "src/firebase-config";
import { AuthenContext } from "@BaseWeb/hook/useAuthen";
import { ControllerContext } from "src/hook/useController";
import * as Yup from "yup"
import { YupObject } from "@CoreAccount/model/yup/BaseYup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormTextField from "@BaseWeb/component/common/FormTextField";
import { IoHomeOutline } from "react-icons/io5";
import { GiShakingHands } from "react-icons/gi";
import { AccountWithRoles } from "@CoreAccount/model/Account";
import { AxiosError } from "@BaseWeb/controller/AxiosCreator";
import moment from "moment";

type Props = {};


const useStyles = makeStyles((theme: Theme) =>
({
    backgroundSwitchLoginRegister: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: theme.spacing(2),
        zIndex: 2,
        position: "relative",
        transition: '0.5s',

        '& >button': {
            width: "50%",
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            borderRadius: theme.spacing(2),
            color: 'black'
        },
    },
})
)
type AccountInput = {
    email: string;
    password: string;
    otp: string
}
let currentTimeOutButton: NodeJS.Timer | null = null;
function LoginContent(props: Props) {
    const accountInvalidationSchema = Yup.object<YupObject<AccountInput>>({
        otp: Yup.string().test("lenghtValue", (value, option) => {
            if (isLoginMode) return true as any;
            // eslint-disable-next-line no-template-curly-in-string
            if (!value) return "${path} is required";
            return true
        }).length(6),
        password: Yup.string().min(6).required().nullable(),
        email: Yup.string().required().nullable().email()
    });
    const refButtonSendOtp = useRef<HTMLButtonElement | null>(null)
    const formHook = useForm<AccountInput>({ reValidateMode: 'onChange', mode: 'onChange', resolver: yupResolver(accountInvalidationSchema) })

    const [state, setState] = useState<{ mode: "login" | "register" | "forgot", lastTimeSendOtp?: Date, message: { content: string, type: "error" | "success" } }>({
        mode: "login", lastTimeSendOtp: undefined,
        message: { content: "", type: "success" }
    });
    const classes = useStyles();
    const theme = useTheme()
    useEffect(() => {
        return () => { };
    }, []);

    const { userController, accountController } = useContext(ControllerContext)
    const { login } = useContext(AuthenContext)
    const [showPassword, setShowPassword] = useState<boolean>(false)
    const { title, labelAccount, labelPassword, labelOtherLogin, contentButton, loginGoogle } = appLocale.page.login;
    const signInWithGoogle = () => {
        const provider = new GoogleAuthProvider()
        signInWithPopup(authentication, provider)
            .then(async res => {
                const account = await userController.loginWithGoogle({ token: (res.user as any).accessToken })
                login(account.jwt || "")
                console.log({ res })
            })
            .catch(err => {
                console.log(err)
            })
    }
    const switchMode = (mode: typeof state.mode) => {
        setState(pre => ({ ...pre, mode, message: { content: "", type: "success" }, lastTimeSendOtp: undefined }))
    }

    const isLoginMode = state.mode == "login";
    const isRegisterMode = state.mode == "register"
    const handlePastOtp = () => {
        navigator.clipboard.readText().then(text => {
            const getOtp = text.match(/\d{6}/)
            formHook.setValue("otp", getOtp?.[0] || "")
        });
    }
    const isShowBtnSendOtp = () => {
        return Boolean(!formHook.formState.errors.email && formHook.getValues("email"))
    }
    const onSendOtp = () => {
        if (isRegisterMode) {
            accountController.createOtpRegister({ username: formHook.getValues("email") }).then(res => {
                setState(pre => ({ ...pre, lastTimeSendOtp: new Date(), message: { content: "We has send you an email", type: "success" } }))
            }).catch(err => setState(pre => ({ ...pre, message: { content: err.response.data.message, type: "error" } })))
        } else {
            accountController.requestForgotPassword(formHook.getValues("email")).then(res => {
                setState(pre => ({ ...pre, lastTimeSendOtp: new Date(), message: { content: "We has send you an email", type: "success" } }))
            }).catch(err => setState(pre => ({ ...pre, message: { content: err.response.data.message, type: "error" } })))
        }
    }

    const handleSubmit = async (values) => {
        let userInfo: AccountWithRoles | null = null;
        if (state.mode === "login") {
            userInfo = await accountController.login(values.email, values.password).catch(err => {
                setState(pre => ({ ...pre, message: { content: err.response.data.message, type: "error" } }))
                return null
            })
        } else if (state.mode === "register") {
            userInfo = await accountController.register(values.email, values.password, values.otp).catch((err) => {
                setState(pre => ({ ...pre, message: { content: err.response.data.message, type: "error" } }))
                return null
            })
        } else if (state.mode == "forgot") {
            userInfo = await accountController.resetPassword({ password: values.password, token: values.otp, username: values.email }).catch((err) => {
                setState(pre => ({ ...pre, message: { content: err.response.data.message, type: "error" } }))
                return null
            })
        }
        if (userInfo) {
            login(userInfo.jwt || "")
        }
    }

    const DEFAULT_TIME_WAIT = 60 * 1000;
    const checkTime = (timeCheck: Date): Boolean => new Date().getTime() - timeCheck.getTime() < DEFAULT_TIME_WAIT

    const isMaxTime = Boolean(state.lastTimeSendOtp && checkTime(state.lastTimeSendOtp))
    if (refButtonSendOtp.current && isMaxTime) {
        if (currentTimeOutButton) {
            clearInterval(currentTimeOutButton)
        }
        currentTimeOutButton = setInterval(() => {
            const btn = refButtonSendOtp.current;
            if (btn) {
                let currentContent = btn.textContent || "";
                currentContent = clearContentRefBtnOtp(currentContent);
                const remainTime = DEFAULT_TIME_WAIT / 1000 - moment(new Date()).diff(state.lastTimeSendOtp, "second")
                if (remainTime == 0) {
                    if (currentTimeOutButton) clearInterval(currentTimeOutButton)
                    btn.textContent = currentContent;
                    setState({ ...state })
                } else {
                    btn.textContent = currentContent + `(${remainTime})s`;
                }
            }
        }, 1000)
    }
    const clearContentRefBtnOtp = (content: string) => content.replace(/\d/g, "").replace(/\(/, "").replace(/\)s/, "")

    /// function get by 

    return <Grid >
        <Grid container justifyContent="center">
            <Stack direction="row" className={classes.backgroundSwitchLoginRegister} sx={{
                "&:before": {
                    content: "''",
                    zIndex: 0,
                    width: "33.3%",
                    height: "100%",
                    position: "absolute",
                    transition: '0.3s',
                    top: 0,
                    left: isLoginMode ? "0%" : isRegisterMode ? "33.3%" : "66.6%",
                    background: theme.palette.primary.main,
                    borderRadius: theme.spacing(2)
                }
            }}>
                <Button onClick={() => switchMode("login")} style={{ color: isLoginMode ? "white" : undefined }} >
                    Login
                </Button >
                <Button onClick={() => switchMode("register")} style={{ color: isRegisterMode ? "white" : undefined }} >
                    Register
                </Button>
                <Button onClick={() => switchMode("forgot")} style={{ color: !(isLoginMode || isRegisterMode) ? "white" : undefined }} >
                    Forgot
                </Button>
            </Stack>
        </Grid>
        <form onSubmit={formHook.handleSubmit(handleSubmit, () => { })}>
            <Stack spacing={3} sx={{ maxWidth: "600px", alignItems: "center", mt: 5 }}>
                {state.message.content && <Grid container mt={-2}><Alert severity={state.message.type} style={{ width: "100%" }}>{state.message.content}</Alert></Grid>}
                <FormTextField
                    control={formHook.control}
                    name="email"
                    textFieldProps={{
                        InputLabelProps: { shrink: true },
                        label: labelAccount,
                        fullWidth: true,
                        InputProps: !isLoginMode && isShowBtnSendOtp() ? {
                            endAdornment: (
                                <Button
                                    variant="contained"
                                    sx={{ borderRadius: 20, whiteSpace: "nowrap", px: 2 }}
                                    size="small"
                                    disabled={isMaxTime}
                                    tabIndex={-1}
                                    onClick={onSendOtp}
                                    ref={refButtonSendOtp as any}
                                >
                                    Send OTP
                                </Button>
                            ),
                        } : undefined,
                    }}
                />
                <FormTextField
                    control={formHook.control}
                    name="password"
                    textFieldProps={{
                        InputLabelProps: { shrink: true },
                        label: labelPassword,
                        fullWidth: true,
                        type: showPassword ? "text" : "password",
                        InputProps: {
                            endAdornment: (
                                <IconButton
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <BsEye /> : <BsEyeSlash />}
                                </IconButton>
                            ),
                        }
                    }}
                />
                {Boolean(!isLoginMode && isShowBtnSendOtp() && state.lastTimeSendOtp) && <FormTextField
                    control={formHook.control}
                    name="otp"
                    textFieldProps={{
                        InputLabelProps: { shrink: true },
                        label: "OTP",
                        fullWidth: true,
                        type: "number",
                        InputProps: {
                            endAdornment: (
                                <Button
                                    variant="contained"
                                    sx={{ borderRadius: 20, whiteSpace: "nowrap", px: 2 }}
                                    size="small"
                                    tabIndex={-1}
                                    onClick={handlePastOtp}
                                >
                                    Past
                                </Button>
                            ),
                        }
                    }}
                />}
            </Stack>
            <Grid
                container
                justifyContent="flex-end"
                width="100%"
                marginTop="10px"
            >
                {/* <Button size="medium" variant="text">Quên mật khẩu?</Button> */}
            </Grid>
            <Grid container justifyContent="center" mt={1}>
                <Button variant="contained" fullWidth size="large" type="submit">{isLoginMode ? contentButton : isRegisterMode ? "Register" : "Reset password"}</Button>
            </Grid>
        </form>
        <Divider sx={{ marginTop: 3, color: "gray" }}>{labelOtherLogin}</Divider>
        <Grid container justifyContent="center" mt={3}>
            <Button variant="outlined" onClick={signInWithGoogle} fullWidth size="large" startIcon={<FcGoogle></FcGoogle>}>{loginGoogle}</Button>
        </Grid>
    </Grid >;
}

export default LoginContent;