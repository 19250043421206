import { Avatar, Badge, Box, Button, Divider, Grid, Paper, Stack, Typography } from '@mui/material';
import clsx from 'clsx';
import { AiFillInstagram } from 'react-icons/ai';
import { FiHeart } from 'react-icons/fi';
import { GiShoppingBag } from 'react-icons/gi';
import { GoPackage } from 'react-icons/go';
import { IoMdLogOut } from 'react-icons/io';
import { MdFacebook } from 'react-icons/md';
import { RiHeartFill } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import LogoAfi from 'src/component/icons/LogoAfi';
import SearchProducts from 'src/component/search-products';
import { HeaderProps, IMenuAction } from '../IMenu';
import { styles, useMenuStyles } from '../styles';

export default function WebHeader({ cartNumber, wishListNumber, logout, locale, user }: HeaderProps) {
    const classes = useMenuStyles();
    const history = useNavigate();

    const userMenuActions: IMenuAction[] = [
        { label: locale.menuTitle.home, doAction: () => history('/') },
        { label: locale.menuTitle.orders, doAction: () => history('/user/orders'), icon: <GoPackage /> },
        { label: locale.menuTitle.logout, doAction: () => logout(), icon: <IoMdLogOut /> },
    ];

    const leftMenuActions: IMenuAction[] = [
        { label: locale.menuTitle.home, doAction: () => history('/') },
        { label: locale.menuTitle.support, doAction: () => history('/user/orders'), icon: <GoPackage /> },
        { label: locale.menuTitle.logout, doAction: () => logout(), icon: <IoMdLogOut /> },
    ];

    return (
        <Grid className={clsx(classes.root, classes.webHeader)} container justifyContent="center" alignItems="flex-start">
            <Grid item container md={8}>
                <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            divider={<Divider sx={{ borderWidth: '0.1' }} orientation="vertical" flexItem />}
                        >
                            <Typography
                                variant="caption"
                                className={classes.menuTitle}
                                onClick={(e) => history('/about')}
                            >
                                {locale.menuTitle.support}
                            </Typography>
                            <Typography
                                variant="caption"
                                className={classes.menuTitle}
                                onClick={(e) => history('/policy')}
                            >
                                {locale.menuTitle.policy}
                            </Typography>
                            <Stack direction="row" alignItems="center" gap={0.5}>
                                <Typography variant="caption" color="white">
                                    {locale.menuTitle.contact}
                                </Typography>
                                <a href="https://www.facebook.com/BeeLuxuryShopping" target="_blank" style={{ lineHeight: 0 }}>
                                    <MdFacebook className={classes.socialIcons} fontSize="23px !impotant" />
                                </a>
                                <AiFillInstagram className={classes.socialIcons} />
                            </Stack>
                        </Stack>
                        <Box
                            sx={{
                                position: 'relative',
                                '&:hover > .popup': {
                                    display: 'block',
                                },
                            }}
                        >
                            <Stack mt={1} pb="5px" direction="row" alignItems="center" gap={0.5}>
                                <Stack direction="row" alignItems="center" gap={0.5}>
                                    <Avatar
                                        alt={user?.username || ""}
                                        src={user?.picture || '/static/images/avatar/1.jpg'}
                                        className={classes.avatarIcon}
                                    />
                                    <Typography
                                        color="white"
                                        variant="caption"
                                    >{`${user?.firstName || ""} ${user?.lastName || ""}`}</Typography>
                                </Stack>
                            </Stack>
                            <Box sx={styles} className="popup">
                                <Button
                                    onClick={(e) => history('/user/orders')}
                                    endIcon={<GoPackage />}
                                    fullWidth
                                    color="secondary"
                                >
                                    {locale.menuTitle.orders}
                                </Button>
                                <Box sx={{ width: '100%', borderTop: '1px solid #ddd' }} />
                                <Button
                                    onClick={(e) => history('/wishlist')}
                                    endIcon={<FiHeart />}
                                    fullWidth
                                    color="secondary"
                                >
                                    {locale.menuTitle.wishlist}
                                </Button>
                                <Box sx={{ width: '100%', borderTop: '1px solid #ddd' }} />
                                <Button onClick={(e) => logout()} endIcon={<IoMdLogOut />} fullWidth color="secondary">
                                    {locale.menuTitle.logout}
                                </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center" justifyContent="space-between" style={{ flexWrap: 'nowrap' }}>
                        <LogoAfi className={classes.logo} onClick={(e) => history('/')} />
                        <Grid item md={10} pl={2}>
                            <Stack direction="row" alignItems="center" gap={3}>
                                <Paper sx={{ width: '100%' }}>
                                    <SearchProducts />
                                </Paper>
                                <Badge badgeContent={wishListNumber} color="secondary">
                                    <RiHeartFill
                                        onClick={(e) => history('/wishlist')}
                                        className={classes.rootIcons}
                                    />
                                </Badge>
                                <Badge badgeContent={cartNumber} color="secondary">
                                    <GiShoppingBag
                                        onClick={(e) => history('/checkout/cart')}
                                        className={classes.rootIcons}
                                    />
                                </Badge>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
