import { Product } from '@CoreCommerce/models/Product';
import { Avatar, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useBaseStyle } from 'src/component/styled-components/baseStyle';
import { appLocale } from 'src/constant/Locale';
import { ControllerContext } from 'src/hook/useController';
import { usePagination } from 'src/hook/usePagination';
import { ShopContext } from 'src/hook/useShop';

const useStyles = makeStyles((theme: Theme) => ({
    baseContainer: {
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
    },
    logo: {
        width: "80px", height: "80px",
        boxShadow: "rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset",
        cursor: 'pointer',
        backgroundColor: "#fff",
        "& img": {
            width: "80%",
            height: "80%",
            objectFit: "scale-down",
            borderRadius: "50%"
        }
    },
    name: {
        padding: "5px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px"
        , minWidth: "150px",
        position: "relative",
        background: "white",
        top: "-10px",
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.main,
            transition: '0.2s'
        }
    },
    inforWrapper: {
        display: "grid", gridTemplateColumns: "1fr 1fr 1fr", justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            justifyContent: "space-evenly",
        }
    },
    infoItem: {
        fontSize: "14px",
        textAlign: "center",
        "& b": {
            color: theme.palette.primary.main
        },
        [theme.breakpoints.down('sm')]: {
            display: "grid",
        }
    }
}))

export default function ShopInfor({ shopId }: { shopId: string }) {
    const { shop, changeShop } = useContext(ShopContext)
    const { productController } = useContext(ControllerContext)
    const { pagination } = usePagination<Product>({
        controller: productController as any,
        pagination: { pageSize: 1 }, filter: { shopId }
    })

    useEffect(() => {
        changeShop(shopId)
    }, [shopId])

    const classes = useStyles()
    const baseClass = useBaseStyle()
    const locale = appLocale.page.shop.shopInfo
    const history = useNavigate()
    console.log(shop?.logo);
    return (
        <Grid container xs={12} justifyContent="center">
            <Grid xs={12} p="20px" className={clsx(classes.baseContainer, baseClass.wrapper)}>
                <Grid className={classes.baseContainer}>
                    <Avatar src={shop?.logo} className={classes.logo} onClick={e => history(`/shop/${shop?.id}`)} />
                    <Typography variant="subtitle1" align="center" className={classes.name} onClick={e => history(`/shop/${shop?.id}`)}>{shop?.name}</Typography>
                </Grid>
                <Grid mt={1} className={classes.inforWrapper}>
                    <Typography className={classes.infoItem} >
                        {locale.titleProduct}: <b> {pagination.total}</b></Typography>
                    <Typography className={classes.infoItem}>
                        {locale.titleStatus}:<b> {locale.status}</b></Typography>
                    <Typography className={classes.infoItem}>
                        {locale.titleTime}:<b>{` ${moment(shop?.createdAt).format('DD/MM/yyyy')}`}</b></Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}