import { Grid, Paper, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { BsCashCoin } from 'react-icons/bs'
import { FaShippingFast } from 'react-icons/fa'
import { MdOutlineLocalOffer, MdOutlinePayments } from 'react-icons/md'
import { appLocale } from 'src/constant/Locale'
import IntroduceItem from './IntroduceItem'
const useStyles = makeStyles((theme: Theme) => ({

    icon: {
        fontSize: 24,
        color: theme.palette.secondary.darker
    },

}))
export default function IntroduceBar() {
    const classes = useStyles()
    const { item1, item2, item3, item4 } = appLocale.page.home.intro
    return (
        <Grid item xs={12} container justifyContent="center" mt={2}>
            <Paper elevation={0} sx={{ width: '100%', pt: 2, pb: 2, textAlign: 'center' }} >
                <Grid container justifyContent="space-around" xs={12}>
                    <IntroduceItem title={item1} icon={<MdOutlineLocalOffer className={classes.icon} />} />
                    <IntroduceItem title={item2} icon={<BsCashCoin className={classes.icon} />} />
                    <IntroduceItem title={item3} icon={<MdOutlinePayments className={classes.icon} />} />
                    <IntroduceItem title={item4} icon={<FaShippingFast className={classes.icon} />} />
                </Grid>
            </Paper>
        </Grid>
    )
}
