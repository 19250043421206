import { Product } from '@CoreCommerce/models/Product';
import { Shop } from '@CoreCommerce/models/Shop';
import { Button, CircularProgress, Grid, useMediaQuery } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { ProductList } from 'src/component/product-base/ProductList';
import { appLocale } from 'src/constant/Locale';
import { ControllerContext } from 'src/hook/useController';
import { usePagination } from 'use-crud-with-pagination';
import Banner from './component/Banner';
import IntroduceBar from './component/IntroduceBar';
import ListBrands from './component/ListBrands';
import User from './component/User';

export default function HomePage() {
    const { productController, shopController } = useContext(ControllerContext);
    const { pagination } = usePagination<Shop>({ controller: shopController as any, pagination: { pageSize: 10 } });
    const [state, setState] = useState<State>({ products: [], loading: true, page: 1, loadingMore: false });

    useEffect(() => {
        if (pagination.rows.length > 0) {
            getProducts(pagination.rows).then((products) =>
                setState((prev) => ({ ...prev, products, loading: false })),
            );
        }
    }, [pagination.rows]);
    const getProducts = async (shops: Shop[], page?: number) => {
        return productController.shuffleProductByShopIds({
            pageSize: 24,
            page: page,
            shopId: shops.map(s => s.id || "")
        })
    };

    const loadMore = async () => {
        setState((prev) => ({ ...prev, loadingMore: true }));
        const products = await getProducts(pagination.rows, state.page);
        setState((prev) => ({
            ...prev,
            loadingMore: false,
            page: state.page + 1,
            products: state.products.concat(products),
        }));
    };
    const matches = useMediaQuery('(width:1024px)');
    const locale = appLocale.page.home;
    return (
        <Grid container>
            <Grid container spacing={1}>
                <Grid item md={matches ? 12 : 2} sm={12} xs={12} mt={2} >
                    <ListBrands />
                </Grid>

                <Grid item md={matches ? 12 : 8} sm={12} xs={12} >
                    <Banner />
                </Grid>
                <Grid item md={matches ? 12 : 2} sm={12} xs={0} mt={2} display={{ md: matches ? 'none' : 'flex', sm: 'none', xs: 'none' }}>
                    <User />
                </Grid>
            </Grid>
            <IntroduceBar />
            <ProductList {...{ ...state }} sx={{ mt: 1 }} />
            <Grid item xs={12} container justifyContent="center" mt={5} mb={7}>
                {state.loadingMore ? (
                    <CircularProgress />
                ) : (
                    <Button onClick={loadMore} style={{ minWidth: 200 }} variant="contained" color="primary">
                        {locale.showMore}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
}

interface State {
    products: Product[];
    loading: boolean;
    loadingMore: boolean;
    page: number;
}
