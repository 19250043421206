import { Box, Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
const useStyles = makeStyles((theme: Theme) => ({
    base: {
        width: 64,
        height: 64,
        display: 'inline-block',
        backgroundColor: 'currentcolor',
        color: 'rgb(250, 84, 28)',
    },
    title: {
        fontSize: '1.25rem',
        fontWeight: 600,
        marginTop: 40,
        marginBottom: 16,
        cursor: 'pointer',
        transition: '.2s ease-in-out',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
    content: {
        width: '70%',
        fontSize: '1rem',
        color: '#637381',
    },
}));
export default function OurValue(props: Props) {
    const classes = useStyles();
    return (
        <Grid container direction="column" alignItems="center" textAlign="center" md={3} sm={6} xs={12} mt={5}>
            <Box
                className={classes.base}
                sx={{ WebkitMask: `url(${props.urlImage}) center center / contain no-repeat` }}
            />
            <Typography className={classes.title}>{props.title}</Typography>
            <Typography className={classes.content}>{props.content}</Typography>
        </Grid>
    );
}
interface Props {
    title: string;
    content: string;
    urlImage: string;
}
