import { Product } from '@CoreCommerce/models/Product';
import { Grid, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { AiOutlineRight } from 'react-icons/ai';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useNavigate, useParams } from 'react-router';
import { ProductList } from 'src/component/product-base/ProductList';
import { useBaseStyle } from 'src/component/styled-components/baseStyle';
import { appLocale } from 'src/constant/Locale';
import { ControllerContext } from 'src/hook/useController';
import ShopInfor from '../shop/ShopInfor';
import ProductDescription from './section/ProductDescription';
import ProductInformation from './section/purchase-info';
import Image2 from './section/purchase-info/Image2';
import { ProductDetailContext, useProductDetail } from './useProductDetail';
import clsx from 'clsx';
import { GoogleAnalyticContext } from '@BaseWeb/hook/useGoogleAnalytic';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'grid',
        gridTemplateColumns: '250px 1fr',
        columnGap: '2rem',
        padding: '20px 12px',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '100%',
            padding: 0,
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: '400px 1fr',
            columnGap: '3rem',
        },
    },
    productInforWrapper: {
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            padding: '20px 12px',
        },
    },
    seeMore: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
    },
}));

export function ProductDetailPage({ productId }: { productId: string }) {
    const productDetailHook = useProductDetail({ productId });
    const { state } = productDetailHook;
    const { productController } = useContext(ControllerContext);
    const [products, setProducts] = useState<Product[]>([]);
    const shopId = state.product.shopId;
    const history = useNavigate();
    const classes = useStyles();
    const baseClasses = useBaseStyle();
    const locale = appLocale.page.details;
    const pageSize = 6;
    useEffect(() => {
        if (shopId) {
            productController.list({ filter: { shopId }, pageSize: 1 }).then((res) => {
                productController
                    .list({ filter: { shopId }, pageSize, page: _.random(1, Math.round(res.total / pageSize)) })
                    .then((res) => {
                        setProducts(res.rows);
                    });
            });
        }
    }, [shopId, productId]);
    return (
        <ProductDetailContext.Provider value={productDetailHook}>
            <Grid xs={12} item sx={{ mt: { sm: 0, md: 2 } }} m="auto">
                <Grid xs={12} item className={clsx(classes.container, baseClasses.wrapper)}>
                    <Grid item style={{ width: '100%' }}>
                        {state.product.id ? <Image2 /> : <Skeleton height="350px" />}
                    </Grid>
                    <Grid className={classes.productInforWrapper}>
                        {state.product.id ? <ProductInformation /> : <Skeleton height="600px" />}
                    </Grid>
                </Grid>
                {state.product?.shopId ? (
                    <Grid mt={2}>
                        <ShopInfor shopId={state.product.shopId || ''} />
                    </Grid>
                ) : (
                    <Skeleton width="100%" height="150px" />
                )}

                {state.product?.desc && state.product?.desc.length > 0 && (
                    <Grid mt={2}>
                        <ProductDescription desc={state.product?.desc} />
                    </Grid>
                )}

                <Grid
                    container
                    alignItems="center"
                    mt={{ md: 4, sm: 2, xs: 2 }}
                    mb={1}
                    justifyContent="space-between"
                    p={{ md: 0, sm: 1, xs: 1 }}
                >
                    <Typography>{locale.similarProducts}</Typography>
                    <Stack direction="row" alignItems="center" className={classes.seeMore}>
                        <Typography fontSize={14} onClick={(e) => history(`/shop/${shopId}`)}>
                            {locale.seeMore}
                        </Typography>
                        <AiOutlineRight onClick={(e) => history(`/shop/${shopId}`)} />
                    </Stack>
                </Grid>
                {state.product.shopId ? (
                    <div style={{ marginBottom: 80 }}>
                        <ProductList {...{ products }} />
                    </div>
                ) : (
                    <Grid container xs={12} spacing={1}>
                        {[...new Array(6)].map((p, index) => (
                            <Grid key={index} item xs={6} sm={4} md={3} lg={3} xl={2}>
                                <Skeleton style={{ width: '100%' }} height={300} />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Grid>
        </ProductDetailContext.Provider>
    );
}

export function ProductDetailPageRoute() {
    const { productId } = useParams<'productId'>();
    const googleAnalytics = useContext(GoogleAnalyticContext);
    useEffect(() => {
        productId && googleAnalytics.eventSeeDetail(productId);
    }, []);
    return productId ? <ProductDetailPage productId={productId} /> : <div />;
}
