import React from "react"
import { CgAdidas } from 'react-icons/cg'
import { FaChild, FaShopify } from "react-icons/fa"
import { GiClothes, GiMirrorMirror } from "react-icons/gi"
import { IoMdShirt } from "react-icons/io"
export interface Brand {
    id: string
    key: string
    name: string
    feeShip?: {
        fee: number,
        freeWithMinimum?: number
    },
    icon?: React.ReactNode
}

export const brands: Brand[] = [
    {
        id: " ",
        key: "",
        name: "All",

    },
    {
        id: "0292794d-2681-4cbb-b104-5a77fdb76fc8",
        key: 'adidas',
        name: "Adidas", feeShip: {
            fee: 3.99,
            freeWithMinimum: 25
        },
        icon: <CgAdidas />
    },
    {
        id: "b566ad86-44df-4ec9-9af3-5cfd52043a47",
        key: "boots",
        name: "Boots", feeShip: {
            fee: 3.99,
            freeWithMinimum: 25
        },
        icon: <GiMirrorMirror />
    },
    {
        id: "cbcef1ee-cd67-455f-a5eb-ea330f0ef968",
        key: "tu-clothing",
        name: "Tu Clothing",
        feeShip: {
            fee: 3.99,
        },
        icon: <IoMdShirt />
    },
    {
        id: "7dd6a828-1497-4896-885e-64c472e735c4",
        key: 'george',
        name: "George",
        feeShip: {
            fee: 3.99,
        },
        icon: <FaChild />
    },
    {
        id: "d727c568-9a3c-4018-8183-3c567aca3802",
        key: 'matalan',
        name: "Matalan",
        feeShip: {
            fee: 3.99,
            freeWithMinimum: 40
        },
        icon: <GiClothes />
    },
    {
        id: "4bf5804b-6394-48ef-b41b-6fa57396789c",
        key: "river-island",
        name: "River Island",
        feeShip: {
            fee: 3.99,
            freeWithMinimum: 50
        },
        icon: <FaShopify />
    }
]