import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { SettingsProvider } from "./submodule/base-web/hook/useSettings";
import ThemeConfig from "./submodule/base-web/theme";
import ThemePrimaryColor from "./submodule/base-web/theme/ThemePrimaryColor";
import GlobalStyles from "./submodule/base-web/theme/globalStyles";
import PopupLogin from "./page/login/PopupLogin";

ReactDOM.render(
  <React.StrictMode>
    <SettingsProvider>
      <ThemeConfig>
        <ThemePrimaryColor>
          <GlobalStyles />
          <App />
        </ThemePrimaryColor>
      </ThemeConfig >
    </SettingsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
