import { Grid, Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.up('lg')]: {
            height: "60vh",
        },
        [theme.breakpoints.down('lg')]: {
            height: "50vh",
        },
        [theme.breakpoints.down('md')]: {
            height: "50vh",
        },
        [theme.breakpoints.down('sm')]: {
            height: "50vh",
        },
        backgroundImage: "url(https://minimal-assets-api.vercel.app/assets/images/about/vision.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        overflow: 'hidden',
        borderRadius: 30
    },

}))
export default function Banner2() {
    const classes = useStyles()
    return (
        <Grid item className={classes.root} md={8} sm={11} xs={11} mt={10} />
    )
}
