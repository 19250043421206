import { OrderVariant } from '@CoreCommerce/models/OrderVariant';
import {
    Box,
    Button,
    ButtonGroup,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Stack,
    Theme,
    Typography,
    useTheme
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import _ from 'lodash';
import React, { useContext } from 'react';
import { MdDeleteOutline } from 'react-icons/md';
import { useNavigate } from 'react-router';
import { CartContext } from '../../useCart';
const useStyles = makeStyles((theme: Theme) => ({
    image: {
        width: 105,
        height: 105,
        borderRadius: 5,
        cursor: 'pointer',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    name: {
        display: '-webkit-box',
        overflow: 'hidden',
        WebkitBoxOrient: 'vertical',
    },
    price: {
        fontWeight: 400,
        fontSize: 24,
    },
    isPrice: {
        fontSize: 18,
        color: theme.palette.grey[600],
        textDecoration: 'line-through',
    },
}));
export default function WebItems({ v }: Props) {
    const attrs = v.variant?.attributes || {};
    const { deleteFromCart, updateVariant, isVariantSelected, selectVariant } = useContext(CartContext);
    const theme = useTheme();
    const history = useNavigate();
    const classes = useStyles();
    const price = _.round(v.variant?.price || 0, 2);
    const salePrice = v.variant?.saleOffPrice || 0;
    const hasSalePrice = salePrice > 0 && salePrice < price;
    const isSale = (price: number, salePrice: number) => {
        let mainPrice = 0;
        if (hasSalePrice) {
            mainPrice = salePrice
        } else {
            mainPrice = price
        }
        return mainPrice
    }
    const subTotal = _.round((v.quantity || 1) * (v.variant?.saleOffPrice || v.variant?.price || 0), 2)
    return (
        <Grid
            container
            xs={12}
            alignItems="center"
            justifyContent="space-between"
            mb={{ sm: 0, xs: 2 }}
            p={{ sm: 4, xs: 0 }}
        >
            <Stack direction="row" width={{ sm: 'auto', xs: '100%' }}>
                <FormControlLabel
                    label=""
                    sx={{ m: 0 }}
                    control={
                        <Checkbox
                            checked={isVariantSelected(v.variantId || '')}
                            onChange={(e, checked) => selectVariant(v)}
                        />
                    }
                />
                <Box
                    className={classes.image}
                    sx={{
                        background: `url(${v.variant?.media?.url || v.variant?.product?.media?.[0].url})`,
                    }}
                    onClick={(e) => history(`/product/${v.variant?.product?.shopId}/${v.variant?.productId}`)}
                />
                <Stack maxWidth={300} pl={2}>
                    <Typography
                        variant="subtitle2"
                        className={classes.name}
                        width={{ sm: 200, xs: 180 }}
                        sx={{
                            WebkitLineClamp: { sm: 2, xs: 1 },
                        }}
                    >
                        {v.variant?.product?.name}
                    </Typography>

                    <Typography
                        variant="caption"
                        className={classes.name}
                        width={{ sm: 200, xs: 180 }}
                        sx={{
                            WebkitLineClamp: 1,
                        }}
                    >
                        {Object.keys(attrs).length ? (
                            <span>
                                (
                                {Object.keys(attrs)
                                    .map((key) => attrs[key])
                                    .join(', ')}
                                )
                            </span>
                        ) : (
                            <span></span>
                        )}
                    </Typography>
                    <Stack direction="row">

                        <Typography
                            variant="body2"
                            pr={0.5}
                            color={theme.palette.grey[600]}
                            sx={{ textDecoration: 'line-through' }}
                        >
                            £{price}
                        </Typography>
                        <Typography color={theme.palette.primary.dark}>£{isSale(price, salePrice)}</Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" display={{ sm: 'none', xs: 'flex' }}>
                        <ButtonGroup variant="outlined" size="small">
                            <Button
                                disabled={Boolean(Number(v.quantity) < 2)}
                                onClick={() => {
                                    updateVariant({ ...v, quantity: Number(v.quantity) - 1 });
                                }}
                            >
                                -
                            </Button>
                            <Button sx={{ pointerEvents: 'none' }}>{v.quantity}</Button>
                            <Button
                                disabled={Boolean(Number(v.quantity) > 19)}
                                onClick={() => {
                                    updateVariant({ ...v, quantity: Number(v.quantity) + 1 });
                                }}
                            >
                                +
                            </Button>
                        </ButtonGroup>
                        <IconButton onClick={(e) => v.variantId && deleteFromCart(v.variantId)}>
                            <MdDeleteOutline fontSize={24} fill={theme.palette.grey[600]} cursor="pointer" />
                        </IconButton>
                    </Stack>
                </Stack>
            </Stack>
            <Stack display={{ sm: 'flex', xs: 'none' }}>
                <ButtonGroup variant="outlined">
                    <Button
                        disabled={Boolean(Number(v.quantity) < 2)}
                        onClick={() => {
                            updateVariant({ ...v, quantity: Number(v.quantity) - 1 });
                        }}
                    >
                        -
                    </Button>
                    <Button sx={{ pointerEvents: 'none', width: 40, height: 40 }}>{v.quantity}</Button>
                    <Button
                        disabled={Boolean(Number(v.quantity) > 19)}
                        onClick={() => {
                            updateVariant({ ...v, quantity: Number(v.quantity) + 1 });
                        }}
                    >
                        +
                    </Button>
                </ButtonGroup>
            </Stack>
            <Typography color={theme.palette.primary.dark} minWidth={70} fontWeight={600} display={{ sm: 'block', xs: 'none' }}>£{subTotal}</Typography>
            <Stack display={{ sm: 'flex', xs: 'none' }}>
                <IconButton onClick={(e) => v.variantId && deleteFromCart(v.variantId)}>
                    <MdDeleteOutline fontSize={24} fill={theme.palette.grey[600]} cursor="pointer" />
                </IconButton>
            </Stack>
        </Grid>
    );
}
interface Props {
    v: OrderVariant;
}
