import { Grid } from '@mui/material'
import { useContext } from 'react'
import { Route, Routes } from 'react-router-dom'
import { NotFound } from 'src/component/search-products/NotFound'
import Steppers from './cart/component/Steppers'
import { CartContext } from './cart/useCart'
import useCheckout, { CheckoutContext, checkoutSteppers } from './useCheckout'


export default function CheckoutPage() {
    const checkoutHook = useCheckout()
    const { cart } = useContext(CartContext)
    const hasVariant = (cart.variants || []).length > 0
    return hasVariant ? (
        <Grid container justifyContent="center"
            sx={{ position: "relative" }}
        // - ${theme.spacing(isMobile?9:16)}
        >
            {/* <BaseMenu /> */}
            <Steppers />
            <CheckoutContext.Provider value={checkoutHook}>
                <Grid xs={12} item mt={3}>
                    <Routes>
                        {checkoutSteppers.map(step => <Route path={step.route} element={step.component} />)}
                    </Routes>
                </Grid>
            </CheckoutContext.Provider>
        </Grid>
    ) : <NotFound title="Cart is empty!" subTitle='Please add products to cart before make the payment!' />
}
