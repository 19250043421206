import { Grid, Step, StepButton, Stepper, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import useCheckout, { checkoutSteppers } from '../../useCheckout';
import { CartContext } from '../useCart';

export default function Steppers() {
    const checkoutHook = useCheckout()
    const { selectedVariants } = useContext(CartContext)
    const theme = useTheme()
    const history = useNavigate()
    return (
        <Grid container justifyContent="center" md={8} sm={12} xs={12} bgcolor={theme.palette.common.white} p={{ xs: 1, sm: 2 }}>
            <Grid item sm={8} xs={11}>
                <Stepper activeStep={checkoutHook.activatedStep} sx={{ height: 24 }}>
                    {checkoutSteppers.map((step, index) => {
                        const stepProps: { completed?: boolean } = {};
                        return (
                            <Step key={step.route} {...stepProps} >
                                <StepButton color="inherit" onClick={e => { history(`/checkout/${step.route}`) }} disabled={selectedVariants.length == 0 && step.index == 2}>
                                    <Typography fontSize={{ xs: 10, sm: 14 }} fontWeight={700} color="primary">{step.title}</Typography>
                                </StepButton>
                            </Step>
                        )
                    })}
                </Stepper>
            </Grid>
        </Grid>
    )
}
