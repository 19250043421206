import React from "react"

export const appLocale = {
    page: {
        login: {
            title: 'Sign in',
            labelAccount: 'Email',
            labelPassword: 'Password',
            contentButton: 'Sign in',
            labelOtherLogin: 'OR',
            loginGoogle: `Sign in with Google`
        },
        home: {
            showMore: 'Show more',
            intro: {
                item1: "Great value items",
                item2: "Worry-free shopping",
                item3: "Safe payment",
                item4: "Worldwide delivery"
            },
            brands: `Brands`
        },
        shop: {
            shopInfo: {
                titleProduct: `Product`,
                titleStatus: `Process`,
                titleTime: `Join`,
                status: `Fast`
            },
            notFound: {
                title: 'No products available',
                subTitle: 'Please search another keyword or go back to Home to buy another products'
            }
        },
        details: {
            productDetails: `Product Details`,
            seeMore: `See All`,
            similarProducts: `SIMILAR PRODUCTS`
        },
        search: {
            resultWithoutText: (count: number) => `${count} results`,
            resultWihText: (search: string, count: number) => `${count} results with keyword "${search}"`
        },
        checkout: {
            cart: {
                cartItems: {
                    product: `Product`,
                    price: `Price`,
                    quantity: `Quantity`,
                    shopContentButton: `View shop`
                },
                cartSummary: {
                    title: `Your order`,
                    price: `Price`,
                    shippingFee: `Delivery`,
                    totalPrice: `Order total`,
                    quantity: `Selected items`,
                    contentButton: `CONTINUE TO CHECKOUT`
                }
            },
            delivery: {
                customerInfo: {
                    title: `Delivery Address`,
                    firstName: `Fisrt name`,
                    lastName: `Last name`,
                    mobile: `Mobile`,
                    email: `Email`,
                    line1: `Line 1`,
                    line2: `Line 2`,
                    city: `City`,
                    postcode: `Postcode`,
                    country: `Country`
                },
                payment: {
                    title: `Payment`,
                    subTitle: `Select payment method:`
                },
                checkoutSummary: {
                    title: `Your order`,
                    price: `Price`,
                    shippingFee: `Delivery`,
                    totalPrice: `Total`,
                    contentButton: `CHECKOUT`
                }
            },
            completed: {
                listOrders: `Orders List`,
                backToHome: `Back to home`
            }
        },
        user: {
            orderHistory: {
                title: `Orders History`,
                total: `Total`,
                shopContentButton: `View shop`,
                hasFee: `This shipping fee has not included`
            }
        },
        about: {
            mainBanner: {
                title1: `Who`,
                title2: `we are?`,
                content: `Let's work together and
                    make awesome somethings easily`
            },
            introduce: {
                title: `Explore`,
                subTitle: `BeeMall`,
                content1: `Is an e-commerce ecosystem that aggregates all the world's leading major brands in one.`,
                content2: `Launched in 2021, BeeMall is built to provide users with a simple and easy online shopping experience. In addition, BeeMall is safe and fast when shopping online through a support system, payment and strong operation.`,
                button: `Explore now`
            },
            coreValues: {
                title: `Our Core Values`,
                item1: {
                    title: `Customer Satisfaction`,
                    content: `Exceeding customer expectations, delivering superiority and more.`,
                },
                item2: {
                    title: `Transparency`,
                    content: `Do not take shortcuts, even when no one is watching`,
                },
                item3: {
                    title: `Reputation`,
                    content: `Be trustworthy, do what we say we will.`,
                },
                item4: {
                    title: `Cooperation`,
                    content: `Always have a spirit of urgency to complete the work.`,
                },
            },
            founders: {
                title: ` Great Team Is The Key`,
                content: `BeeMall will provide you support if you have any problems, our support team will reply within a day and we also have detailed documentation.`,
                item1: {
                    name: `Hoang Nguyen`,
                    career: `CEO BeeMall`
                },
                item2: {
                    name: `Hieu Ho`,
                    career: `CTO BeeMall`
                },
                item3: {
                    name: `Hai Nguyen`,
                    career: `CMO BeeMall`
                }
            },
            views: {
                title: `Our Vision`,
                content: `We believe in the evolving power of technology and wish to contribute to making the world a better place by connecting the community of buyers and sellers.`,
                item1: `Global Shopping`,
                item2: `Shop with confidence`,
                item3: `Secure payment`,
                item4: `Help center`,
                button: `Shop now`
            },
            footer: {
                social: `FOLLOW US!`,
                menu1: {
                    title: `BEEMALL`,
                    item1: `Home`,
                    item2: `Event`,
                    item3: `Technology`
                },
                menu2: {
                    title: `SEE MORE`,
                    item1: `BeeToken`,
                    item2: `BeeGiftcard`
                }
            }
        },
        privacy: {
            title: `BeeMall Privacy`,
            item1: {
                title: `What information do we collect?`,
                content: `We collect information from you when you register on our site and gather data when you participate in the forum by reading, writing, and evaluating the content shared here.
                When registering on our site, you may be asked to enter your name and e-mail address. You may, however, visit our site without registering. Your e-mail address will be verified by an email containing a unique link. If that link is visited, we know that you control the e-mail address.
                When registered and posting, we record the IP address that the post originated from. We also may retain server logs which include the IP address of every request to our server.`
            },
            item2: {
                title: `What do we use your information for?`,
                content: `Any of the information we collect from you may be used in one of the following ways: To personalize your experience — your information helps us to better respond to your individual needs.
                To improve our site — we continually strive to improve our site offerings based on the information and feedback we receive from you. To improve customer service — your information helps us to more effectively respond to your customer service requests and support needs. To send periodic emails — The email address you provide may be used to send you information, notifications that you request about changes to topics or in response to your user name, respond to inquiries, and/or other requests or questions.`
            },
            item3: {
                title: `How do we protect your information?`,
                content: `We implement a variety of security measures to maintain the safety of your personal information when you enter, submit, or access your personal information.`
            },
            item4: {
                title: `Do we use cookies?`,
                content: `Yes. Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser (if you allow). These cookies enable the site to recognize your browser and, if you have a registered account, associate it with your registered account.
                We use cookies to understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future. We may contract with third-party service providers to assist us in better understanding our site visitors. These service providers are not permitted to use the information collected on our behalf except to help us conduct and improve our business.`
            },
            item5: {
                title: `Do we disclose any information to outside parties?`,
                content: `We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our site, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.`
            }
        },
    },
    component: {
        appMenu: {
            searchProducts: {
                searchLabel: 'Search product by name...',
                searchLabelShop: (name: string) => `Search product in shop ${name}...`
            },
            menuTitle: {
                orders: 'My orders',
                logout: 'Log out',
                home: 'Home',
                support: 'About',
                policy: 'Policy',
                contact: 'Contact',
                wishlist: `Wishlist`
            },
        },
        productItem: {
            sold: "sold"
        },
        footer: {
            title: `Beehive Group`,
            aboutUs: `About us`,
            legal: `Legal`,
            career: `Career`,
            privacyNotice: `Privacy Notice`,
            copyRight: `Product of BeeGiftcard Project`
        }
    }
}

type languages = typeof appLocale.component.appMenu
