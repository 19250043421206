import { Box, Button, Grid, Stack, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { appLocale } from 'src/constant/Locale';
const useStyles = makeStyles((theme: Theme) => ({
    box: {
        position: 'relative',
        paddingBottom: 5,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '50%',
            bottom: 0,
            left: '25%',
            borderTop: `5px solid ${theme.palette.primary.darker}`,
            borderRadius: 50
        },
    },
    image: {
        borderRadius: theme.spacing(2.5),
    },
    title: {
        fontSize: 32,
    },
}));
export default function Introduce() {
    const classes = useStyles();
    const history = useNavigate();
    const locale = appLocale.page.about.introduce;
    const theme = useTheme();
    return (
        <Grid item md={8} sm={11} xs={11} mt={{ sm: 10, xs: 5 }}>
            <Grid container xs={12}>
                <Grid item md={6} display={{ md: 'block', sm: 'none', xs: 'none' }}>
                    <Grid container xs={12} pr={7} spacing={3} alignItems="flex-end">
                        <Grid item xs={6}>
                            <img
                                className={classes.image}
                                src="https://minimal-assets-api.vercel.app/assets/images/about/what-1.jpg"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <img
                                className={classes.image}
                                src="https://minimal-assets-api.vercel.app/assets/images/about/what-2.jpg"
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <Stack alignItems="flex-start" spacing={4}>
                        <Box className={classes.box}>
                            <Typography className={classes.title} variant="subtitle1" color="#183b56">
                                {`${locale.title} `}
                                <Typography
                                    className={classes.title}
                                    variant="subtitle1"
                                    display="inline-block"
                                    color={theme.palette.primary.darker}
                                >
                                    {`${locale.subTitle}`}
                                </Typography>
                            </Typography>
                        </Box>
                        <Typography paragraph width="100%" fontSize={18}>
                            {locale.content1}
                        </Typography>
                        <Typography paragraph width="100%" fontSize={18}>
                            {locale.content2}
                        </Typography>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={(e) => history('/home')}
                            endIcon={<HiArrowNarrowRight />}
                            sx={{ borderRadius: 10 }}
                        >
                            {locale.button}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </Grid>
    );
}
