import { Box, Grid, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { IoListSharp } from 'react-icons/io5';
import { brands } from 'src/component/search-products/brand';
import { appLocale } from 'src/constant/Locale';
import BrandItem from './BrandItem';
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        padding: 10,
        [theme.breakpoints.up('lg')]: {
            height: '40vh',
        },
        [theme.breakpoints.down('lg')]: {
            height: '7vh',
        },
        [theme.breakpoints.down('md')]: {
            height: '7vh',

        },
        [theme.breakpoints.down('sm')]: {
            height: '11vh',

        },
        borderRadius: 5
    },
}));
export default function ListBrands() {
    const brand = appLocale.page.home.brands;

    const matches = useMediaQuery('(width:1024px)');
    const classes = useStyles();
    return (
        <Box className={classes.root} >
            <Stack
                direction="row"
                alignItems="center"
                gap={1}
                display={{ md: matches ? 'none' : 'flex', sm: 'none', xs: 'none' }}
                pl={1.2}
                mt={1}
            >
                <IoListSharp fontSize={18} />
                <Typography variant="subtitle1">{brand}</Typography>
            </Stack>
            <Grid container direction={{ md: matches ? 'row' : 'column', sm: 'row', xs: 'row' }} justifyContent="space-between">
                {brands.slice(1, brands.length).map((item) => (
                    <Grid key={item.id} item md={matches ? 2 : 1} sm={2} xs={4} p={0.4} pt={{ md: matches ? 0.5 : 1.2, sm: 0.5, xs: 0.5 }} pl={{ md: matches ? 0 : 1.2, sm: 1, xs: 1.8 }}>
                        <BrandItem brand={item} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}
