import { MobileContext } from '@BaseWeb/hook/useMobile';
import { Button, Checkbox, FormControlLabel, Grid, Stack, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { BiStoreAlt } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import { useBaseStyle } from 'src/component/styled-components/baseStyle';
import { appLocale } from 'src/constant/Locale';
import VariantItems from '../cart/component/VariantItems';
import CheckoutSummary from '../delivery-confirm/component/CheckoutSummary';
import { CheckoutContext } from '../useCheckout';
import StickyFooter from './component/StickyFooter';
import { CartContext } from './useCart';

export default function Cart() {
    const { shops, groupByShop, isShopSelected, selectShop, selectedVariants } = useContext(CartContext);
    const { isMobile } = useContext(MobileContext);
    const { doChangeStep } = useContext(CheckoutContext);
    const { shopContentButton } = appLocale.page.checkout.cart.cartItems;
    const baseClass = useBaseStyle();
    const theme = useTheme();
    const history = useNavigate();
    return (
        <Grid
            container
            justifyContent="center"
            sx={{ position: 'relative', maxHeight: `calc(100vh - ${theme.spacing(isMobile ? 19 : 26)})` }}
        >
            <Grid item md={8} sm={12} xs={12}>
                {shops
                    .map((shop) => ({ shop, variants: groupByShop[shop.id || ''] }))
                    .map((s) => {
                        return (
                            <Grid container className={baseClass.wrapper} sx={{ mb: 3, p: 2 }}>
                                <Grid container justifyContent="space-between" alignItems="center" mb={1}>
                                    <Stack direction="row" alignItems="center">
                                        <FormControlLabel
                                            label=""
                                            control={
                                                <Checkbox
                                                    checked={isShopSelected(s.shop.id || '')}
                                                    onChange={(e, checked) => selectShop(s.shop.id || '')}
                                                />
                                            }
                                            sx={{ m: 0 }}
                                        />
                                        <BiStoreAlt
                                            style={{ fontSize: theme.spacing(2.5), fill: theme.palette.grey[800] }}
                                        />
                                        <Typography pl={0.5} pr={1} variant="subtitle2">
                                            {s.shop.name}
                                        </Typography>
                                    </Stack>
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{ mr: 1, p: `${theme.spacing(0.5)} ${theme.spacing(1.5)}` }}
                                        onClick={(e) => history(`/shop/${s.shop.id}`)}
                                    >
                                        {shopContentButton}
                                    </Button>
                                </Grid>
                                <div style={{ width: '100%', borderTop: '1px solid #ddd' }} />
                                <Grid item xs={12} mt={2}>
                                    {s.variants.map((v) => {
                                        return (
                                            <VariantItems v={v} />
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        );
                    })}

                {selectedVariants.length > 0 && <CheckoutSummary />}
                <StickyFooter handleCheckout={() => doChangeStep('delivery')} />
            </Grid>
        </Grid>
    );
}
