import { Button, Grid, Typography, useTheme } from '@mui/material'
import React from 'react'
import { BiRightArrowAlt } from 'react-icons/bi'
import DealItem from './component/DealItem'
import { topDeals } from './data'

export default function TopDeal() {
    const theme = useTheme()
    return (
        <Grid container justifyContent="center" mt={{ sm: 5, xs: 15 }} >
            <Grid item md={8} sm={8} xs={12}>
                <Typography variant="h6" mb={2} ml={{ sm: 1, xs: 4 }}>Top Deals</Typography>
                <Grid container justifyContent="center" spacing={10} mb={2}>
                    {topDeals.map(deal =>
                        <Grid item>
                            <DealItem d={deal} />
                        </Grid>
                    )}
                </Grid>
                <Button sx={{ mb: 10, ml: { sm: 0, xs: 3 } }} color="secondary" endIcon={<BiRightArrowAlt />}>All deals & coupons</Button>
            </Grid>
        </Grid>
    )
}

