import { AxiosInstance } from 'axios';
import { BaseHttpController } from '@CoreCommerce/controllers/BaseHttpController';
import { Account, AccountWithRoles } from '@CoreAccount/model/Account';
import { IAccountController } from '@CoreAccount/controller/IAccountController';
import { ACCOUNT_SERVICE_NAME } from '@CoreAccount/index';
import { IUserController } from '../IUserController';
import { User, UserWithRole } from '@CoreCommerce/models/User';

export class UserController extends BaseHttpController<User> implements IUserController {
    constructor(serviceUrl: string, axiosClient: AxiosInstance) {
        super(serviceUrl, 'user', axiosClient);
    }
    getMe(): Promise<UserWithRole> {
        return this.doGet({ path: 'me' }).then((resp) => resp.data);
    }

    loginWithGoogle(params: { token: string }): Promise<AccountWithRoles> {
        return this.doPost({ path: 'login-with-google', body: params }).then((resp) => resp.data);
    }
    async login(username: string, password: string): Promise<AccountWithRoles> {
        return this.doPost({ path: 'login', config: { params: { username, password } } }).then((resp) => resp.data);
    }
    async register(username: string, password: string): Promise<AccountWithRoles> {
        return this.doPost({ path: 'register', config: { params: { username, password } } }).then((resp) => resp.data);
    }
}
