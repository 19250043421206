
export interface Deal {
    image: string
    title: string
    brand: string
}

export const topDeals: Deal[] = [{
    image: 'https://www.klarna.com/assets/sites/3/2021/06/30074053/bose40-516x500.jpg.webp',
    title: "Listen in style",
    brand: "Bose"
}, {
    image: 'https://www.klarna.com/assets/sites/3/2021/11/30100254/50calvinklein50-516x500.jpg.webp',
    title: "Quality for you",
    brand: "Calvin Klein"
},
{
    image: 'https://www.klarna.com/assets/sites/3/2020/04/02061450/50MK_-516x500.jpg.webp',
    title: "Designers fashion for you",
    brand: "Michael Kors"
},
{
    image: 'https://www.klarna.com/assets/sites/3/2021/10/07075500/60j_-516x500.jpg.webp',
    title: "Sparkling Savings",
    brand: "Jewlr"
}]
