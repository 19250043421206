import { NotificationContext } from '@BaseWeb/hook/useNotification';
import { Media } from '@CoreCommerce/models/Media';
import { Product } from '@CoreCommerce/models/Product';
import { ReportDataAnalyticsProduct } from '@CoreCommerce/models/ReportAnalytics';
import { Variant } from '@CoreCommerce/models/Variant';
import _, { uniq } from 'lodash';
import { createContext, useContext, useEffect, useState } from 'react';
import { ControllerContext } from 'src/hook/useController';
import { WishListContext } from '../wishlist/useWishList';

export function useProductDetail({ productId }: Props) {
    const [state, setState] = useState<State>({ product: {} as Product, filters: {}, quantity: 1 });
    const [report, setReport] = useState<ReportDataAnalyticsProduct>()
    const { productController, wishListController } = useContext(ControllerContext)
    const { name, desc, media } = state?.product || {} as Product;
    const variants = state?.product?.variants || [];
    const medias = media?.concat((variants || []).map(v => v.media).filter(m => !!m && !!m.url) as Media[])
    const variantAttrs = variants?.map(v => v.attributes);
    const attrs = Object.keys((variantAttrs || []).reduce((allAttrs, attrs) => allAttrs = { ...allAttrs, ...attrs }, {})).sort();
    const { showSuccess } = useContext(NotificationContext);
    const { onInit } = useContext(WishListContext)
    const getAttrValue = (variant: Variant[], attr: string) => uniq(variant?.map(v => v.attributes[attr]))
    const isSelectedAttr = (key: string, value: string) => state.filters[key] == value
    const filterVariants = (variant: Variant[], filters: any) => variant?.filter(v => Object.keys(filters)
        .map(key => (v.attributes[key] == filters[key]))
        .reduce((isMatch, isMatchFiled) => isMatch = isMatch && isMatchFiled, true));
    const getVariantByAttr = (props: Object): Variant | undefined => {
        return variants.find(v => _.isEqual(v.attributes, props))
    }

    const selectedVariant = filterVariants(variants, state.filters);

    const noSelectedItem = Boolean(Object.keys(state.filters).length < attrs.length)

    const toggleWishList = () => {
        wishListController.toggleWishList(productId).then(product => {
            onInit()
            setState(prev => ({ ...prev, product }))
            if (!state.product.wishList) {
                showSuccess('Add to wishlist successfully!')

            } else {
                showSuccess('Remove from wishlist successfully!')
            }
        })
    }
    useEffect(() => {
        productController.get(productId).then(product => setState(prev => ({ ...prev, product })));
        productController.getInfoAnalytics(productId).then(productData => setReport(productData));
    }, [productId])

    return {
        state, name, desc, medias, report, selectedVariant, noSelectedItem, variants, attrs, productId,
        filterVariants, getAttrValue, isSelectedAttr, setState, getVariantByAttr, toggleWishList
    }
}

export const ProductDetailContext = createContext<ReturnType<typeof useProductDetail>>({} as any)

interface State {
    product: Product
    filters: any,
    quantity: number
}

interface Props {
    productId: string
}

