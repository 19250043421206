import { Box, Stack, styled, Typography, useTheme } from '@mui/material'
import React from 'react'
import { Brand } from '../data'
const DealImage = styled('img')({
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    cursor: "pointer"
})
export default function BrandCategory({ bc }: Props) {
    const theme = useTheme()
    return (
        <Box width={theme.spacing(32)}>
            <Stack gap={2}>
                <DealImage src={bc.image} />
                <Typography variant="caption" fontSize={16}>{bc.name}</Typography>
            </Stack>
        </Box>
    )
}
interface Props {
    bc: Brand
}