import { addressValidationSchema, DeliveryAddress } from '@CoreCommerce/models/Address'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Grid } from '@mui/material'
import { useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { appLocale } from 'src/constant/Locale'
import { UserContext } from 'src/hook/useUser'
import { CartContext } from "src/page/checkout/cart/useCart"
import CheckoutSummary from 'src/page/checkout/delivery-confirm/component/CheckoutSummary'
import CustomerInfoForm from './component/CustomerInfoForm'
import { PaymentMethodComponent } from './component/payment-method'
import { PaymentMethodContext, usePaymentMethod } from './component/payment-method/usePaymentMethod'

export function DeliveryConfirm() {
    const { proceedOrder } = useContext(CartContext)
    const formHook = useForm<DeliveryAddress>({ reValidateMode: 'onChange', mode: 'onChange', resolver: yupResolver(addressValidationSchema) })
    const { formState, handleSubmit, reset } = formHook;
    const { upsert, user } = useContext(UserContext)
    const defaultValue = { ...user, ...(user?.addresses?.[0] || {}) } as DeliveryAddress;
    const paymentMethodHook = usePaymentMethod()
    const { paymentMethod } = paymentMethodHook;
    const history = useNavigate();
    const { contentButton } = appLocale.page.checkout.delivery.checkoutSummary


    useEffect(() => { reset(defaultValue) }, [user])


    const onSubmit = (address: DeliveryAddress) => {
        upsert({
            ...user,
            firstName: address.firstName || "",
            lastName: address.lastName || "",
            phoneNumber: address.phoneNumber,
            email: address.email || "",
            addresses: [address]
        }).then(() => proceedOrder({ address, paymentMethod }).then(orders => {
            history(`/checkout/completed/${orders.map(o => o.id).join(',')}`)
        }))
    }

    const onError = (error) => console.log(error);

    return (
        <Grid container justifyContent="center">
            <Grid item md={8} sm={12} xs={12}>
                <FormProvider {...formHook}>
                    <PaymentMethodContext.Provider value={paymentMethodHook}>
                        <Grid container justifyContent="center">
                            <Grid container spacing={3}>
                                <Grid item sm={8} xs={12}>
                                    <CustomerInfoForm />
                                    {formState.isValid && <PaymentMethodComponent />}
                                </Grid>
                                <Grid item sm={4} xs={12}>
                                    <CheckoutSummary />
                                    <Button size="large" style={{ marginTop: 15 }} fullWidth
                                        disabled={!formState.isValid || !paymentMethodHook.isValid}
                                        variant="contained"
                                        onClick={() => {
                                            handleSubmit(onSubmit, onError)();
                                        }}>{contentButton}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </PaymentMethodContext.Provider>
                </FormProvider>
            </Grid>
        </Grid>
    )
}

