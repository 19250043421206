import { Button, Divider, Grid, Stack, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import React, { useContext } from 'react'
import { appLocale } from 'src/constant/Locale'
import { CartContext } from '../useCart'
const useStyles = makeStyles((theme: Theme) => ({
    container: {
        background: "#fff",
        marginTop: theme.spacing(3),
        zIndex: 2,
        position: "sticky",
        bottom: "0",
        "&::before": {
            background: "linear-gradient(transparent,rgba(0,0,0,.026))",
            content: '""',
            position: "absolute",
            top: "-1.25rem",
            left: 0,
            height: "1.25rem",
            width: "100%",
        }
    },
    row: {
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            padding: "10px 10px"
        }
    },
    priceWrapper: {
        minWidth: 200,
        maxWidth: 500
    }
}))

export default function StickyFooter(props: Props) {
    const classes = useStyles()
    const { totalSelectedOrder, selectedVariants } = useContext(CartContext)
    const { totalPrice, contentButton, quantity } = appLocale.page.checkout.cart.cartSummary

    const renderPrice = (title: string, price: number) => {
        return (
            <Grid className={classes.priceWrapper} container justifyContent='space-between' alignItems='center' >
                <Typography variant="body2">{title}: </Typography>
                <Typography variant="subtitle1" color="primary">£{price}</Typography>
            </Grid >
        )
    }
    return (
        <Stack className={classes.container} divider={<Divider orientation="horizontal" style={{ border: "1px dashed rgba(0,0,0,.09)" }} />}>
            {/* <Grid container className={classes.row} alignItems="flex-end" direction="column">
                {renderPrice(price, totalSelectedOrder)}
                {renderPrice(shippingFee, 40)}
            </Grid> */}
            <Grid className={classes.row} container justifyContent="space-between" alignItems="center">
                <Grid xs={6}>
                    <Typography variant="body2">{`${quantity}: ${selectedVariants.length}`}</Typography>
                    {/* <Typography variant="subtitle2" component='span'>{totalPrice}:</Typography>
                    <Typography variant="h6" color="primary" component='span' ml={0.5} >£{_.round(totalSelectedOrder, 2)}</Typography> */}
                </Grid>
                <Grid sm={4} xs={6}>
                    <Button
                        disabled={selectedVariants.length == 0}
                        variant="contained"
                        fullWidth
                        size="large"
                        sx={{ minWidth: 150 }}
                        onClick={props.handleCheckout}>{contentButton}</Button>
                </Grid >
            </Grid>
        </Stack>
    )
}

type Props = {
    handleCheckout: VoidFunction,
}