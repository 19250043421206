import { Product } from '@CoreCommerce/models/Product';
import React, { useContext, useEffect, useState } from 'react';
import { ControllerContext } from 'src/hook/useController';
import { UserContext } from 'src/hook/useUser';

export default function useWishList() {
    const [state, setState] = useState<Product[]>([])
    const [loading, setLoading] = useState(true)
    const { wishListController } = useContext(ControllerContext)
    useEffect(() => {
        onInit()
    }, [])
    const onInit = () => {
        wishListController.myList().then((product) => {
            setState(product)
            if (state.length === 0) {
                setLoading(false)
            }
        })
    }
    return {
        state, setState, loading, setLoading, onInit
    }
}

export const WishListContext = React.createContext<ReturnType<typeof useWishList>>({} as any);
