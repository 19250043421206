import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useAfiSwipeStyles = makeStyles((theme: Theme) => ({
    root: {
        "& .swiper-thumbs": {
            marginTop: "1rem",
        },
        '& .swiper-slide': {
            [theme.breakpoints.up('md')]:{
                borderRadius: "5px"
            }
        },
        '& img': {
            objectFit:"cover",
            height: "inherit",
            width: "inherit",
            [theme.breakpoints.up('md')]:{
                borderRadius: "5px"
            },
            maxHeight:"50vh"
        },
        "& .swiper-slide-thumb-active": {
            border: `2px solid ${theme.palette.primary.main}`,
            padding: "3px"
        },
        '& .swiper-pagination-bullet-active': {
            background: theme.palette.primary.main,
        }
    }
}))