import { AuthenContext } from '@BaseWeb/hook/useAuthen';
import { useContext, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import About from './page/about';
import CheckoutPage from './page/checkout';
import { CheckoutCompletePage } from './page/checkout/complete';
import { CoinBaseConfirmed } from './page/checkout/payment/confirm/CoinbseConfirmed';
import HomePage from './page/home/HomePage';
import { OrderHistoryRoute } from './page/info/order-history';
import LandingPage from './page/landing-page';
import Login2 from './page/login/Login2';
import Policy from './page/policy';
import { ProductDetailPageRoute } from './page/product-detail';
import RegisterPage from './page/register';
import { SearchPage } from './page/search/SearchPage';
import { ShopPageRoute } from './page/shop/ShopPage';
import WishList from './page/wishlist';
import { AuthRoute } from './submodule/base-web/router/AuthRoute';
import { PublicRoute } from './submodule/base-web/router/PublicRoute';
import { AppContext } from './wrapper/AppContext';

function App() {
    useEffect(() => { }, []);
    return (
        <BrowserRouter>
            <AppContext>
                <AppRoute />
            </AppContext>
        </BrowserRouter>
    );
}

export function AppRoute() {
    const { isAuthen } = useContext(AuthenContext);

    return (
        <Routes>
            <Route path="/home-page" element={<LandingPage />} />
            <Route path="/" element={<AuthRoute loginUrl="/login" />}>
                <Route path="/landing" element={<LandingPage />} />
                <Route path="/home" element={<HomePage />} />
                <Route path="/search" element={<SearchPage />} />
                <Route path="/shop/:shopId" element={<ShopPageRoute />} />
                <Route path="/product/:shopId/:productId" element={<ProductDetailPageRoute />} />
                <Route path="/checkout/completed/:orderId" element={<CheckoutCompletePage />} />
                <Route path="/checkout/payment-confirm/:orderId/coinbase" element={<CoinBaseConfirmed />} />
                <Route path="/user/orders" element={<OrderHistoryRoute />} />
                <Route path="/wishlist" element={<WishList />} />
                <Route path="/*" element={<Navigate to="/home" />} />
                <Route path="/" element={<Navigate to="/home" />} />
            </Route>

            <Route path="/" element={<PublicRoute homeUrl="/home" />}>
                <Route path="/login" element={<Login2 />} />
                <Route path="/register" element={<RegisterPage />} />
            </Route>

            <Route
                path="/"
                element={
                    <AuthRoute
                        loginUrl="/login"
                        fullWidth
                        renderFooter={() => {
                            return <></>;
                        }}
                    />
                }
            >
                <Route path="/checkout/*" element={<CheckoutPage />} />
                <Route path="/about" element={<About />} />
                <Route path="/policy" element={<Policy />} />
            </Route>

            <Route
                path="/"
                element={
                    <AuthRoute
                        loginUrl="/login"
                        fullWidth
                        renderFooter={() => {
                            return <></>;
                        }}
                    />
                }
            >
                <Route path="/checkout/*" element={<CheckoutPage />} />
            </Route>

            <Route path="/home" element={<HomePage />} />
        </Routes>
    );
}

export default App;
