export interface Brand {
    image: string
    name: string
}

export interface CategoryBrand {
    name: string
    brands: Brand[]
}

export const categoryBrands: CategoryBrand[] = [{
    name: "Fashion & Apparel",
    brands: [
        {
            image: "https://www.klarna.com/assets/sites/3/2019/11/20100309/ASOS.jpg.webp",
            name: "ASOS",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2019/11/19091408/Shop-directory-image-for-H_M.jpg",
            name: "H&M",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2020/04/28154343/Adidas_Shop_D-1.jpg.webp",
            name: "Adidas",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2020/11/04153115/Anthropologie.jpg.webp",
            name: "Anthropologie",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2020/02/25170759/SD_Levis.jpg.webp",
            name: "ASOS",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2019/11/20100309/ASOS.jpg.webp",
            name: "Levi's",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2019/11/03102802/River-Island-1.jpg.webp",
            name: "PrettyLittleThing",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2019/11/03102802/River-Island-1.jpg.webp",
            name: "PrettyLittleThing",
        }
    ]
},
{
    name: "Beauty",
    brands: [
        {
            image: "https://www.klarna.com/assets/sites/3/2019/11/20100309/ASOS.jpg.webp",
            name: "ASOS",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2019/11/19091408/Shop-directory-image-for-H_M.jpg",
            name: "H&M",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2020/04/28154343/Adidas_Shop_D-1.jpg.webp",
            name: "Adidas",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2020/11/04153115/Anthropologie.jpg.webp",
            name: "Anthropologie",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2020/02/25170759/SD_Levis.jpg.webp",
            name: "ASOS",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2019/11/20100309/ASOS.jpg.webp",
            name: "Levi's",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2019/11/03102802/River-Island-1.jpg.webp",
            name: "PrettyLittleThing",
        },
        {
            image: "https://www.klarna.com/assets/sites/3/2019/11/03102802/River-Island-1.jpg.webp",
            name: "PrettyLittleThing",
        }
    ]
}]