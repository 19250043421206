import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Typography, TypographyProps } from '@mui/material';

type Props = {
    value: number;
    onUpdateText: (value) => string;
} & TypographyProps;
function TypographyNumberPump(props: Props) {
    const refTypo = useRef<HTMLElement>();
    let currentValue = useRef<number>(props.value);
    useEffect(() => {
        const TIME_DEFAULT = 1;
        const TIME_PRINT = 4 // 3 là thời gian trung bình in ra màn hình (ms)
        const typeUp = props.value > currentValue.current

        const calcCurrent = Math.abs(props.value - currentValue.current);
        let time = TIME_DEFAULT / calcCurrent;
        let step = typeUp ? 1 : -1;
        if (time < TIME_PRINT) {
            step = calcCurrent / (TIME_DEFAULT / TIME_PRINT);
            step = typeUp ? step : -step
            time = 1;
        }
        startDump(currentValue.current, time, Math.round(step));
        currentValue.current = props.value;
    }, [props.value, props.onUpdateText]);
    async function startDump(value: number, timeout: number, step: number) {
        if (refTypo.current) {
            const refP = refTypo.current;
            refP.innerHTML = props.onUpdateText(value);
            await new Promise((res) => setTimeout(res, timeout));
            const condition = step >= 0 ? value + step < props.value + step : value + step > props.value + step
            if (condition) {
                const conditionStep = step >= 0 ? value + step < props.value : value + step > props.value
                startDump(conditionStep ? value + step : props.value, timeout, step);
            }
        }
    }
    return <Typography ref={refTypo as any} {...props} />;
}

export default React.memo(TypographyNumberPump);
