import { Button, Divider, Drawer, Grid, IconButton, Stack, Theme, Typography, useMediaQuery, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/system";
import React, { useState } from 'react';
import { MdMenu } from "react-icons/md";
import { useNavigate } from "react-router";
import LogoAfi from "src/component/icons/LogoAfi";
const MyHeader = styled('header')({
    maxWidth: "100%",
    height: '80px',
    backgroundColor: "rgba(255, 255, 255, 0.72)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    boxShadow: "0px 0px 15px rgba(63, 63, 63, 0.1)",
    display: "flex",
    padding: "5px 50px",
    alignItems: "center",
    backdropFilter: "blur(6px)",
    zIndex: 2,
})

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        maxWidth: "100%",
        height: '80px',
        backgroundColor: "rgba(255, 255, 255, 0.72)",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        boxShadow: "0px 0px 15px rgba(63, 63, 63, 0.1)",
        backdropFilter: "blur(6px)",
        zIndex: 2
    },
    logo: {
        height: theme.spacing(8),
        marginRight: theme.spacing(5),
        fill: theme.palette.primary.main,
        cursor: "pointer"
    },
    menuItem: {
        cursor: "pointer",
        '&:hover': {
            color: theme.palette.primary.main,
            transition: "0.2s"
        }
    },
    listMenu: {
        width: 250,
        padding: 20,
        textAlign: 'center',
    },
    banner: {
        maxWidth: "100%",
        height: "60vh",
        marginTop: 78,
        background: "url(https://images7.alphacoders.com/102/1022157.jpg)",
        backgroundRepeat: "no-repeat",
        WebkitBackgroundSize: "cover",
        [theme.breakpoints.up(1400)]: {
            backgroundPositionY: "-300px",
        },
    },
    sloganWrapper: {
        width: "25%",
        height: "calc(100%)",
        minWidth: "300px",
        backgroundColor: "rgba(255, 255, 255, .15)",
        backdropFilter: "blur(10px)",
    },
    slogan: {
        color: "white",
        position: "relative",
        top: "40%",
        left: "50%"
    },

}))
export default function Header() {
    const classes = useStyles()
    const history = useNavigate()
    const theme = useTheme()

    const [open, setOpen] = useState(false);

    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
    return (
        <div>
            <Grid container justifyContent="space-between" alignItems="center" p={1} className={classes.header} sx={{ display: { sm: 'none' } }}>
                <Drawer
                    classes={{ paper: classes.listMenu }}
                    anchor="left"
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Stack gap={1} mt={2}>
                        <Typography variant="subtitle1" className={classes.menuItem}>Shop</Typography>
                        <Divider sx={{ borderWidth: 1 }} />
                        <Typography variant="subtitle1" className={classes.menuItem}>How it works</Typography>
                        <Divider sx={{ borderWidth: 1 }} />
                        <Typography variant="subtitle1" className={classes.menuItem}>Help</Typography>
                    </Stack>
                </Drawer>
                <Grid item >
                    <LogoAfi style={{ width: "auto", height: 56, marginBottom: 8, fill: theme.palette.primary.main, cursor: "pointer" }} />
                </Grid>
                <Stack direction="row" gap={1} alignItems="center">
                    <Button variant="outlined" sx={{borderRadius: 5, height: 35}} onClick={e => history('/login')}>Login</Button>
                    <IconButton onClick={() => setOpen(true)}>
                        <MdMenu />
                    </IconButton>
                </Stack>
            </Grid>
            {matches && <MyHeader >
                <Grid container justifyContent="flex-start" alignItems="center">
                    <LogoAfi className={classes.logo} />
                    <Stack direction="row" gap={2}>
                        <Typography variant="subtitle1" className={classes.menuItem}>Shop</Typography>
                        <Typography variant="subtitle1" className={classes.menuItem}>How it works</Typography>
                        <Typography variant="subtitle1" className={classes.menuItem}>Help</Typography>
                    </Stack>
                </Grid>
                <Stack direction="row" gap={2}>
                    <Button variant="outlined" onClick={e => history('/login')}>Login</Button>
                    <Button variant="contained" >Register</Button>
                </Stack>
            </MyHeader>}
            <Grid className={classes.banner} display={{ sm: 'block', xs: 'none' }}>
                <Grid className={classes.sloganWrapper}>
                    <Grid className={classes.slogan}>
                        <Typography variant="h2" sx={{whiteSpace:"nowrap"}}>Shop all you love!</Typography>
                        <Typography>No matter where you are. Just click! And we will bring it for you!</Typography>
                        <Button variant="contained" size="large" fullWidth sx={{ color: "white", marginTop: 5 }}>Join us to get the best deal today!</Button>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
