import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import Banner from './components/Banner';
import Banner2 from './components/Banner2';
import CoreValues from './components/CoreValues';
import Founders from './components/Founders';
import Introduce from './components/Introduce';
import Policy from './components/Footer';
import View from './components/View';
import Footer from './components/Footer';
const useStyles = makeStyles((theme: Theme) => ({
    base: {
        backgroundColor: '#fff',
        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
    },
}));
export default function About() {
    const classes = useStyles();
    return (
        <Grid container justifyContent="center" className={classes.base} >
            <Banner />
            <Introduce />
            <CoreValues />
            <Banner2 />
            <Founders />
            <View />
            <div style={{ width: '100%', borderTop: '1px solid #ddd' }} />
            <Footer />
        </Grid>
    );
}
