import { ButtonGroup, Button } from '@mui/material'
import React from 'react'

export function QuantityButton({quantity,onChange,disable}:Props) {
    return (
        <ButtonGroup variant="outlined" >
            <Button disabled={Boolean(Number(quantity) < 2||disable)}
                onClick={() => onChange(Number(quantity) - 1)}
            >-</Button>
            <Button sx={{ pointerEvents: "none" }} disabled={disable}>{quantity}</Button>
            <Button disabled={Boolean(Number(quantity) > 19||disable)}
                onClick={() => onChange(Number(quantity) + 1)}
            >+</Button>
        </ButtonGroup>
    )
}

interface Props{
    quantity?:number
    onChange(quantity:number):void
    disable?:boolean
}