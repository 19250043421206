import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        border: "none !important"
    },
    mask: {
        position: 'absolute',
        width: "100vw",
        height: "100vh",
        background: "#00000060",
    },
    selectBrand: {
        '& .MuiInputBase-input': {
            backgroundColor: "#F3F3F3",
            border: 0,
            borderRadius: 0,
            fontSize: "0.85rem",
            padding: '10px 26px 10px 12px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0
        }
    }
}))