import { Typography } from '@mui/material'
import React from 'react'

export default function ContentPrivacy(props: Props) {
    return (
        <>
            <Typography variant='h5' pb={2} color="#183b56">{props.title}</Typography>
            <Typography paragraph color="#606c82" fontSize="1rem">
                {props.content}
            </Typography>
        </>
    )
}
interface Props {
    title: string,
    content: React.ReactElement | string
}