import { Divider, Grid, Stack, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Facebook from 'src/component/icons/Facebook';
import LogoAfi from 'src/component/icons/LogoAfi';
import Instagram from 'src/component/icons/Instagram';
import Youtube from 'src/component/icons/Youtube';
import Twitter from 'src/component/icons/Twitter';
import { appLocale } from 'src/constant/Locale';
import { ImLocation2 } from 'react-icons/im'
import { IoPaperPlane } from 'react-icons/io5';
const useStyles = makeStyles((theme: Theme) => ({
    base: {
        backgroundColor: '#f9fafb',
        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
    },
    logo: {
        width: '150px',
        marginBottom: 20,
        fill: theme.palette.primary.main,
        cursor: 'pointer',
    },
    icon: {
        width: '38px',
        cursor: 'pointer',
        transition: 'transform 0.25s ease-out',
        '&:hover': {
            transform: 'translateY(-.15rem)',
        },
    },
    title: {
        color: '#797b7c',
    },
    list: {
        fontSize: 14,
        paddingLeft: 6,
        cursor: 'pointer',
        transition: 'transform 0.25s ease-out',
        '&:hover': {
            color: theme.palette.primary.main,
            transform: 'translateX(.25rem)',
        },
    },
}));
export default function Footer({ background }: Props) {
    const classes = useStyles();
    const history = useNavigate();
    const theme = useTheme()
    const locale = appLocale.page.about.footer;
    return (
        <Grid item xs={12} container justifyContent="center" alignItems="center" sx={{ backgroundColor: { background } }}>
            <Grid item md={8} sm={11} xs={11} pt={3} pb={5}>
                <Grid container justifyContent="space-between">
                    <Stack p={1}>
                        <LogoAfi className={classes.logo} onClick={(e) => history('/')} />
                        <Stack direction="row" alignItems="center" gap={1}>
                            <IoPaperPlane color={theme.palette.primary.darker} />
                            <Typography className={classes.title} fontSize={14}>contact@beemall.com</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap={1} mt={1}>
                            <ImLocation2 color={theme.palette.primary.darker} />
                            <Typography className={classes.title} fontSize={14}>197 Campbell Road, London, England, E3 4DP</Typography>
                        </Stack>
                        <Stack direction="row" gap={1} mt={2}>
                            <a href="https://www.facebook.com/BeeLuxuryShopping" target="_blank" style={{ lineHeight: 0 }}>
                                <Facebook className={classes.icon} />
                            </a>
                            <Instagram className={classes.icon} />
                            <Twitter className={classes.icon} />
                            <Youtube className={classes.icon} />
                        </Stack>

                    </Stack>
                    <Stack direction="row" gap={3} p={{ sm: 3, xs: 1 }}>
                        <Grid>
                            <Typography className={classes.title} pl={0.75} mb={1}>
                                {locale.menu1.title}
                            </Typography>
                            <Stack>
                                <Typography variant="caption" className={classes.list}>
                                    {locale.menu1.item1}
                                </Typography>
                                <Typography variant="caption" className={classes.list}>
                                    {locale.menu1.item2}
                                </Typography>
                                <Typography variant="caption" className={classes.list}>
                                    {locale.menu1.item3}
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid>
                            <Typography className={classes.title} pl={0.75} mb={1}>
                                {locale.menu2.title}
                            </Typography>
                            <Stack>
                                <Typography variant="caption" className={classes.list}>
                                    {locale.menu2.item1}
                                </Typography>
                                <Typography variant="caption" className={classes.list}>
                                    {locale.menu2.item2}
                                </Typography>
                            </Stack>
                        </Grid>
                    </Stack>
                </Grid>
            </Grid >
        </Grid >
    );
}
interface Props {
    background?: string
}