import { AuthenContext } from '@BaseWeb/hook/useAuthen'
import { Dialog, Slide, Theme } from '@mui/material'
import Grid from '@mui/material/Grid/Grid'
import { TransitionProps } from '@mui/material/transitions'
import { makeStyles } from '@mui/styles'
import React, { useContext } from 'react'
import LoginContent from 'src/component/login/LoginContent'




const useStyles = makeStyles((theme: Theme) =>
({
    background: {
        height: "100vh",
        // background: "url(https://www.pixelstalk.net/wp-content/uploads/2016/06/Nice-Fashion-Wallpaper.jpg)",
        backgroundImage: "url(/login.png)",
        backgroundSize: "cover",
        display: "flex",
        alignItems: "center",
        padding: 40,
        [theme.breakpoints.down(1200)]: {
            padding: 24
        },
    },
    dialogLogin: {

    }
})
)
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupLogin() {

    const authen = useContext(AuthenContext)
    const classes = useStyles()
    return (
        <Dialog
            open={authen.isShowPopup}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => { }}
            maxWidth="md"
        >
            <Grid p={5}>
                <LoginContent />
            </Grid>
        </Dialog>
    )
}
