import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Control, Controller, Path } from 'react-hook-form';
import { TextFieldProps } from '@mui/material';

export default function Countries<T>({ control, name }: Props<T>) {
    return (
        <Controller
            name={name as any}
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
                return (
                    <Autocomplete
                        id="country-select-demo"
                        fullWidth
                        options={countries}
                        onChange={(e, data) => onChange(data?.label)}
                        value={countries.find((ct) => ct.label == value) || null}
                        autoHighlight
                        noOptionsText="This country hasn't supported yet."
                        getOptionLabel={(option) => option.label}
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt=""
                                />
                                {option.label}
                            </Box>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Select your country"
                                error={!!error}
                                helperText={error?.message}
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                );
            }}
        />
    );
}

interface CountryType {
    code: string;
    label: string;
    phone: string;
}
interface Props<T> {
    control: Control<T, object>;
    name: Path<T>;
}
// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
const countries: readonly CountryType[] = [
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
];
