import { Grid, Stack, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
const useStyles = makeStyles((theme: Theme) => ({
    base: {
        width: 50,
        height: 50,
        borderRadius: '50%',
        position: 'relative',
        color: 'rgb(119, 5, 8)',
        cursor: "pointer",
        border: '2px dashed rgba(250, 84, 28, 0.24)',
        '&:before': {
            content: "''",
            position: 'absolute',
            borderRadius: '50%',
            width: 'calc(60%)',
            height: 'calc(60%)',
            background: `conic-gradient(from 
                0deg at 50% 50%, rgb(250, 84, 28) 
                0deg, rgb(253, 171, 118) 
                360deg)`,
            zIndex: 8,
        },
    },
}))
export default function IconMenu(props: Props) {
    const classes = useStyles()
    return (
        <Grid xs={6} container direction="column" justifyContent="center" alignItems="center" textAlign="center" mt={-10}>
            <Stack className={classes.base} alignItems="center" justifyContent="center">
                <div style={{ zIndex: 9, fontSize: 18, marginTop: 5 }} onClick={props.redirect}>
                    {props.icon}
                </div>
            </Stack>
            <Typography fontSize="0.75rem" mt={1}>{props.title}</Typography>
        </Grid>
    )
}
interface Props {
    icon: React.ReactElement,
    title: string,
    redirect(e: any): void
}