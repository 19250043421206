import { Grid, Typography } from '@mui/material'
import CategoryBrands from './section/brand-category'
import Footer from './section/footer'
import Header from './section/header'
import TopDeal from './section/top-deal'

export default function LandingPage() {
    return (
        <div>
            <Header />
            <TopDeal />
            <CategoryBrands />
            {/* <Footer /> */}
        </div>
    )
}
