import TypographyNumberPump from '@BaseWeb/component/common/TypographyNumberPump';
import { StatisticOrder } from '@CoreCommerce/controllers/IOrderController';
import { Divider, Grid, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect, useState } from 'react';
import { HideOnScroll } from 'src/app-menu/AppMenu';
import { appLocale } from 'src/constant/Locale';
import { ControllerContext } from 'src/hook/useController';

const useStyles = makeStyles((theme: Theme) => ({
    base: {
        backgroundColor: '#fff',
        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
        position: 'fixed',
        bottom: 0,
        left: 'auto',
        right: 0,
        zIndex: 999,
    },
}));
export default function Footer(props) {
    const classes = useStyles();
    const locale = appLocale.component.footer;
    const [order, setOrder] = useState<StatisticOrder>()
    const { orderController } = useContext(ControllerContext)

    useEffect(() => {
        orderController.statisticOrder().then(res => {
            setOrder(res)
        })
    }, [])
    const componentDataReport: Record<keyof (Omit<StatisticOrder, "waitingForPayment" | "addedToCart">), { value: number; title: string }> = {
        total: {
            title: 'Total Orders',
            value: order?.total || 0,
        },
        success: {
            title: 'Order Success',
            value: order?.success || 0,
        },
        totalPrice: {
            title: 'Total Values',
            value: order?.totalPrice || 0,
        },
    };

    return (
        <HideOnScroll {...props} direction='up'>
            <Grid container xs={12} justifyContent="center" className={classes.base}>
                <Grid item container md={8} sm={12} xs={12} pt={1} pb={0.5} justifyContent="center">
                    <Stack direction="row" alignItems="center" divider={<Divider orientation="vertical" flexItem />} width="100%" mb={1} gap={0.5}>
                        {[...Object.entries(componentDataReport)].map(([key, value], index) => {
                            return (
                                <Grid key={index} item sm={4} xs container justifyContent="center" >
                                    <Stack direction="column-reverse" alignItems="center" >
                                        <Typography variant='caption' fontWeight={550}>{value.title}</Typography>
                                        <TypographyNumberPump
                                            align="center"
                                            variant="h6"
                                            color="primary"
                                            value={value.value}
                                            onUpdateText={(valueNumber) => "totalPrice" == key ? `£${valueNumber.toLocaleString(undefined, { minimumFractionDigits: 2 })}` : `${valueNumber}+`}
                                        />
                                    </Stack>
                                </Grid>
                            );
                        })}
                    </Stack>
                    <div style={{ width: '100%', borderTop: '1px solid #ddd' }} />
                    <Typography variant="caption" mt={1}>
                        {locale.copyRight}
                    </Typography>
                </Grid>
            </Grid>
        </HideOnScroll>
    );
}
