import { Box, Grid, Stack, Theme, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
const useStyles = makeStyles((theme: Theme) => ({
    icon: {
        background: theme.palette.background.default,
        textAlign: 'center',
    }
}))
export default function IntroduceItem(props: Props) {
    const theme = useTheme()
    const classes = useStyles()
    return (
        <Grid item md={1} sm={1} xs={6} p={{ sm: 0, xs: 1 }}>
            <Stack alignItems="center" sx={{ flexBasis: "100px" }} gap={1}>
                <Box className={classes.icon} sx={{
                    width: { sm: 50, xs: 40 },
                    height: { sm: 50, xs: 40 },
                    borderRadius: { sm: 24, xs: 14 },
                    lineHeight: { sm: '4rem', xs: '3.5rem' },
                }}>
                    {props.icon}
                </Box>
                <Typography fontSize={13} noWrap>{props.title}</Typography>
            </Stack>
        </Grid>
    )
}
interface Props {
    title: string,
    icon: React.ReactElement;
}