import { useContext } from 'react';
import { Navigate, Outlet, PathRouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import { AuthenContext } from '../hook/useAuthen';

export function PublicRoute(props: Props) {

    const { isAuthen } = useContext(AuthenContext)
    return (isAuthen == null || !isAuthen ? <Outlet /> : <Navigate to={props.homeUrl || ""} />)
}


interface Props {
    homeUrl?: string
}