import React, { useContext, useEffect } from "react";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { NotificationContext } from "../hook/useNotification";

export function Snackbar() {
  const { enqueueSnackbar } = useSnackbar();
  const { notification } = useContext(NotificationContext)

  useEffect(() => {
    if (!notification) return;
    const { message, variant } = notification;
    if (message) {
      enqueueSnackbar(message, { variant });
    }
  }, [notification]);

  return <div></div>;
}

export function AppNotification() {
  return (
    <SnackbarProvider maxSnack={3}>
      <Snackbar />
    </SnackbarProvider>
  );
}
