import { alpha, Box, SvgIcon, SvgIconProps } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { merge } from 'lodash';
import { AiFillStar, AiOutlineCloseSquare, AiOutlineMinusSquare, AiOutlinePlusSquare } from "react-icons/ai";
import { FiAlertCircle, FiAlertTriangle } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { RiFolderInfoFill } from "react-icons/ri";
import { initType } from '../CustomTypeTheme';


// ----------------------------------------------------------------------

function Icon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M17 3a4 4 0 014 4v10a4 4 0 01-4 4H7a4 4 0 01-4-4V7a4 4 0 014-4h10zm0 2H7a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2z" />
        </SvgIcon>
    );
}

function CheckedIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M17 3a4 4 0 014 4v10a4 4 0 01-4 4H7a4 4 0 01-4-4V7a4 4 0 014-4h10zm-1.372 4.972a1.006 1.006 0 00-.928.388l-3.78 5-1.63-2.08a1.001 1.001 0 00-1.58 1.23l2.44 3.11a1 1 0 001.58-.01l4.57-6v-.03a1.006 1.006 0 00-.672-1.608z" />
        </SvgIcon>
    );
}

function IndeterminateIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <path d="M17 3a4 4 0 014 4v10a4 4 0 01-4 4H7a4 4 0 01-4-4V7a4 4 0 014-4h10zm-1.75 8h-6.5a.75.75 0 00-.75.75v.5c0 .414.336.75.75.75h6.5a.75.75 0 00.75-.75v-.5a.75.75 0 00-.75-.75z" />
        </SvgIcon>
    );
}
initType();
export default function ComponentsOverrides(theme: Theme) {
    const ICON_SIZE = { width: 20, height: 20 };
    const ICON_LARGE = { width: 28, height: 28 };
    const isLight = theme.palette.mode === 'light';
    const varLow = alpha(theme.palette.grey[900], 0.48);
    const varHigh = alpha(theme.palette.grey[900], 1);
    const styleContained = (color: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error') => ({
        props: { variant: 'contained', color },
        style: { boxShadow: theme.customShadows[color] },
    });
    const ICON = (
        <SvgIcon>
            <AiFillStar />
        </SvgIcon>
    );
    const standardStyle = (color: any) => ({
        color: theme.palette[color][isLight ? 'darker' : 'lighter'],
        backgroundColor: theme.palette[color][isLight ? 'lighter' : 'darker'],
        '& .MuiAlert-icon': {
            color: theme.palette[color][isLight ? 'main' : 'light']
        }
    });

    const filledStyle = (color: any) => ({
        color: theme.palette[color].contrastText
    });

    const outlinedStyle = (color: any) => ({
        color: theme.palette[color][isLight ? 'darker' : 'lighter'],
        border: `solid 1px ${theme.palette[color][isLight ? 'light' : 'dark']}`,
        backgroundColor: theme.palette[color][isLight ? 'lighter' : 'darker'],
        '& .MuiAlert-icon': {
            color: theme.palette[color][isLight ? 'main' : 'light']
        }
    });
    return merge(
        {
            MuiFab: {
                defaultProps: {
                    color: 'primary',
                },

                styleOverrides: {
                    root: {
                        boxShadow: theme.customShadows.z8,
                        '&:hover': {
                            boxShadow: 'none',
                            backgroundColor: theme.palette.grey[400],
                        },
                    },
                    primary: {
                        boxShadow: theme.customShadows.primary,
                        '&:hover': {
                            backgroundColor: theme.palette.primary.dark,
                        },
                    },
                    secondary: {
                        boxShadow: theme.customShadows.secondary,
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.dark,
                        },
                    },
                    extended: {
                        '& svg': {
                            marginRight: theme.spacing(1),
                        },
                    },
                },
            },
        },
        {
            MuiTab: {
                styleOverrides: {
                    root: {
                        padding: 0,
                        fontWeight: theme.typography.fontWeightMedium,
                        borderTopLeftRadius: theme.shape.borderRadius,
                        borderTopRightRadius: theme.shape.borderRadius,
                        '&.Mui-selected': {
                            color: theme.palette.text.primary,
                        },
                        '&:not(:last-of-type)': {
                            marginRight: theme.spacing(5),
                        },
                        '@media (min-width: 600px)': {
                            minWidth: 48,
                        },
                    },
                    labelIcon: {
                        minHeight: 48,
                        flexDirection: 'row',
                        '& > *:first-of-type': {
                            marginBottom: 0,
                            marginRight: theme.spacing(1),
                        },
                    },
                    wrapper: {
                        flexDirection: 'row',
                        whiteSpace: 'nowrap',
                    },
                    textColorInherit: {
                        opacity: 1,
                        color: theme.palette.text.secondary,
                    },
                },
            },
            MuiTabPanel: {
                styleOverrides: {
                    root: {
                        padding: 0,
                    },
                },
            },
            MuiTabScrollButton: {
                styleOverrides: {
                    root: {
                        width: 48,
                        borderRadius: '50%',
                    },
                },
            },
        },
        {
            MuiChip: {
                // defaultProps: {
                //     deleteIcon: <Icon icon={closeCircleFill} />,
                // },
                styleOverrides: {
                    colorDefault: {
                        '& .MuiChip-avatarMedium, .MuiChip-avatarSmall': {
                            color: theme.palette.text.secondary,
                        },
                    },
                    outlined: {
                        borderColor: theme.palette.grey[500_32],
                        '&.MuiChip-colorPrimary': {
                            borderColor: theme.palette.primary.main,
                        },
                        '&.MuiChip-colorSecondary': {
                            borderColor: theme.palette.secondary.main,
                        },
                    },
                    //
                    avatarColorInfo: {
                        color: theme.palette.info.contrastText,
                        backgroundColor: theme.palette.info.dark,
                    },
                    avatarColorSuccess: {
                        color: theme.palette.success.contrastText,
                        backgroundColor: theme.palette.success.dark,
                    },
                    avatarColorWarning: {
                        color: theme.palette.warning.contrastText,
                        backgroundColor: theme.palette.warning.dark,
                    },
                    avatarColorError: {
                        color: theme.palette.error.contrastText,
                        backgroundColor: theme.palette.error.dark,
                    },
                },
            },
        },
        {
            MuiCard: {
                styleOverrides: {
                    root: {
                        boxShadow: theme.customShadows.z16,
                        borderRadius: theme.shape.borderRadiusMd,
                        position: 'relative',
                        zIndex: 0, // Fix Safari overflow: hidden with border radius
                    },
                },
            },
            MuiCardHeader: {
                defaultProps: {
                    titleTypographyProps: { variant: 'h6' },
                    subheaderTypographyProps: { variant: 'body2', marginTop: theme.spacing(0.5) },
                },
                styleOverrides: {
                    root: {
                        padding: theme.spacing(3, 3, 0),
                    },
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        padding: theme.spacing(3),
                    },
                },
            },
        },
        {
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.action.selected,
                            '&:hover': {
                                backgroundColor: theme.palette.action.hover,
                            },
                        },
                    },
                },
            },
        },
        {
            MuiGrid: {
                styleOverrides: {},
            },
        },
        {
            MuiLink: {
                defaultProps: {
                    underline: 'hover',
                },

                styleOverrides: {
                    root: {},
                },
            },
        },
        {
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            '& svg': { color: theme.palette.text.disabled },
                        },
                    },
                    input: {
                        '&::placeholder': {
                            opacity: 1,
                            color: theme.palette.text.disabled,
                        },
                    },
                },
            },
            MuiInput: {
                styleOverrides: {
                    underline: {
                        '&:before': {
                            borderBottomColor: theme.palette.grey[500_56],
                        },
                    },
                },
            },
            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.grey[500_12],
                        '&:hover': {
                            backgroundColor: theme.palette.grey[500_16],
                        },
                        '&.Mui-focused': {
                            backgroundColor: theme.palette.action.focus,
                        },
                        '&.Mui-disabled': {
                            backgroundColor: theme.palette.action.disabledBackground,
                        },
                    },
                    underline: {
                        '&:before': {
                            borderBottomColor: theme.palette.grey[500_56],
                        },
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.grey[500_32],
                        },
                        '&.Mui-disabled': {
                            '& .MuiOutlinedInput-notchedOutline': {
                                borderColor: theme.palette.action.disabledBackground,
                            },
                        },
                    },
                },
            },
        },
        {
            MuiRadio: {
                styleOverrides: {
                    root: {
                        padding: theme.spacing(1),
                        svg: {
                            fontSize: 24,
                            '&[font-size=small]': {
                                fontSize: 20,
                            },
                        },
                    },
                },
            },
        },
        {
            MuiBadge: {
                styleOverrides: {
                    dot: {
                        width: 10,
                        height: 10,
                        borderRadius: '50%',
                    },
                },
            },
        },
        {
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        color: 'inherit',
                        minWidth: 'auto',
                        marginRight: theme.spacing(2),
                    },
                },
            },
            MuiListItemAvatar: {
                styleOverrides: {
                    root: {
                        minWidth: 'auto',
                        marginRight: theme.spacing(2),
                    },
                },
            },
            MuiListItemText: {
                styleOverrides: {
                    root: {
                        marginTop: 0,
                        marginBottom: 0,
                    },
                    multiline: {
                        marginTop: 0,
                        marginBottom: 0,
                    },
                },
            },
        },
        {
            MuiTableRow: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.action.selected,
                            '&:hover': {
                                backgroundColor: theme.palette.action.hover,
                            },
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        borderBottom: 'none',
                    },
                    head: {
                        color: theme.palette.text.secondary,
                        backgroundColor: theme.palette.background.neutral,
                        '&:first-of-type': {
                            paddingLeft: theme.spacing(3),
                            borderTopLeftRadius: theme.shape.borderRadius,
                            borderBottomLeftRadius: theme.shape.borderRadius,
                            boxShadow: `inset 8px 0 0 ${theme.palette.background.paper}`,
                        },
                        '&:last-of-type': {
                            paddingRight: theme.spacing(3),
                            borderTopRightRadius: theme.shape.borderRadius,
                            borderBottomRightRadius: theme.shape.borderRadius,
                            boxShadow: `inset -8px 0 0 ${theme.palette.background.paper}`,
                        },
                    },
                    stickyHeader: {
                        backgroundColor: theme.palette.background.paper,
                        backgroundImage: `linear-gradient(to bottom, ${theme.palette.background.neutral} 0%, ${theme.palette.background.neutral} 100%)`,
                    },
                    body: {
                        '&:first-of-type': {
                            paddingLeft: theme.spacing(3),
                        },
                        '&:last-of-type': {
                            paddingRight: theme.spacing(3),
                        },
                    },
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    root: {
                        borderTop: `solid 1px ${theme.palette.divider}`,
                    },
                    toolbar: {
                        height: 64,
                    },
                    select: {
                        '&:focus': {
                            borderRadius: theme.shape.borderRadius,
                        },
                    },
                    selectIcon: {
                        width: 20,
                        height: 20,
                        marginTop: 2,
                    },
                },
            },
        },
        {
            MuiPaper: {
                defaultProps: {
                    elevation: 0,
                },

                styleOverrides: {
                    root: {
                        backgroundImage: 'none',
                    },
                },
            },
        },
        {
            MuiAlert: {
                defaultProps: {
                    iconMapping: {
                        error: <RiFolderInfoFill />,
                        info: <FiAlertCircle />,
                        success: <IoMdCheckmarkCircleOutline />,
                        warning: <FiAlertTriangle />
                    }
                },

                styleOverrides: {
                    message: {
                        '& .MuiAlertTitle-root': {
                            marginBottom: theme.spacing(0.5)
                        }
                    },
                    action: {
                        '& button:not(:first-of-type)': {
                            marginLeft: theme.spacing(1)
                        }
                    },

                    standardInfo: standardStyle('info'),
                    standardSuccess: standardStyle('success'),
                    standardWarning: standardStyle('warning'),
                    standardError: standardStyle('error'),

                    filledInfo: filledStyle('info'),
                    filledSuccess: filledStyle('success'),
                    filledWarning: filledStyle('warning'),
                    filledError: filledStyle('error'),

                    outlinedInfo: outlinedStyle('info'),
                    outlinedSuccess: outlinedStyle('success'),
                    outlinedWarning: outlinedStyle('warning'),
                    outlinedError: outlinedStyle('error')
                }
            }
        },
        {
            MuiSwitch: {
                styleOverrides: {
                    thumb: {
                        boxShadow: theme.customShadows.z1,
                    },
                    track: {
                        opacity: 1,
                        backgroundColor: theme.palette.grey[500],
                    },
                    switchBase: {
                        left: 0,
                        right: 'auto',
                        '&:not(:.Mui-checked)': {
                            color: theme.palette.grey[isLight ? 100 : 300],
                        },
                        '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
                            color: theme.palette.grey[isLight ? 400 : 600],
                        },
                        '&.Mui-disabled+.MuiSwitch-track': {
                            opacity: 1,
                            backgroundColor: `${theme.palette.action.disabledBackground} !important`,
                        },
                    },
                },
            },
        },
        {
            MuiSelect: {
                // defaultProps: {
                //     IconComponent: ExpandMoreRoundedIcon,
                // },

                styleOverrides: {
                    root: {},
                },
            },
        },
        {
            MuiButton: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            boxShadow: 'none',
                        },
                    },
                    sizeLarge: {
                        height: 48,
                    },
                    // contained
                    containedInherit: {
                        color: theme.palette.grey[800],
                        boxShadow: theme.customShadows.z8,
                        '&:hover': {
                            backgroundColor: theme.palette.grey[400],
                        },
                    },
                    containedPrimary: {
                        boxShadow: theme.customShadows.primary,
                    },
                    containedSecondary: {
                        boxShadow: theme.customShadows.secondary,
                    },
                    containedInfo: {
                        boxShadow: theme.customShadows.info,
                    },
                    containedSuccess: {
                        boxShadow: theme.customShadows.success,
                    },
                    containedWarning: {
                        boxShadow: theme.customShadows.warning,
                    },
                    containedError: {
                        boxShadow: theme.customShadows.error,
                    },
                    // outlined
                    outlinedInherit: {
                        border: `1px solid ${theme.palette.grey[500_32]}`,
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    },
                    textInherit: {
                        '&:hover': {
                            backgroundColor: theme.palette.action.hover,
                        },
                    },
                },
            },
        },
        {
            MuiRating: {
                defaultProps: {
                    emptyIcon: ICON,
                    icon: ICON
                },

                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            opacity: 0.48
                        }
                    },
                    iconEmpty: { color: theme.palette.grey[500_48] },
                    sizeSmall: { '& svg': { ...ICON_SIZE } },
                    sizeLarge: { '& svg': { ...ICON_LARGE } }
                }
            }
        },
        {
            MuiDialog: {
                styleOverrides: {
                    paper: {
                        boxShadow: theme.customShadows.z24,
                        '&.MuiPaper-rounded': {
                            borderRadius: theme.shape.borderRadiusMd,
                        },
                        '&.MuiDialog-paperFullScreen': {
                            borderRadius: 0,
                        },
                        '&.MuiDialog-paper .MuiDialogActions-root': {
                            padding: theme.spacing(3),
                        },
                        '@media (max-width: 600px)': {
                            margin: theme.spacing(2),
                        },
                        '@media (max-width: 663.95px)': {
                            '&.MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
                                maxWidth: '100%',
                            },
                        },
                    },
                    paperFullWidth: {
                        width: '100%',
                    },
                },
            },
            MuiDialogTitle: {
                styleOverrides: {
                    root: {
                        padding: theme.spacing(3, 3, 0),
                    },
                },
            },
            MuiDialogContent: {
                styleOverrides: {
                    root: {
                        borderTop: 0,
                        borderBottom: 0,
                        padding: theme.spacing(3),
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        '& > :not(:first-of-type)': {
                            marginLeft: theme.spacing(1.5),
                        },
                    },
                },
            },
        },
        {
            MuiAvatar: {
                styleOverrides: {
                    colorDefault: {
                        color: theme.palette.text.secondary,
                        backgroundColor: theme.palette.grey[400],
                    },
                },
            },
            MuiAvatarGroup: {
                styleOverrides: {
                    avatar: {
                        fontSize: 16,
                        fontWeight: theme.typography.fontWeightMedium,
                        '&:first-of-type': {
                            fontSize: 14,
                            color: theme.palette.primary.main,
                            backgroundColor: theme.palette.primary.lighter,
                        },
                    },
                },
            },
        },
        {
            MuiSlider: {
                defaultProps: {
                    size: 'small',
                },

                styleOverrides: {
                    root: {
                        '&.Mui-disabled': {
                            color: theme.palette.action.disabled,
                        },
                    },
                    markLabel: {
                        fontSize: 13,
                        color: theme.palette.text.disabled,
                    },
                    valueLabel: {
                        borderRadius: 8,
                        backgroundColor: theme.palette.grey[isLight ? 800 : 700],
                    },
                },
            },
        },
        {
            MuiDrawer: {
                styleOverrides: {
                    modal: {
                        '&[role="presentation"]': {
                            '& .MuiDrawer-paperAnchorLeft': {
                                boxShadow: `8px 24px 24px 12px ${alpha(
                                    theme.palette.grey[900],
                                    isLight ? 0.16 : 0.48,
                                )}`,
                            },
                            '& .MuiDrawer-paperAnchorRight': {
                                boxShadow: `-8px 24px 24px 12px ${alpha(
                                    theme.palette.grey[900],
                                    isLight ? 0.16 : 0.48,
                                )}`,
                            },
                        },
                    },
                },
            },
        },
        {
            MuiStepConnector: {
                styleOverrides: {
                    line: {
                        borderColor: theme.palette.divider,
                    },
                },
            },
        },
        {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        backgroundColor: theme.palette.grey[isLight ? 800 : 700],
                    },
                    arrow: {
                        color: theme.palette.grey[isLight ? 800 : 700],
                    },
                },
            },
        },
        {
            MuiPopover: {
                styleOverrides: {
                    paper: {
                        boxShadow: theme.customShadows.z12,
                    },
                },
            },
        },
        {
            MuiSvgIcon: {
                styleOverrides: {
                    fontSizeSmall: {
                        width: 20,
                        height: 20,
                        fontSize: 'inherit',
                    },
                    fontSizeLarge: {
                        width: 32,
                        height: 32,
                        fontSize: 'inherit',
                    },
                },
            },
        },
        {
            MuiCheckbox: {
                defaultProps: {
                    icon: <Icon />,
                    checkedIcon: <CheckedIcon />,
                    indeterminateIcon: <IndeterminateIcon />
                },

                styleOverrides: {
                    root: {
                        padding: theme.spacing(1),
                        '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
                            color: theme.palette.action.disabled
                        },
                        '& .MuiSvgIcon-fontSizeMedium': {
                            width: 24,
                            height: 24
                        },
                        '& .MuiSvgIcon-fontSizeSmall': {
                            width: 20,
                            height: 20
                        },
                        svg: {
                            fontSize: 24,
                            '&[font-size=small]': {
                                fontSize: 20
                            }
                        }
                    }
                }
            }
        },
        {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                        border: `1px solid transparent`,
                        '& .MuiTablePagination-root': {
                            borderTop: 0,
                        },
                        '& .MuiDataGrid-toolbarContainer': {
                            padding: theme.spacing(2),
                            backgroundColor: theme.palette.background.neutral,
                            '& .MuiButton-root': {
                                marginRight: theme.spacing(1.5),
                                color: theme.palette.text.primary,
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover,
                                },
                            },
                        },
                        '& .MuiDataGrid-cell, .MuiDataGrid-columnsContainer': {
                            borderBottom: `1px solid ${theme.palette.divider}`,
                        },
                        '& .MuiDataGrid-columnSeparator': {
                            color: theme.palette.divider,
                        },
                        '& .MuiDataGrid-columnHeader[data-field="__check__"]': {
                            padding: 0,
                        },
                    },
                },
            },
            MuiGridMenu: {
                styleOverrides: {
                    root: {
                        '& .MuiDataGrid-gridMenuList': {
                            boxShadow: theme.customShadows.z20,
                            borderRadius: theme.shape.borderRadius,
                        },
                        '& .MuiMenuItem-root': {
                            ...theme.typography.body2,
                        },
                    },
                },
            },
            MuiGridFilterForm: {
                styleOverrides: {
                    root: {
                        padding: theme.spacing(1.5, 0),
                        '& .MuiFormControl-root': {
                            margin: theme.spacing(0, 0.5),
                        },
                        '& .MuiInput-root': {
                            marginTop: theme.spacing(3),
                            '&::before, &::after': {
                                display: 'none',
                            },
                            '& .MuiNativeSelect-select, .MuiInput-input': {
                                ...theme.typography.body2,
                                padding: theme.spacing(0.75, 1),
                                borderRadius: theme.shape.borderRadius,
                                backgroundColor: theme.palette.background.neutral,
                            },
                            '& .MuiSvgIcon-root': {
                                right: 4,
                            },
                        },
                    },
                },
            },
            MuiGridPanelFooter: {
                styleOverrides: {
                    root: {
                        padding: theme.spacing(2),
                        justifyContent: 'flex-end',
                        '& .MuiButton-root': {
                            '&:first-of-type': {
                                marginRight: theme.spacing(1.5),
                                color: theme.palette.text.primary,
                                '&:hover': {
                                    backgroundColor: theme.palette.action.hover,
                                },
                            },
                            '&:last-of-type': {
                                color: theme.palette.common.white,
                                backgroundColor: theme.palette.primary.main,
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.dark,
                                },
                            },
                        },
                    },
                },
            },
        },
        {
            MuiSkeleton: {
                defaultProps: {
                    animation: 'wave',
                },

                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.background.neutral,
                    },
                },
            },
        },
        {
            MuiTimelineDot: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                    },
                },
            },

            MuiTimelineConnector: {
                styleOverrides: {
                    root: {
                        backgroundColor: theme.palette.divider,
                    },
                },
            },
        },
        {
            MuiTreeView: {
                defaultProps: {
                    defaultCollapseIcon: <AiOutlineMinusSquare {...ICON_SIZE} />,
                    defaultExpandIcon: <AiOutlinePlusSquare {...ICON_SIZE} />,
                    defaultEndIcon: (
                        <Box component={AiOutlineCloseSquare} {...ICON_SIZE} sx={{ color: 'text.secondary' }} />
                    ),
                },
            },
            MuiTreeItem: {
                styleOverrides: {
                    label: { ...theme.typography.body2 },
                    iconContainer: { width: 'auto' },
                },
            },
        },
        {
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        background: [
                            `rgb(22,28,36)`,
                            `-moz-linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
                            `-webkit-linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
                            `linear-gradient(75deg, ${varLow} 0%, ${varHigh} 100%)`,
                        ],
                        '&.MuiBackdrop-invisible': {
                            background: 'transparent',
                        },
                    },
                },
            },
        },
        {
            MuiSnackbarContent: {
                styleOverrides: {
                    root: {},
                },
            },
        },
        {
            MuiLinearProgress: {
                styleOverrides: {
                    root: {
                        borderRadius: 4,
                        overflow: 'hidden',
                    },
                    bar: {
                        borderRadius: 4,
                    },
                    colorPrimary: {
                        backgroundColor: theme.palette.primary[isLight ? 'lighter' : 'darker'],
                    },
                    buffer: {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        {
            MuiContainer: {
                styleOverrides: {
                    root: {},
                },
            },
        },
        {
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        backgroundColor: 'transparent',
                        '&.Mui-expanded': {
                            boxShadow: theme.customShadows.z8,
                            borderRadius: theme.shape.borderRadius,
                            backgroundColor: 'transparent',

                        },
                        '&.Mui-disabled': {
                            backgroundColor: 'transparent',
                        },
                    },
                },
            },
            MuiAccordionSummary: {
                styleOverrides: {
                    root: {
                        paddingLeft: theme.spacing(2),
                        paddingRight: theme.spacing(1),
                        backgroundColor: 'transparent',
                        '&.Mui-disabled': {
                            opacity: 1,
                            color: theme.palette.action.disabled,
                            '& .MuiTypography-root': {
                                color: 'inherit',
                            },
                        },
                    },
                    expandIconWrapper: {
                        color: 'inherit',
                    },
                },
            },
        },
        {
            MuiIconButton: {
                styleOverrides: {
                    root: {},
                },
            },
        },
        {
            MuiTypography: {
                styleOverrides: {
                    paragraph: {
                        marginBottom: theme.spacing(2),
                    },
                    gutterBottom: {
                        marginBottom: theme.spacing(1),
                    },
                },
            },
        },
        {
            MuiPaginationItem: {
                styleOverrides: {
                    root: {
                        '&.Mui-selected': {
                            fontWeight: theme.typography.fontWeightBold,
                        },
                    },
                    textPrimary: {
                        '&.Mui-selected': {
                            color: theme.palette.primary.main,
                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                            '&:hover, &.Mui-focusVisible': {
                                backgroundColor: `${alpha(theme.palette.primary.main, 0.24)} !important`,
                            },
                        },
                    },
                    outlined: {
                        border: `1px solid ${theme.palette.grey[500_32]}`,
                    },
                    outlinedPrimary: {
                        '&.Mui-selected': {
                            backgroundColor: alpha(theme.palette.primary.main, 0.08),
                            border: `1px solid ${alpha(theme.palette.primary.main, 0.24)}`,
                        },
                    },
                },
            },
        },
        {
            MuiButtonGroup: {
                variants: [
                    {
                        props: { variant: 'contained', color: 'inherit' },
                        style: { boxShadow: theme.customShadows.z8 },
                    },
                    styleContained('primary'),
                    styleContained('secondary'),
                    styleContained('info'),
                    styleContained('success'),
                    styleContained('warning'),
                    styleContained('error'),

                    {
                        props: { disabled: true },
                        style: {
                            boxShadow: 'none',
                            '& .MuiButtonGroup-grouped.Mui-disabled': {
                                color: theme.palette.action.disabled,
                                borderColor: `${theme.palette.action.disabledBackground} !important`,
                                '&.MuiButton-contained': {
                                    backgroundColor: theme.palette.action.disabledBackground,
                                },
                            },
                        },
                    },
                ],

                styleOverrides: {
                    root: {
                        '&:hover': {
                            boxShadow: 'none',
                        },
                    },
                },
            },
        },
        {
            MuiBreadcrumbs: {
                styleOverrides: {
                    separator: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                },
            },
        },
        {
            MuiAutocomplete: {
                styleOverrides: {
                    paper: {
                        boxShadow: theme.customShadows.z20,
                    },
                },
            },
        },
        {
            MuiFormControlLabel: {
                styleOverrides: {
                    label: {
                        ...theme.typography.body2,
                    },
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        marginTop: theme.spacing(1),
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.disabled,
                    },
                },
            },
        },
        {
            MuiToggleButton: {
                styleOverrides: {
                    root: {
                        color: theme.palette.grey[500],
                        border: `solid 1px ${theme.palette.grey[500_32]}`,
                        '&.Mui-selected': {
                            color: theme.palette.grey[isLight ? 600 : 0],
                            backgroundColor: theme.palette.action.selected,
                        },
                        '&.Mui-disabled': {
                            color: theme.palette.grey[500_48],
                        },
                    },
                },
            },
        },
        {
            MuiLoadingButton: {
                styleOverrides: {
                    root: {
                        '&.MuiButton-text': {
                            '& .MuiLoadingButton-startIconPendingStart': {
                                marginLeft: 0,
                            },
                            '& .MuiLoadingButton-endIconPendingEnd': {
                                marginRight: 0,
                            },
                        },
                    },
                },
            },
        },
    );
}
