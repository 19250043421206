import { Button, Grid, useTheme } from '@mui/material'
import React, { useState } from 'react'
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react/swiper-react'
import SwiperCore, { Autoplay, FreeMode, Navigation, Pagination, Thumbs, Keyboard } from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css'

SwiperCore.use([FreeMode, Thumbs, Pagination, Navigation, Autoplay]);

export default function BaseSwipe({ imgs, swipeProps, style, customClass, showThumbs, indexImageActive, autoPlay = false, loop = false }: Props) {
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);

    // Component swiper
    const [swiper, setSwiper] = useState<any>(null);

    // swiper di chuyen den vi tri index
    const slideTo = (index) => swiper.slideTo(index);

    if (swiper && indexImageActive != undefined && swiper.realIndex != indexImageActive) {
        slideTo(indexImageActive)
    }

    return (
        <div>
            <Swiper
                autoplay={autoPlay}
                loop={loop}
                cssMode
                onSwiper={setSwiper}
                pagination={{ "clickable": true }} freeMode
                slidesPerView={1}
                {...swipeProps}
                thumbs={{ swiper: thumbsSwiper }}
            >
                {imgs.map((url, index) => <SwiperSlide key={`${index}`} className={customClass} style={style}><img src={url} /></SwiperSlide>)}
            </Swiper>
            {showThumbs && <Swiper onSwiper={(thumb) => {
                setThumbsSwiper(thumb)
            }}
                freeMode
                slidesPerView={4}
                spaceBetween={10}>
                {imgs.map((url, index) => <SwiperSlide key={`${index}`}><img src={url} /></SwiperSlide>)}
            </Swiper>}
        </div>
    )
}

interface Props {
    imgs: string[],
    style?: React.CSSProperties,
    showThumbs?: boolean,
    swipeProps?: SwiperProps,
    customClass?: string;
    indexImageActive?: number;
    autoPlay?: boolean,
    loop?: boolean
}


