import { Grid, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { appLocale } from 'src/constant/Locale';
import OurValue from './OurValue';
const useStyles = makeStyles((theme: Theme) => ({
    base: {
        background: 'url(/background.png)',
        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
    },
    box: {
        position: 'relative',
        paddingBottom: 5,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '50%',
            bottom: 0,
            left: '25%',
            borderTop: `5px solid ${theme.palette.primary.darker}`,
            borderRadius: 50
        },
    },
}));
export default function CoreValues() {
    const classes = useStyles();
    const locale = appLocale.page.about.coreValues;
    return (
        <Grid container xs={12} justifyContent="center" className={classes.base} pt={{ sm: 1, xs: 2 }} pb={{ sm: 10, xs: 5 }} mt={10}>
            <Grid item md={8} sm={12} xs={12} container justifyContent="center" alignItems="center">
                <Typography fontSize={32} fontWeight={600} mt={2} mb={7} className={classes.box} color="#183b56">
                    {locale.title}
                </Typography>
                <Grid container alignItems="center" justifyContent="space-between" xs={12}>
                    <OurValue
                        title={locale.item1.title}
                        content={locale.item1.content}
                        urlImage="https://zone-assets-api.vercel.app/assets/icons/ic_agreement.svg"
                    />
                    <OurValue
                        title={locale.item2.title}
                        content={locale.item2.content}
                        urlImage="https://zone-assets-api.vercel.app/assets/icons/ic_transparency.svg"
                    />
                    <OurValue
                        title={locale.item3.title}
                        content={locale.item3.content}
                        urlImage="https://zone-assets-api.vercel.app/assets/icons/ic_reputation.svg"
                    />
                    <OurValue
                        title={locale.item4.title}
                        content={locale.item4.content}
                        urlImage="https://zone-assets-api.vercel.app/assets/icons/ic_popularity.svg"
                    />
                </Grid>
            </Grid>
        </Grid>
    );
}
