import { Button, Grid, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { AiOutlineSafety } from 'react-icons/ai';
import { BsWallet2 } from 'react-icons/bs';
import { FaShippingFast } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { appLocale } from 'src/constant/Locale';
import ViewItem from './ViewItem';
const useStyles = makeStyles((theme: Theme) => ({
    base: {
        background: 'url(/background.png)',
    },
    box: {
        position: 'relative',
        paddingBottom: 7,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '50%',
            bottom: 0,
            left: '25%',
            borderTop: `5px solid ${theme.palette.primary.darker}`,
            borderRadius: 50,
        },
    },
}));
export default function View() {
    const classes = useStyles();
    const history = useNavigate();
    const locale = appLocale.page.about.views;
    return (
        <Grid
            container
            xs={12}
            justifyContent="center"
            className={classes.base}
            pt={{ sm: 5, xs: 2 }}
            pb={{ sm: 5, xs: 5 }}
        >
            <Grid item xs={12} container justifyContent="center" alignItems="center">
                <Grid item xs={12} container justifyContent="center" alignItems="center" direction="column" mt={2}>
                    <Stack justifyContent="center" alignItems="center" maxWidth={480} textAlign="center">
                        <Typography fontSize={32} fontWeight={600} mt={2} mb={2} className={classes.box} color="#183b56">
                            {locale.title}
                        </Typography>
                        <Typography>{locale.content}</Typography>
                    </Stack>
                    <Grid container alignItems="center" justifyContent="space-between" xs={8}>
                        <ViewItem icon={<FaShippingFast />} title={locale.item1} />
                        <ViewItem icon={<AiOutlineSafety />} title={locale.item2} />
                        <ViewItem icon={<BsWallet2 />} title={locale.item3} />
                        <ViewItem icon={<FiUsers />} title={locale.item4} />
                    </Grid>
                    <Button
                        variant="outlined"
                        size="large"
                        onClick={(e) => history('/home')}
                        endIcon={<HiArrowNarrowRight />}
                        sx={{ borderRadius: 10, mt: 10 }}
                    >
                        {locale.button}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}
