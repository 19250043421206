import { Grid, Theme, Typography, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useBaseStyle } from 'src/component/styled-components/baseStyle'
import { appLocale } from 'src/constant/Locale'

const useStyles = makeStyles((theme: Theme) => ({
    productDetail: {
        "& .title": {
            display: "flex",
            alignItems: "center",
            fontSize: "1rem",
            fontWeight: 700
        },
        "& *": {
            fontSize: "0.85rem",
            color: theme.palette.grey[800]
        },
        "& ul": {
            display: "grid",
            gridTemplateColumns: "1fr",
            marginLeft:"1rem"
        },
        "& h1,h2,h3,h4,h5,h6": {
            marginBottom: 8,
            fontSize: "1rem",
        },
        "& p": {
            lineHeight: "1.5rem",
        },
        "& li": {
            padding: "4px 0",
        }
    },

}))

export default function ProductDescription({ desc }: { desc: string }) {
    const classes = useStyles()
    const baseClass = useBaseStyle()
    const theme = useTheme()
    const locale = appLocale.page.details
    return (
        <Grid p="20px 40px" className={baseClass.wrapper}>
            <Typography variant="h5" style={{ color: theme.palette.grey[600] }}>{locale.productDetails}</Typography>
            <Grid className={classes.productDetail} mt={2} dangerouslySetInnerHTML={{ __html: desc || "" }} />
        </Grid>
    )
}
