import { Divider, Grid, Paper, Typography, useTheme } from '@mui/material'
import _ from 'lodash'
import { useContext } from 'react'
import { appLocale } from 'src/constant/Locale'
import { CartContext } from '../../cart/useCart'

export default function CheckoutSummary(props: any) {
    const theme = useTheme()
    const { shopWithTotalAndFeeShip } = useContext(CartContext)
    const { title, price, shippingFee, totalPrice } = appLocale.page.checkout.delivery.checkoutSummary
    const total = shopWithTotalAndFeeShip.map(s => s.total).reduce((sum, total) => sum = sum + total, 0)
    const totalDelivery = shopWithTotalAndFeeShip.map(s => s.feeShip).reduce((sum, total) => sum = sum + total, 0)

    return (
        <div>
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="subtitle1" color={theme.palette.grey[700]}>{title}</Typography>
                {shopWithTotalAndFeeShip.map(s => <Grid mt={2} style={{ fontSize: "0.9rem" }}>
                    <Typography style={{ fontSize: "0.9rem", fontWeight: 600 }}>{s.shop?.name}</Typography>
                    <Grid container xs={12} item justifyContent="space-between">
                        <Grid>{price}</Grid>
                        <Grid>${_.round(s.total, 2)}</Grid>
                    </Grid>
                    <Grid container xs={12} item justifyContent="space-between" >
                        <Grid>{shippingFee}</Grid>
                        <Grid>${s.feeShip}</Grid>
                    </Grid>
                </Grid>)}
                <Divider sx={{ borderWidth: 1, mt: 5.4 }} />
                <Grid container alignItems="center" justifyContent="space-between" pt={2}>
                    <Typography color={theme.palette.grey[800]}>{totalPrice}</Typography>
                    <Typography variant="subtitle1" color={theme.palette.primary.dark}>£{_.round(total + totalDelivery, 2)}</Typography>
                </Grid>
                {props.children}
            </Paper>
        </div>
    )
}
