import { Product } from '@CoreCommerce/models/Product';
import { SxProps, Theme } from '@mui/material';
import Grid from '@mui/material/Grid/Grid';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import ProductItem from './ProductItem';

export function ProductList({ products, loading, sx, isDisplayShop }: Props) {
    return (
        <Grid container item xs={12} spacing={1} p={{ sm: 0, xs: '0 0.5rem' }} sx={sx}>
            {!loading
                ? products.map((p) => (
                      <Grid key={p.id} item xs={6} sm={4} md={3} lg={3} xl={2}>
                          <ProductItem product={p} isDisplayShop />
                      </Grid>
                  ))
                : Array.from(new Array(24)).map((p, index) => (
                      <Grid key={index} item xs={6} sm={4} md={3} lg={3} xl={2}>
                          <Skeleton style={{ width: '100%' }} height={300} />
                      </Grid>
                  ))}
        </Grid>
    );
}

interface Props {
    products: Product[];
    loading?: boolean;
    isDisplayShop?: boolean;
    sx?: SxProps<Theme>;
}
