import styled from "@emotion/styled";
import { SxProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const styles: SxProps = {
    position: 'absolute',
    top: 34,
    right: 0,
    borderRadius: "10px 0 10px 10px",
    zIndex: 2,
    p: 1,
    bgcolor: 'background.paper',
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",
    width: "150px",
    height: "auto",
    textAlign: "center",
    transitionTimingFunction: "ease-out",
    display: 'none',
};


export const useMenuStyles = makeStyles((theme: Theme) => ({
    root: {
        width: "100vw",
        background: `linear-gradient(to right, ${theme.palette.secondary.darker},${theme.palette.primary.darker})`,
    },
    logo: {
        width: "auto",
        height: 60,
        fill: theme.palette.common.white,
        cursor: "pointer",
    },
    menuTitle: {
        cursor: "pointer",
        color: theme.palette.common.white,
        '&:hover': {
            // color: theme.palette.primary.main,
            transition: "0.2s",
            textDecoration: "underline"
        }
    },
    webHeader: {
        height: "128px",
        zIndex: 1000,
    },
    mobileHeader: {
        height: 72,
        boxShadow: "0px 0px 15px rgba(63, 63, 63, 0.1)",
        backdropFilter: "blur(6px)",
        zIndex: 2,
        padding: 8,
        paddingLeft: 16,
        display: "flex",
        alignItems: "center"
    },
    tabMenu: {
        width: 250,
        padding: 20,
        textAlign: 'center',
    },
    tabMenuIcons: {
        height: 25
    },
    rootIcons: {
        fontSize: 30,
        cursor: "pointer",
        color: "#fff"
    },
    socialIcons: {
        fontSize: 24,
        cursor: "pointer",
        color: theme.palette.common.white,
        '&:hover': {
            color: theme.palette.primary.main,
            transition: "0.2s"
        }
    },
    avatarIcon: {
        width: 25,
        height: 25,
        cursor: "pointer"
    },
    backdrop: {
        background: 'rgba(0, 0, 0, 0.5)',
    }
}))