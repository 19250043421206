import { appConfig } from '../constant/IConfig';
import { AxiosInstance } from 'axios';
import { BaseHttpController } from '@CoreCommerce/controllers/BaseHttpController';
import { Account, AccountWithRoles } from '@CoreAccount/model/Account';
import { IAccountController } from '@CoreAccount/controller/IAccountController';
import { ACCOUNT_SERVICE_NAME } from '@CoreAccount/index';

export class AccountController extends BaseHttpController<Account> implements IAccountController {
    constructor(axiosClient: AxiosInstance) {
        super(appConfig.apiGateway.account, ACCOUNT_SERVICE_NAME.account, axiosClient);
    }
    createOtpRegister(params: { username: string; }): Promise<{ username: string; }> {
        return this.doPost({ path: 'createOtpRegister', body: params, }).then((resp) => resp.data);
    }
    requestForgotPassword(username: string): Promise<{ username: string; }> {
        return this.doPost({ path: 'requestForgotPassword', body: { username }, }).then((resp) => resp.data);
    }
    verifyToken(username: string, token: string): Promise<boolean> {
        throw new Error('Method not implemented.');
    }
    resetPassword(params: { username: string; password: string; token: string; }): Promise<AccountWithRoles> {
        return this.doPost({ path: 'resetPassword', body: params, }).then((resp) => resp.data);
    }
    loginWithGoogle(params: { token: string; }): Promise<AccountWithRoles> {
        return this.doPost({ path: 'login-with-google', body: params, config: {} }).then((resp) => resp.data);
    }
    async login(username: string, password: string): Promise<AccountWithRoles> {
        console.log({ body: { username, password } });

        return this.doPost({ path: 'login', body: { username, password } }).then((resp) => resp.data);
    }
    async register(username: string, password: string, otp: string): Promise<AccountWithRoles> {
        return this.doPost({ path: 'register', body: { username, password, otp } }).then((resp) => resp.data);
    }
}
