import { Grid } from '@mui/material'
import React from 'react'
import ListBrandCategory from './components/ListBrandCategory'
import { categoryBrands } from './data'

export default function CategoryBrands() {
    return (
        <Grid container justifyContent="center">
            {categoryBrands.map(cb =>
                <ListBrandCategory lbc={cb} />
            )}
        </Grid>
    )
}
