import { Grid, Pagination, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { NotFound } from 'src/component/search-products/NotFound';
import { appLocale } from 'src/constant/Locale';
import { UserContext } from 'src/hook/useUser';
import OrderItem from './component/OrderItem';
import useOrderHistory from './useOrderHistory';

export default function OrderHistoryPage({ userId }: { userId: string }) {
    const { loading, pagination, onChangePage } = useOrderHistory({ userId });
    const theme = useTheme()
    const title = appLocale.page.user.orderHistory.title;
    const isNotExistedOrder = pagination.rows.length == 0 && !loading;
    return (
        <Grid container mb={10}>
            {!isNotExistedOrder ? (
                <Grid xs={12} container>
                    {!loading ? (
                        <>
                            <Grid container justifyContent="flex-start" mt={{ md: 5, sm: 0, xs: 1 }}>
                                <Typography
                                    variant="h3"
                                    color={theme.palette.secondary.dark}
                                    ml={{ md: 0, sm: 2, xs: 2 }}
                                    mt={{ md: 0, sm: 1, xs: 1 }}
                                >
                                    {title}
                                </Typography>
                                <Grid item md={12} sm={12} xs={12}>
                                    {pagination.rows
                                        .filter((r) => (r.variants?.length || 0) > 0)
                                        .map((order) => (
                                            <OrderItem key={order.id} order={order} />
                                        ))}
                                </Grid>
                            </Grid>
                            <Grid container justifyContent="center" xs={12} mt={5}>
                                <Pagination
                                    color="primary"
                                    count={pagination.totalPages}
                                    page={pagination.page}
                                    onChange={(e, page) => onChangePage(page)}
                                />
                            </Grid>
                        </>
                    ) : (
                        <Grid container justifyContent="flex-start" mt={{ md: 5, sm: 0, xs: 1 }}>
                            <Grid md={12} sm={12} xs={12} ml={{ md: 0, sm: 2, xs: 2 }}>
                                <Skeleton style={{ width: 210 }} height={35} />
                            </Grid>
                            {[...new Array(10)].map((p, index) => (
                                <Grid key={index} md={12} sm={12} xs={12} mt={{ md: 3, sm: 1, xs: 1 }}>
                                    <Skeleton style={{ width: '100%' }} height={300} />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </Grid>
            ) : (
                <NotFound
                    title="Oops sorry, you haven't placed any orders yet."
                    subTitle="You can go back to Home & start order now!"
                />
            )}
        </Grid>
    );
}

export function OrderHistoryRoute() {
    const { user } = useContext(UserContext);
    return user.id ? <OrderHistoryPage userId={user.id} /> : <div />;
}
