import { BaseModel } from '@CoreCommerce/models/BaseModel'
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Theme, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext, useState } from 'react'
import { MdOutlineArrowForwardIos } from 'react-icons/md'
import { PaymentMethodContext } from '../usePaymentMethod'


export const useStyles = makeStyles((theme: Theme) => ({
    methodItem: {
        padding: "16px 0",
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.main
        },
    }
}))

export interface Provider extends BaseModel {
    name: string
    label: string
}

const providers: Provider[] = [{
    label: 'BeeMall.io',
    name: 'BeeMall.io',
    id: "35800d69-6ca4-40d3-b945-bd66924b87cb",
},
{
    label: 'Phúc Long Express',
    name: 'PLE',
    id: "1dac2b64-d12f-41e5-800d-c57c3e078738",
},]

export default function PayLaterPayment() {
    const classes = useStyles();
    const { paymentMethod, setPaymentMethodState } = useContext(PaymentMethodContext)
    return (
        <Grid>
            <Grid className={classes.methodItem} container xs={12} item justifyContent="space-between">
                <Typography>Pay later</Typography>
                <MdOutlineArrowForwardIos />
            </Grid>
            {paymentMethod?.method === 'payLater' && <Grid pl={2}>
                <FormControl component="fieldset">
                    <RadioGroup
                        onChange={(e, value) => setPaymentMethodState(prev => ({ ...prev, paymentMethod: { ...prev.paymentMethod, provider: value } }))}
                        defaultValue={paymentMethod.provider}>
                        {providers.map(p => <FormControlLabel value={p.name} control={<Radio />} label={p.label} />)}
                    </RadioGroup>
                </FormControl>
            </Grid>}
        </Grid>
    )
}

interface State {
    expanded: boolean
}