import { ReportAnalyticsFooter } from "@CoreCommerce/models/ReportAnalytics";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import ReactGA from "react-ga4"
import { useLocation } from 'react-router';
import { io } from "socket.io-client";
import { appConfig } from "src/constant/IConfig";
import { UserContext } from "src/hook/useUser";
import { AuthenContext } from "./useAuthen";
export default function useGoogleAnalytic() {
    const { pathname, search } = useLocation();
    const user = useContext(UserContext)
    const [dataReport, setDataReport] = useState<ReportAnalyticsFooter>();
    const socket = useMemo(() => io(appConfig.apiGateway.ecommerce, { path: '/google-analytics', }), [appConfig.apiGateway.ecommerce]);
    const pageView = (path?: string) => {
        console.log("add page view");
        ReactGA.send({ hitType: "pageview", page: path || window.location.pathname })
    }
    const eventSeeDetail = (productId: string) => {
        ReactGA.event({
            category: "PRODUCT",
            action: 'SEE DETAIL',
            label: productId
        })
    }
    const onSearch = (keySearch: string, brand: string) => {
        ReactGA.event({
            category: brand || "PRODUCT",
            action: 'SEARCH',
            label: keySearch
        })
    }
    const addToCart = (productId: string) => {
        ReactGA.event({
            category: "PRODUCT",
            action: 'ADD TO CART',
            label: productId
        })
    }
    const seeShop = (keyShop: string) => {
        ReactGA.event({
            category: keyShop,
            action: 'SEE SHOP',
            label: keyShop
        })
    }
    const errorRequest = (status, path) => {
        ReactGA.event({
            category: "ERROR",
            action: status,
            label: path
        })
    }

    useEffect(() => {
        ReactGA.initialize('G-4FR66C6TMP', {
            gaOptions: {
                userId: user?.user?.email
            }
        });
        socket.io.engine.id = user.user?.username
        socket.on('msgToServer', (payload: ReportAnalyticsFooter) => {
            // setDataReport(_.mergeWith(payload, dataReport, (v1, v2) => ({ title: v2.title, value: v1 })))
            setDataReport(payload)
        });
        socket.emit("initValue")
    }, [user])

    useEffect(() => {
        pageView(window.location.pathname)
    }, [pathname])

    return { pageView, eventSeeDetail, onSearch, addToCart, seeShop, errorRequest, dataReport }
}

export const GoogleAnalyticContext = React.createContext<ReturnType<typeof useGoogleAnalytic>>({} as any);
