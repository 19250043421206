import { Grid, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react'
import { AiOutlineFileDone } from 'react-icons/ai';
import { appLocale } from 'src/constant/Locale';
import Footer from '../about/components/Footer';
import BrContent from './components/BrContent';
import ContentPrivacy from './components/ContentPrivacy';
const useStyles = makeStyles((theme: Theme) => ({
    base: {
        backgroundColor: '#fff',
        boxShadow: 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
        borderRadius: 8,
        padding: "35px 30px"
    },
}));
export default function Policy() {
    const classes = useStyles()
    const { title, item1, item2, item3, item4, item5 } = appLocale.page.privacy
    return (
        <Grid container justifyContent="center" >
            <Grid item md={8} sm={12} xs={12} mt={5}>
                <Stack direction="row" alignItems="center" spacing={1} color="#183b56">
                    <Typography variant='h3' ml={{md: 0, sm: 3.5, xs: 3.5}}>{title}</Typography>
                    <AiOutlineFileDone fontSize={34} />
                </Stack>
            </Grid>
            <Grid item md={8} sm={12} xs={12} mt={5} className={classes.base} mb={10}>
                <ContentPrivacy title={item1.title} content={<BrContent>{item1.content}</BrContent>} />
                <ContentPrivacy title={item2.title} content={<BrContent>{item2.content}</BrContent>} />
                <ContentPrivacy title={item3.title} content={<BrContent>{item3.content}</BrContent>} />
                <ContentPrivacy title={item4.title} content={<BrContent>{item4.content}</BrContent>} />
                <ContentPrivacy title={item5.title} content={<BrContent>{item5.content}</BrContent>} />
            </Grid>
            <Footer background='#fff' />
        </Grid>

    )
}
